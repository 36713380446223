import { request } from './request';

export function getActiveQuestions(orderId) {
    return request({
        path: encodeURI(`/qanda/order/${orderId}/question`),
        options: {
            method: 'GET'
        }
    });
}

export function getFileSourceText(key, orderId) {
    return request({
        path: encodeURI(`/qanda/source-text/question`),
        options: {
            method: 'GET'
        },
        params: {
            key,
            orderId
        }
    });
}

export function postQuestion({ refId, orderUid, orderId, langs, ...params }) {
    return request({
        path: `/qanda/question`,
        options: {
            method: 'POST',
            body: JSON.stringify(
                Object.assign(
                    { groupKey: refId, orderId, tags: [], pool: 'order' },
                    params
                )
            )
        }
    });
}

export function putResolveQuestion(questionId, isResolve) {
    return request({
        path: `/qanda/question/${questionId}`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ isResolve })
        }
    });
}

export function postAnswer({ questionId, content }) {
    return request({
        path: `/qanda/question/${questionId}/answer`,
        options: {
            method: 'POST',
            body: JSON.stringify({ content, state: 0, tags: [] })
        }
    });
}
