import React from 'react';

export const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

export const Label = ({ error, className, required, children, ...props }) => {
    return (
        <label className="form-label" {...props}>
            {children} {required && <span>(Required)</span>}
        </label>
    );
};

export const Field = ({
    type,
    id,
    label,
    error,
    value = '',
    onChange,
    className = '',
    render,
    showFeedbackError = true,
    rows = 0,
    required,
    ...props
}) => {
    return (
        <div className={`form-group ${error ? 'error' : ''} ${className}`}>
            {label && (
                <Label htmlFor={id} error={error} required={required}>
                    {label}
                </Label>
            )}
            {render({ id, type, value, onChange, rows, ...props })}
            {showFeedbackError && <InputFeedback error={error} />}
        </div>
    );
};
