import React from 'react';
import { Link } from 'react-router-dom';

const OrderIdCell = ({ orderId }) => {
    return (
        <Link className="link-btn" to={`/orders/${orderId}`}>
            {orderId}
        </Link>
    );
};

export default OrderIdCell;
