import ask from 'common/ask';
export const findAll = (path, queries) => {
    return ask({
        path,
        queries
    });
};

export const inviteCustomer = (path, data) => {
    return ask({
        path,
        options: {
            method: 'POST',
            body: JSON.stringify(data)
        }
    });
};
