import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';
import { QNA } from './actionConstants';
import { MESSAGES } from './constants';

const INITIAL_STATE = fromJS({
    error: null,
    loading: false,
    executing: false,
    isAddingQuestion: false,
    id: null, // question id
    list: null,
    groupKey: 'groupKey'
});

const handlers = {
    [QNA.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
        });
    },
    [QNA.TOGGLE_ADDING_QUESTION](state, { isAddingQuestion }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['isAddingQuestion'], isAddingQuestion);
        });
    },
    [QNA.FETCH_QUESTION_LIST](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], true);
        });
    },
    [QNA.ON_QUESTION_LIST_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['list'], fromJS(data));
            mutableState.setIn(['isAddingQuestion'], false);
        });
    },
    [QNA.ADD_QUESTION](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['executing'], true);
        });
    },
    [QNA.ON_QUESTION_ADDED](state, { data }) {
        data.answers = [];
        const questions = state.getIn(['list']).unshift(fromJS(data));

        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['executing'], false);
            mutableState.setIn(['list'], questions);
            mutableState.setIn(['isAddingQuestion'], false);
        });
    },
    [QNA.ON_ANSWER_ADDED](state, { data }) {
        const questions = state.getIn(['list']).toJS();
        const index = questions.findIndex(
            question => question.id === data.questionId
        );
        const answers = questions[index]['answers'];
        answers.push(data);

        return state.withMutations(mutableState => {
            mutableState.set(['error'], null);
            mutableState.setIn(['list', index, 'answers'], fromJS(answers));
        });
    },
    [QNA.ON_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], MESSAGES.ERROR);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['executing'], false);
        });
    },
    [QNA.ON_QUESTION_RESOLVED](state, { data }) {
        const questions = state
            .getIn(['list'])
            .map(q => (q.get('id') === data.id ? fromJS(data) : q));
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['executing'], false);
            mutableState.setIn(['list'], questions);
            mutableState.setIn(['isAddingQuestion'], false);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
