import { request } from './request';

export function signIn(payload) {
    return request({
        path: '/secure/v1/auth/signin',
        options: {
            body: JSON.stringify(payload)
        }
    });
}

export function signOut(payload) {
    return request({
        path: `/secure/v1/auth/signout`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                authorization: payload.token
            })
        }
    });
}

export function signUp({ serviceType, ...rest }) {
    const param = {
        register: rest
    };

    return request({
        path: '/secure/v1/auth/signup',
        options: {
            body: JSON.stringify(param),
            headers: {
                Service: serviceType
            }
        }
    });
}

export function resetPassword({ email }) {
    return request({
        path: `/secure/v1/user/${email}/password/tms`
    });
}

export function verifyResetPassword({ userUid, token }) {
    return request({
        path: `/secure/v1/user/${token}/reset-pass/${userUid}/verify`,
        options: {
            method: 'GET'
        }
    });
}

export function changePassword({ token, confirmPassword, newPassword }) {
    return request({
        path: `/secure/v1/user`,
        options: {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ confirmPassword, newPassword })
        }
    });
}

export function editProfile(payload) {
    return request({
        path: '/secure/v1/user/editProfile',
        options: {
            body: JSON.stringify(payload)
        }
    });
}

export function getUserCompanyInfo({ userUid, companyUid }) {
    return request({
        path: `/secure/v1/user/getCompanyInfo/${userUid}/${companyUid}`,
        options: {
            method: 'GET'
        }
    });
}
