import { requestData, receiveData } from '../api/actions';
import {
    getFileSourceText,
    getActiveQuestions,
    postQuestion,
    putResolveQuestion,
    postAnswer
} from 'services/qna';

import { QNA, QANDA_STATE } from './actionConstants';

export function closeMessage() {
    return {
        type: QNA.CLOSE_MESSAGE
    };
}

export function toggleAddingQuestion(isAddingQuestion) {
    return {
        type: QNA.TOGGLE_ADDING_QUESTION,
        isAddingQuestion
    };
}

export function searchText(keyword, orderUid) {
    return getFileSourceText(keyword, orderUid);
}

export function fetchActiveQuestions(orderId) {
    return dispatch => {
        dispatch(requestData(QNA.FETCH_QUESTION_LIST));

        getActiveQuestions(orderId)
            .then(payload => {
                dispatch(
                    receiveData(QNA.ON_QUESTION_LIST_FETCHED, payload.data)
                );
            })
            .catch(e => {
                dispatch(receiveData(QNA.ON_FAILED, e));
            });
    };
}

export function addQuestion(question) {
    return dispatch => {
        dispatch(requestData(QNA.RESOLVE_QUESTION));

        postQuestion(question)
            .then(payload => {
                dispatch(receiveData(QNA.ON_QUESTION_ADDED, payload.data));
            })
            .catch(e => {
                dispatch(receiveData(QNA.ON_FAILED, e));
            });
    };
}

export function resolveQuestion(question) {
    const toggleResolved = !(QANDA_STATE.RESOLVED === parseInt(question.state));

    return dispatch => {
        dispatch(
            requestData(QNA.RESOLVE_QUESTION, {
                questionId: question.id,
                isResolve: toggleResolved
            })
        );

        putResolveQuestion(question.id, toggleResolved)
            .then(payload => {
                dispatch(receiveData(QNA.ON_QUESTION_RESOLVED, payload.data));
            })
            .catch(e => {
                dispatch(receiveData(QNA.ON_FAILED, e));
            });
    };
}

export function addAnswer({ questionId, content, callback }) {
    return dispatch => {
        dispatch(requestData(QNA.ADD_ANSWER));

        postAnswer({ questionId, content })
            .then(payload => {
                if (callback) {
                    callback();
                }
                dispatch(receiveData(QNA.ON_ANSWER_ADDED, payload.data));
            })
            .catch(e => {
                if (callback) {
                    callback();
                }
                dispatch(receiveData(QNA.ON_FAILED, e));
            });
    };
}
