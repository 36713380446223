import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import onClickOutside from 'react-onclickoutside';
import { object, string, func } from 'prop-types';
import Popover from 'react-tiny-popover';

import { showModal, toogleUserStatus, reloadUserList } from './actions';

import './UserActionsBtn-style.css';

class UserActionsBtn extends React.PureComponent {
    static propTypes = {
        user: object,
        label: string,
        showModal: func,
        toogleUserStatus: func
    };

    state = {
        show: false
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    onButtonClick = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    onEditClick = () => {
        const { user, showModal } = this.props;

        showModal(user);
    };

    toogleUserStatus = () => {
        const { user, toogleUserStatus, reloadUserList } = this.props;

        toogleUserStatus(user);

        setTimeout(function() {
            reloadUserList();
        }, 100);
    };

    onWheel = e => {
        e.stopPropagation();
    };

    render() {
        const { label, user } = this.props;
        const { show } = this.state;

        return (
            <Popover
                isOpen={show}
                position={'bottom'}
                content={
                    <div className="user-actions">
                        <div
                            className={
                                'dropdown-menu dropdown-menu-righ actions show'
                            }
                        >
                            <div
                                className="dropdown-item clickable"
                                onClick={this.onEditClick}
                            >
                                Edit
                            </div>
                            <div
                                className="dropdown-item clickable"
                                onClick={this.toogleUserStatus}
                            >
                                {user && user.status === true
                                    ? 'Deactivate'
                                    : 'Activate'}
                            </div>
                        </div>
                        <div className="overlay" onWheel={this.onWheel} />
                    </div>
                }
            >
                <span
                    className="user-action-btn clickable"
                    onClick={() => this.setState({ show: !show })}
                >
                    {label}
                </span>
            </Popover>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            showModal,
            toogleUserStatus,
            reloadUserList
        },
        dispatch
    );
};

export default connect(
    null,
    mapDispatchToProps
)(onClickOutside(UserActionsBtn));
