import React from 'react';
import { bool, string } from 'prop-types';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import classNames from 'classnames';

import './Popover-style.css';

export default class PopoverWrapper extends React.PureComponent {
    static propTypes = {
        show: bool,
        className: string
    };
    static defaultProps = {
        position: ['bottom', 'top', 'right', 'left']
    };

    state = {
        show: this.props.show
    };

    onClickOutside = () => {
        this.setState({
            show: false
        });
    };

    render() {
        const { position, className } = this.props;
        const { show } = this.state;
        const componentClass = classNames('popover', className);

        return (
            <Popover
                containerClassName={componentClass}
                isOpen={show}
                position={position}
                onClickOutside={this.onClickOutside}
                content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor={'#36a6b3'}
                        arrowSize={10}
                    >
                        <div className="content">{this.props.children}</div>
                    </ArrowContainer>
                )}
            >
                <div />
            </Popover>
        );
    }
}
