import React from 'react';
import classNames from 'classnames';

const TabItem = ({ item, onSelect }) => {
    const { label, active, icon } = item;

    const onItemClick = () => {
        onSelect(item);
    };
    const itemClass = classNames('nav-link', {
        active: active
    });

    return (
        <li className="nav-item clickable" onClick={onItemClick}>
            <span className={itemClass}>
                {!!icon && icon}
                {label}
            </span>
        </li>
    );
};

export default TabItem;
