import React from 'react';
import { string, number, object } from 'prop-types';
import icons from './icons.svg';

const Icon = ({ name, color, size, onClick, style, title, className }) => (
    <div
        data-tooltip={title || ''}
        className={`${className} g-icon ${title ? 'tooltip' : ''}`}
        onClick={onClick}
        style={style}
    >
        <svg
            className={`g-icon-${name}`}
            fill={color}
            width={size}
            height={size}
        >
            <use xlinkHref={`${icons}#icon-${name}`} />
        </svg>
    </div>
);

Icon.propTypes = {
    name: string.isRequired,
    color: string,
    size: number,
    style: object
};

export default Icon;
