import React from 'react';
import { bool, string, func } from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import './Drawer-style.css';

class Drawer extends React.Component {
    static propTypes = {
        open: bool,
        className: string,
        onClose: func
    };

    handleClickOutside = event => {
        const { open, onClose } = this.props;

        if (open && onClose) {
            onClose();
        }
    };

    render() {
        const { className, open, children, onClose } = this.props;
        // const hasChildren = !!React.Children.count(children);

        const componentClass = classNames('Drawer', className, {
            'is-open': open,
            'is-close': open !== undefined && open === false
        });

        return (
            <div className={componentClass}>
                {onClose && (
                    <i
                        className="fas fa-times close clickable"
                        onClick={onClose}
                    />
                )}
                {children}
            </div>
        );
    }
}

export default onClickOutside(Drawer);
