import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, string, func, bool } from 'prop-types';
import Popover from 'react-tiny-popover';
import { toJS } from 'components';
import { assignUserToOrder, assignUserToJob } from '../orders/actions';

import UserSelection from './UserSelection';

import './UserDropdownInput-style.css';

class UserDropdownInput extends React.Component {
    static propTypes = {
        selectedValue: string,
        userList: array,
        userRole: string,
        target: string,
        readOnly: bool,
        placeholder: string,
        onSelect: func
    };

    state = {
        show: false
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    handleUserSelect = user => {
        const {
            orderId,
            jobId,
            userRole,
            assignUserToOrder,
            assignUserToJob
        } = this.props;

        this.setState({
            show: false
        });

        if (userRole === 'pm') {
            assignUserToOrder(user.userUid, orderId);
        } else if (userRole === 'translator') {
            assignUserToJob(user.userUid, orderId, jobId);
        }
    };

    onButtonClick = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    onWheel = e => {
        this.setState({
            show: false
        });
    };

    render() {
        const {
            userList,
            placeholder,
            selectedValue,
            userRole,
            target,
            readOnly
        } = this.props;
        const { show } = this.state;
        const selectedUser =
            userList && userList.find(user => user.userUid === selectedValue);

        const filteredUsers =
            userList &&
            userList.filter(user => {
                return (
                    user.status === true &&
                    user.roleName.toLowerCase() === userRole.toLowerCase() &&
                    (target ? user.translationLanguages.includes(target) : true)
                );
            });

        if (readOnly) {
            return (
                <span className={`user user-btn`}>
                    {selectedValue ? (
                        <span>
                            <i className="far fa-user-circle" />{' '}
                            {selectedUser && selectedUser.userName}
                        </span>
                    ) : (
                        <span className="content-empty">n/a</span>
                    )}
                </span>
            );
        }

        return (
            <Popover
                isOpen={show}
                position={['bottom', 'left', 'top']}
                content={
                    <div className="user-dropdown-input">
                        {show && (
                            <UserSelection
                                users={filteredUsers}
                                selectedValue={selectedValue}
                                placeholder={
                                    userRole === 'pm'
                                        ? 'Search a PM'
                                        : 'Search a translator'
                                }
                                onSelect={this.handleUserSelect}
                                onClickOutside={this.handleClickOutside}
                            />
                        )}
                        <div
                            className="overlay"
                            onWheel={this.onWheel}
                            onClick={this.handleClickOutside}
                        />
                    </div>
                }
            >
                <span
                    className={`user user-btn clickable ${
                        show ? 'selected' : ''
                    }`}
                    onClick={() => this.setState({ show: !show })}
                >
                    {selectedValue ? (
                        <span>
                            <i className="far fa-user-circle" />{' '}
                            {selectedUser && selectedUser.userName}
                        </span>
                    ) : (
                        <span className="content-empty">{placeholder}</span>
                    )}
                </span>
            </Popover>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userList: state.getIn(['users', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ assignUserToOrder, assignUserToJob }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(UserDropdownInput));
