import React from 'react';

import './LangSummaryTable-style.css';

const LangSummaryTable = ({ rows }) => {
    const buildLangItems = langDataRows => {
        const items = [];

        langDataRows &&
            langDataRows.forEach((data, index) => {
                items.push(
                    <li
                        key={`lang-sum-item-${index}`}
                        className="lang-summary-item"
                    >
                        <div className="cell date">{data.date}</div>
                        <div className="cell note" title={data.info}>
                            {data.info}
                        </div>
                        <div className="cell lang">{data.lang}</div>
                        <div className="cell service">{data.service}</div>
                        <div className="cell count right">
                            {`${data.wordCount} (${data.rate} ${data.currency})`}
                        </div>
                        <div className="cell price right">
                            {data.price} {data.currency}
                        </div>
                    </li>
                );
            });

        return items;
    };

    return (
        <div className="summary worked-summary">
            <div className="title">Summary</div>
            <div className="lang-summary-table">
                <div className="table-header">
                    <div className="cell date">Date</div>
                    <div className="cell note">Note</div>
                    <div className="cell lang">Lang</div>
                    <div className="cell service">Service</div>
                    <div className="cell count right">Wordcount</div>
                    <div className="cell price right">Price</div>
                </div>
                <ul className="body">{buildLangItems(rows)}</ul>
            </div>
        </div>
    );
};

export default LangSummaryTable;
