import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, func, bool } from 'prop-types';
import { downloadFile } from './actions';
import truncate from 'lodash.truncate';

import './FileDownload-style.css';

class FileDownload extends React.PureComponent {
    static propTypes = {
        orderId: string,
        fileName: string,
        handleFileDownload: func,
        isTruncate: bool
    };

    onDownload = () => {
        const { downloadFile, handleFileDownload, ...rest } = this.props;

        if (handleFileDownload) {
            handleFileDownload(rest);
        } else {
            downloadFile(rest);
        }
    };

    render() {
        const { fileName, newFileName, isTruncate } = this.props;
        const name = newFileName || fileName;

        return (
            <span
                className="file-download clickable"
                onClick={this.onDownload}
                title={name}
            >
                {isTruncate
                    ? truncate(name, {
                          length: 25,
                          separator: '... '
                      })
                    : name}
            </span>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ downloadFile }, dispatch);
};

export default connect(null, mapDispatchToProps)(FileDownload);
