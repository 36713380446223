import React from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import { Redirect } from 'react-router-dom';
import { Message } from 'components';

import DateTimePopoverInput from './DateTimePopoverInput';
import { postJobExtension } from 'services/order';

import localizedHelp from '../common/localizeHelper';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        suggestedDeadline: Yup.string().required('Deadline is required')
    }),

    mapPropsToValues: props => ({
        ...props
    }),
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        setStatus({ error: null });
        postJobExtension(props.jobId, props.tok, payload.suggestedDeadline)
            .then(data => {
                setSubmitting(false);
                setStatus({
                    redirectTo:
                        '/message/assignment?msg=job-suggestion-submitted'
                });
            })
            .catch(e => {
                setStatus({
                    error: localizedHelp('msg-submit-job-extension-error')
                });
                setSubmitting(false);
            });
    },
    displayName: 'AssignmentMaybeForm'
});

export const AssignmentMaybe = props => {
    const {
        orderId,
        source,
        target,
        deadline,
        isSubmitting,
        isValid,
        status,
        handleChange,
        handleSubmit,
        setFieldValue
    } = props;

    const message = status && status.error && (
        <Message type="danger" message={status.error} />
    );

    const redirect =
        status && status.redirectTo ? (
            <Redirect to={status.redirectTo} />
        ) : null;

    return (
        <form onSubmit={handleSubmit}>
            {message}
            {redirect}
            <div className="container my-2 assignment-maybe">
                <div className="row col-12 m-3">
                    <div className=" title">
                        <h2 className="page-title">
                            {`Request ${source}-${target}: Order #${orderId}`}
                        </h2>
                    </div>
                </div>
                <div className="row"></div>
                <div className="row m-3">
                    <div className="col-4 justify-content-start">
                        Requested delivery:
                    </div>
                    <div className="col-8 justify-content-start">
                        {deadline}
                    </div>
                </div>
                <div className="row m-3">
                    <div className="col-4 justify-content-start">
                        Your suggested delivery:
                    </div>
                    <div className="col-8 justify-content-start">
                        <DateTimePopoverInput
                            id="suggestedDeadline"
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                        />{' '}
                        GMT+1
                    </div>
                </div>
                <div className="row m-3">
                    <div className="col-4 justify-content-start"></div>
                    <div className="col-8 justify-content-start">
                        <button
                            type="submit"
                            className={`btn  ${!isSubmitting ? 'loading' : ''}`}
                            disabled={isSubmitting || !isValid}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const AssignmentMaybeForm = formikEnhancer(AssignmentMaybe);

export default AssignmentMaybeForm;
