import React from 'react';
import { array, func } from 'prop-types';

import ButtonItem from './ButtonItem';
import './ButtonGroup-style.css';

class ButtonGroup extends React.PureComponent {
    static propTypes = {
        items: array,
        onChange: func
    };
    state = {
        selectedItem: this.props.selectedItem
    };

    onSelect = item => {
        const { onChange } = this.props;

        this.setState({
            selectedItem: item
        });

        if (onChange) {
            onChange(item);
        }
    };

    render() {
        const { items } = this.props;
        const { selectedItem } = this.state;
        const btnItems = [];

        items.forEach((item, index) => {
            btnItems.push(
                <ButtonItem
                    key={`btn_item_${index}`}
                    value={item.value}
                    label={item.label}
                    selected={selectedItem.value === item.value}
                    onSelect={this.onSelect}
                />
            );
        });

        return <div className="button-selection-group">{btnItems}</div>;
    }
}

export default ButtonGroup;
