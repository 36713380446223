import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { requestData, receiveData } from '../api/actions';
import {
    getOrderList,
    getOrderJobList,
    getOrderDetails,
    getFile,
    getExtractedFile,
    getAssetsFile,
    getTranslatedFile,
    getMergedFile,
    assignOrder,
    assignJob,
    setJobDone,
    setDeadline,
    postTranslatedFile,
    mergeFiles,
    sendJobAssignmentEmail,
    postDeliverOrder,
    setJobRequireChecklist,
    getChecklistSubmissionList,
    setManagerNote,
    postManagerAssetsFile,
    getManagerAssetsFile,
    deleteManagerAssetsFile
} from 'services/order';

import { dateTimeToString, DATE_TIME_FORMAT } from '../common/timeUtils';
import { JobTimer } from '../common/jobTimer';

import { ORDER } from './actionConstants';
import { MAX_UPLOAD_FILE_SIZE } from './constants';

const OrderDetailReloader = new JobTimer();

function buildOrderDetailsData(orderPayload) {
    const UTC_OFFSET = 60;

    return {
        ...orderPayload,
        id: orderPayload.id + '',
        orderId: orderPayload.id + '',
        sourceFile: orderPayload.files[0].original,
        combinedFile: get(orderPayload, ['merge', 'original']),
        createdDate: dateTimeToString(
            orderPayload.createdAt,
            DATE_TIME_FORMAT.MM_DD_YYYY,
            UTC_OFFSET
        ),
        status: 'not-assign'
    };
}

function buildJobDetailsData(orderPayload) {
    return {
        ...orderPayload,
        id: orderPayload.id + '',
        orderId: orderPayload.orderId + ''
    };
}

function onResultMessage(dispatch, message) {
    dispatch({
        type: ORDER.SHOW_MESSAGE,
        message
    });

    setTimeout(function() {
        dispatch({
            type: ORDER.CLOSE_MESSAGE
        });
    }, 2500);
}

export function closeMessage() {
    return {
        type: ORDER.CLOSE_MESSAGE
    };
}

function openDownloadedFile(fileName, payload) {
    let a = document.createElement('a');
    a.style = 'display: none';
    const blob = new Blob([payload], {
        type: 'application/octet-stream'
    });

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 100);
}

export function fetchOrderList(params) {
    return dispatch => {
        if (!params.offset) {
            dispatch(requestData(ORDER.FETCH_ORDER_LIST));
        } else {
            dispatch(
                requestData(ORDER.FETCH_ORDER_BUFFER_LIST, {
                    offset: params.offset,
                    limit: params.limit
                })
            );
        }

        getOrderList(params)
            .then(payload => {
                const {
                    data: { limit, offset, total, rows },
                    map: { status }
                } = payload;
                const data =
                    rows &&
                    rows.map(order => {
                        return buildOrderDetailsData(order);
                    });

                dispatch(
                    receiveData(ORDER.ON_ORDER_LIST_FETCHED, {
                        limit,
                        offset,
                        total,
                        dataRows: data,
                        mapStatus: status
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function fetchOrderJobList(orderId) {
    return dispatch => {
        dispatch(requestData(ORDER.FETCH_ORDER_JOB_LIST, { orderId }));

        getOrderJobList(orderId)
            .then(payload => {
                const data =
                    payload.data &&
                    payload.data.map(job => {
                        return buildJobDetailsData(job);
                    });
                dispatch(
                    receiveData(ORDER.ON_ORDER_JOB_LIST_FETCHED, {
                        orderId,
                        jobs: data
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

function reloadOrderDetails(dispatch, orderId) {
    getOrderDetails(orderId)
        .then(payload => {
            const details = buildOrderDetailsData(payload.data);

            dispatch(
                receiveData(ORDER.ON_ORDER_DETAILS_FETCHED, {
                    orderId,
                    details,
                    mapStatus: payload.map.status
                })
            );
        })
        .catch(e => {
            console.log(e);
        });
}

function startOrderDetailsReloading(
    dispatch,
    tryCount = 5,
    orderId,
    currentState
) {
    const jobName = `reloadOrderDetails-${orderId}`;

    OrderDetailReloader.addJob(jobName, function() {
        getOrderDetails(orderId)
            .then(payload => {
                const details = buildOrderDetailsData(payload.data);

                if (
                    get(details, ['state']) !== get(currentState, ['state']) ||
                    tryCount === 0
                ) {
                    dispatch(
                        receiveData(ORDER.ON_ORDER_DETAILS_FETCHED, {
                            orderId,
                            details,
                            mapStatus: payload.map.status
                        })
                    );

                    reloadOrderJobList(dispatch, orderId);
                } else {
                    if (tryCount > 0) {
                        startOrderDetailsReloading(
                            dispatch,
                            tryCount - 1,
                            orderId,
                            currentState
                        );
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    });
}

function reloadOrderJobList(dispatch, orderId) {
    getOrderJobList(orderId)
        .then(payload => {
            const data = payload.data
                ? payload.data.map(job => {
                      return buildJobDetailsData(job);
                  })
                : [];

            dispatch(
                receiveData(ORDER.ON_ORDER_JOB_LIST_FETCHED, {
                    orderId,
                    jobs: data
                })
            );
        })
        .catch(e => {
            console.log(e);
        });
}

function startOrderJobListReloading(
    dispatch,
    tryCount = 5,
    orderId,
    jobId,
    currentState
) {
    const jobName = `reloadOrderJobList-${orderId}`;

    OrderDetailReloader.addJob(jobName, function() {
        getOrderJobList(orderId)
            .then(payload => {
                const data = payload.data
                    ? payload.data.map(job => {
                          return buildJobDetailsData(job);
                      })
                    : [];

                const job = data.find(item => jobId === item.id) || {};

                if (
                    get(job, ['state']) !== get(currentState, ['state']) ||
                    !isEqual(job.progress, currentState.progress)
                ) {
                    dispatch(
                        receiveData(ORDER.ON_ORDER_JOB_LIST_FETCHED, {
                            orderId,
                            jobs: data
                        })
                    );

                    reloadOrderDetails(dispatch, orderId);
                } else {
                    if (tryCount > 0) {
                        startOrderJobListReloading(
                            dispatch,
                            tryCount - 1,
                            orderId,
                            jobId,
                            currentState
                        );
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    });
}

export function fetchOrderDetails(orderId) {
    return dispatch => {
        dispatch(requestData(ORDER.FETCH_ORDER_DETAILS, { orderId }));

        getOrderDetails(orderId)
            .then(payload => {
                const details = buildOrderDetailsData(payload.data);

                dispatch(
                    receiveData(ORDER.ON_ORDER_DETAILS_FETCHED, {
                        orderId,
                        details,
                        mapStatus: payload.map.status
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function assignUserToOrder(userUid, orderId) {
    return dispatch => {
        assignOrder(orderId, userUid)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_ORDER_ASSIGNED, {
                        orderId,
                        details: buildOrderDetailsData(payload.data)
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function assignUserToJob(userUid, orderId, jobId) {
    return dispatch => {
        assignJob(orderId, jobId, userUid)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_JOB_ASSIGNED, {
                        orderId,
                        jobId,
                        details: buildJobDetailsData(payload.data)
                    })
                );

                reloadOrderDetails(dispatch, orderId);
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function confirmJobDone(orderId, jobId) {
    return dispatch => {
        setJobDone(orderId, jobId)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_JOB_DONE_CONFIRMED, {
                        orderId,
                        jobId,
                        details: buildJobDetailsData(payload.data)
                    })
                );

                reloadOrderDetails(dispatch, orderId);
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function notifyJobAssignment(orderId, jobId, userId) {
    return dispatch => {
        sendJobAssignmentEmail(orderId, jobId, userId)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_JOB_EMAIL_SENT, {
                        orderId,
                        jobId,
                        details: buildJobDetailsData(payload.data)
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function setOrderDeadline(orderId, deadline) {
    return dispatch => {
        setDeadline(orderId, deadline)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_ORDER_DEADLINE_SET, {
                        orderId,
                        details: buildOrderDetailsData(payload.data)
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function downloadFile({ orderId, fileName }) {
    return () => {
        getFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function downloadExtractedFile({ orderId, fileName }) {
    return () => {
        getExtractedFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function downloadAssetsFile({ orderId, fileName, newFileName }) {
    return () => {
        getAssetsFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(newFileName || fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function downloadTranslatedFile({ orderId, jobId, fileName }) {
    return () => {
        getTranslatedFile(orderId, jobId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function downloadMergedFile({ orderId, fileName }) {
    return () => {
        getMergedFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function uploadTranslatedFile(orderId, jobId, files) {
    return dispatch => {
        dispatch(requestData(ORDER.UPLOAD_FILE));

        postTranslatedFile(orderId, jobId, files)
            .then(payload => {
                const details = buildJobDetailsData(payload.data);

                dispatch(
                    receiveData(ORDER.ON_TRANSLATED_FILE_UPLOADED, {
                        orderId,
                        jobId,
                        details
                    })
                );

                startOrderJobListReloading(
                    dispatch,
                    5,
                    orderId,
                    jobId,
                    details
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function mergeTranslatedFiles(orderId) {
    return dispatch => {
        dispatch(requestData(ORDER.MERGE_TRANSLATED_FILES));

        mergeFiles(orderId)
            .then(payload => {
                const details = buildOrderDetailsData(payload.data);
                dispatch(
                    receiveData(ORDER.ON_TRANSLATED_FILES_MERGED, {
                        orderId,
                        details
                    })
                );

                startOrderDetailsReloading(dispatch, 5, orderId, details);
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function deliverOrder(orderId) {
    return dispatch => {
        dispatch(requestData(ORDER.DELIVER_ORDER));

        postDeliverOrder(orderId)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_ORDER_DELIVERED, {
                        orderId,
                        details: buildOrderDetailsData(payload.data)
                    })
                );

                onResultMessage(
                    dispatch,
                    'You have delivered the order successfully.'
                );

                reloadOrderJobList(dispatch, orderId);
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function updateJobRequireChecklist({
    orderId,
    jobIds,
    isChecked,
    onFailCallback
}) {
    return dispatch => {
        requestData(ORDER.UPDATE_JOB_CHECKLIST);

        setJobRequireChecklist(orderId, jobIds, isChecked)
            .then(payload => {
                dispatch(receiveData(ORDER.ON_JOB_CHECKLIST_UPDATED));
            })
            .catch(e => {
                onFailCallback && onFailCallback();
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function fetchChecklists(params) {
    return dispatch => {
        if (!params.offset) {
            dispatch(requestData(ORDER.FETCH_CHECKLIST));
        } else {
            dispatch(
                requestData(ORDER.FETCH_BUFFER_CHECKLIST, {
                    offset: params.offset,
                    limit: params.limit
                })
            );
        }

        getChecklistSubmissionList(params)
            .then(payload => {
                const {
                    data: { limit, offset, total, rows }
                } = payload;
                const dataRows =
                    rows &&
                    rows.map(row => {
                        const item = row;
                        const { formData } = row;

                        for (const prop in formData) {
                            item[prop] = formData[prop];
                        }

                        return item;
                    });
                dispatch(
                    receiveData(ORDER.ON_CHECKLIST_FETCHED, {
                        limit,
                        offset,
                        total,
                        dataRows
                    })
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));
            });
    };
}

export function setPMComment({ orderId, text, onSuccess, onError }) {
    return dispatch => {
        setManagerNote(orderId, text)
            .then(payload => {
                dispatch(
                    receiveData(ORDER.ON_PM_COMMENT_UPDATE, {
                        orderId,
                        text
                    })
                );

                onSuccess && onSuccess();
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));

                onError && onError(text);
            });
    };
}

export function uploadPMAssetsFile({
    orderId,
    fileObject,
    onSuccess,
    onError
}) {
    return dispatch => {
        dispatch(requestData(ORDER.UPLOAD_FILE));

        if (fileObject.file.size > MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
            dispatch(
                receiveData(ORDER.ON_FAILED, { code: 'FILE_SIZE_EXCEEDED' })
            );
            return;
        }

        postManagerAssetsFile(orderId, fileObject)
            .then(payload => {
                dispatch(receiveData(ORDER.ON_PM_ASSETS_UPLOADED, { orderId }));

                onSuccess && onSuccess();
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));

                onError && onError();
            });
    };
}

export function downloadPMAssetsFile({ orderId, fileName }) {
    return () => {
        getManagerAssetsFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

export function removePMAssetsFile({
    orderId,
    fileName,
    managerAssets,
    onError
}) {
    return dispatch => {
        deleteManagerAssetsFile(orderId, fileName)
            .then(payload => {})
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ORDER.ON_FAILED, e));

                onError && onError(e, managerAssets);
            });
    };
}
