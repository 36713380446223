import React from 'react';
import { array, string, func } from 'prop-types';
import { SearchInput, MenuItem } from 'components';

class UserSelection extends React.Component {
    static propTypes = {
        selectedValue: string,
        users: array,
        placeholder: string,
        onSelect: func
    };

    static defaultProps = {
        placeholder: 'Search a user'
    };

    state = {
        filter: ''
    };

    onClick = event => {
        event.stopPropagation();
    };

    onSearchInput = text => {
        this.setState({
            filter: text
        });
    };

    onUserSelect = user => {
        const { onSelect } = this.props;

        onSelect(user);
    };

    buildSelectionItems = () => {
        const { users, selectedValue } = this.props;
        const { filter } = this.state;

        const items = [];

        users &&
            users.forEach(user => {
                const found = !(
                    filter &&
                    filter.length > 0 &&
                    user.firstName
                        .toLowerCase()
                        .search(filter.toLowerCase()) === -1
                );

                if (found) {
                    items.push(
                        <MenuItem
                            key={user.userId}
                            className="user"
                            option={{
                                label: user.firstName,
                                icon: <i className="far fa-user-circle" />,
                                selected: user.userUid === selectedValue,
                                ...user
                            }}
                            onClick={this.onUserSelect}
                        />
                    );
                }
            });

        return items;
    };

    render() {
        const { placeholder } = this.props;
        const { filter } = this.state;

        return (
            <div className="user-select" onClick={this.onClick}>
                <div className={'dropdown-menu dropdown-menu-righ show'}>
                    <SearchInput
                        placeholder={placeholder}
                        onChange={this.onSearchInput}
                        value={filter}
                    />
                    <div className="menu-items-container">
                        {this.buildSelectionItems()}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserSelection;
