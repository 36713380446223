import React from 'react';
import { func, string } from 'prop-types';

class UploadIcon extends React.PureComponent {
    static propTypes = {
        id: string.isRequired,
        onNewFiles: func.isRequired,
        title: string
    };

    render() {
        const { id, title } = this.props;
        const elmId = `input-upload-${id}`;

        return (
            <div className="upload-icon" title={title}>
                <label className="select-file-control" htmlFor={elmId}>
                    <i className="fas fa-cloud-upload-alt clickable" />
                    <input
                        type="file"
                        id={elmId}
                        name={id}
                        multiple
                        style={{ display: 'none' }}
                        onChange={e =>
                            this.selectFileHandle(Array.from(e.target.files))
                        }
                    />
                </label>
            </div>
        );
    }

    selectFileHandle = files => {
        const { onNewFiles } = this.props;

        onNewFiles(files);
    };
}

export default UploadIcon;
