import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, array, bool, object } from 'prop-types';

import { Loading, Drawer, toJS } from 'components';
import { fetchUserList, showModal } from './actions';
import UserTable from './UserTable';
import UsersFilterForm from './UsersFilterForm';
import UserModal from './UserModal';
import {
    objectToQueryParams,
    queryParamsToObject,
    queryParamsToString
} from 'common/urlQueryUtils';

import { PAGES } from 'common/const';

import './UserList-style.css';

class UserList extends React.PureComponent {
    static propTypes = {
        data: array,
        user: object.isRequired,
        fetchData: func,
        loading: bool
    };
    state = {
        isOpenFilterTray: undefined,
        isKeepFilterInputtingValues: true
    };

    getCurrentUrlParamsObj = () => {
        const { location } = this.props;

        return queryParamsToObject(location.search);
    };

    getFilterParamsObj = () => {
        const result = {};
        const allParams = this.getCurrentUrlParamsObj();
        const notAllowed = ['sortType', 'sortKey'];
        const filterKeys = Object.keys(allParams).filter(
            key => !notAllowed.includes(key)
        );

        filterKeys.forEach(key => (result[key] = allParams[key]));

        return result;
    };

    getSortParamsObj = () => {
        const result = {};
        const allParams = this.getCurrentUrlParamsObj();
        const allowed = ['sortType', 'sortKey'];
        const filterKeys = Object.keys(allParams).filter(key =>
            allowed.includes(key)
        );

        filterKeys.forEach(key => (result[key] = allParams[key]));

        return result;
    };

    setUrlParams = params => {
        const { history } = this.props;
        const currentParams = this.getCurrentUrlParamsObj();

        history.push({
            pathname: PAGES.USERS,
            search: objectToQueryParams({ ...currentParams, ...params })
        });
    };

    onFilterBtnClick = () => {
        this.setState({
            isOpenFilterTray: true,
            isKeepFilterInputtingValues: true
        });
    };

    onFilterClose = () => {
        this.setState({
            isOpenFilterTray: false
        });
    };

    onFilterApply = params => {
        this.setUrlParams(params);
        this.onFilterClose();
    };

    onFilterCancel = () => {
        this.setState({
            isOpenFilterTray: false,
            isKeepFilterInputtingValues: false
        });
    };

    onFilterClearClick = e => {
        const { history } = this.props;
        e.stopPropagation();

        history.push({
            search: objectToQueryParams(this.getSortParamsObj())
        });

        this.onFilterCancel();
    };

    onSortChange = (columnKey, sortType) => {
        this.setUrlParams({
            sortKey: columnKey,
            sortType
        });
    };

    onAddClick = () => {
        const { showModal } = this.props;

        showModal();
    };

    componentDidMount() {
        const { loading, data, fetchData, user } = this.props;

        if (!loading && !data) {
            fetchData(user.userUid);
        }
    }

    render() {
        const { loading, data } = this.props;
        const { isOpenFilterTray, isKeepFilterInputtingValues } = this.state;
        const filterQueryObj = this.getFilterParamsObj();
        const filterString = queryParamsToString(
            objectToQueryParams(filterQueryObj)
        );
        const filterBtnContent = filterString ? (
            <span>
                {filterString}{' '}
                <i
                    className="fas fa-times icon-clear-filter"
                    onClick={this.onFilterClearClick}
                />
            </span>
        ) : (
            'Filter'
        );

        if (loading || !data) {
            return <Loading />;
        }

        return (
            <div className="user-list h-100">
                <div className="header d-flex justify-content-between m-3">
                    <h2 className="page-title">User list</h2>

                    <button
                        type="button"
                        className="btn add-btn"
                        onClick={this.onAddClick}
                    >
                        <i className="fas fa-plus" />
                        Add new
                    </button>
                    <div
                        className="filter-btn clickable"
                        onClick={this.onFilterBtnClick}
                    >
                        <i className="fas fa-filter" />
                        <span className="value"> {filterBtnContent}</span>
                    </div>
                    <Drawer
                        className="filter-tray"
                        open={isOpenFilterTray}
                        onClose={this.onFilterClose}
                    >
                        {isKeepFilterInputtingValues && (
                            <UsersFilterForm
                                values={filterQueryObj}
                                onCancel={this.onFilterCancel}
                                onEnter={this.onFilterApply}
                            />
                        )}
                    </Drawer>
                </div>
                <UserTable
                    data={data}
                    filterParams={filterQueryObj}
                    sortParams={this.getSortParamsObj()}
                    onSortChange={this.onSortChange}
                />
                <UserModal />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['users', 'loading']),
        data: state.getIn(['users', 'data']),
        user: state.getIn(['auth', 'user'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchUserList,
            showModal
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(UserList));
