import React from 'react';
import localizeHelper from '../../common/localizeHelper';

import './FileProgress-style.css';

const ProgressBarEmpty = () => {
    return (
        <div
            className="progress file-progress"
            title={localizeHelper('FILE_PROGRESS_EMPTY')}
        >
            <div className="position-absolute w-100 text-center font-weight-bold">
                Checking...
            </div>
        </div>
    );
};

const ProgressBarError = ({ err }) => {
    return (
        <div
            className="progress file-progress border-danger"
            title={localizeHelper(err.message)}
        >
            <div className="position-absolute w-100 text-center text-danger font-weight-bold">
                File Error
            </div>
        </div>
    );
};

const ProgressBar = ({ value, total }) => {
    const completed = parseInt((value * 100) / total);
    const text = `${value}/${total} strings`;
    const textClasses =
        completed > 85
            ? 'position-absolute w-100 text-center text-white'
            : 'position-absolute w-100 text-center';

    return (
        <div className="progress file-progress position-relative">
            <div className={textClasses}> {text}</div>
            <div
                className="progress-bar bg-success"
                style={{ width: completed + '%' }}
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={completed}
            />
        </div>
    );
};

export default function FileProgress(props) {
    if (!props.data) return <ProgressBarEmpty />;

    const { err, totalRow, rowTranslated } = props.data;
    if (err) return <ProgressBarError err={err} />;

    return (
        <ProgressBar
            className={props.className}
            value={rowTranslated}
            total={totalRow}
        />
    );
}
