import React from 'react';
import classNames from 'classnames';

import './FileAsset-style.css';

const FileAsset = ({
    className,
    fileName,
    handleFileDownload,
    handleFileRemove
}) => {
    const componentClass = classNames('file-info', className);

    return (
        <div className={componentClass}>
            <div
                className="file-download clickable"
                onClick={handleFileDownload}
            >
                <span className="file-name">{fileName}</span>
            </div>
            <div className="line" />
            <div className="file-delete">
                <i
                    className="fas fa-trash clickable icon-delete"
                    onClick={handleFileRemove}
                />
            </div>
        </div>
    );
};

export default FileAsset;
