export const DATE_TIME_FORMAT = {
    HH_MM: 'HH:MM',
    MM_DD_YYYY: 'MM-DD-YYYY',
    MM_DD_YYYY_HH_MM: 'MM-DD-YYYY HH:MM'
};

function addZero(i) {
    return i < 10 ? '0' + i : i;
}

export function dateTimeToString(utcDateTime, format, utcOffset = 0) {
    const date = new Date(utcDateTime);
    const offset = new Date().getTimezoneOffset() + utcOffset;

    date.setTime(date.getTime() + offset * 60 * 1000);
    const y = date.getFullYear();
    const n = addZero(date.getMonth() + 1);
    const d = addZero(date.getDate());
    const h = addZero(date.getHours());
    const m = addZero(date.getMinutes());
    const s = addZero(date.getSeconds());
    let result;

    switch (format) {
        case DATE_TIME_FORMAT.HH_MM:
            result = h + ':' + m;
            break;
        case DATE_TIME_FORMAT.MM_DD_YYYY:
            result = n + '-' + d + '-' + y;
            break;
        case DATE_TIME_FORMAT.MM_DD_YYYY_HH_MM:
            result = n + '-' + d + '-' + y + ' ' + h + ':' + m;
            break;

        default:
            result = h + ':' + m + ':' + s;
    }

    return result;
}
