import { combineReducers } from 'redux-immutable';
import { optimistic } from 'redux-optimistic-ui';

import authReducer from '../auth/reducer';
import ordersReducer from '../orders/reducer';
import usersReducer from '../users/reducer';
import customersReducer from '../customers/reducer';
import qnaReducer from '../qna/reducer';
import trackerReducer from '../tracker/reducer';

export default combineReducers({
    auth: authReducer,
    orders: ordersReducer,
    users: usersReducer,
    customers: optimistic(customersReducer),
    qna: qnaReducer,
    tracker: trackerReducer
});
