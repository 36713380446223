import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
    Modal,
    TextFieldInput,
    Select,
    Checkbox,
    TextInput,
    Message,
    SpinnerIcon
} from 'components';
import { withFormik } from 'formik';
import Yup from 'yup';
import { LANGUAGE_OPTIONS } from 'common/languageData';
//import { postQACheclistForm, notifyToSlack } from 'services/gridly';
import { postQACheclistForm } from 'services/order';
import { CHECK_ITEMS } from './const';

import '../auth/auth.css';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        gameTitle: Yup.string().required('Game title is required'),
        targetLanguage: Yup.string().required('Target language is required')
    }),
    displayName: 'QAChecklistForm',
    mapPropsToValues: ({ translatorFullName, projectName, target }) => ({
        fullName: translatorFullName,
        gameTitle: projectName,
        targetLanguage: target,
        transOrProof: 'Translation'
    }),
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        const { orderId, jobId, onSubmit } = props;

        setStatus({ error: null });
        setSubmitting(true);

        postQACheclistForm(orderId, jobId, payload)
            .then(data => {
                onSubmit && onSubmit();
                setSubmitting(false);
            })
            .catch(e => {
                setStatus({
                    error: `Oops. Please try again or contact pm@localizedirect.com`
                });
                setSubmitting(false);
            });
    }
});

const QAChecklistForm = ({
    values,
    touched,
    errors,
    status,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue
}) => {
    const message = status && status.error && (
        <Message type="danger" message={status.error} />
    );

    return (
        <div className="qachecklist-form">
            <div className="action-container">
                <div className="title">Translation QA Checklist Form</div>
                <label className="form-label">
                    Please fill the fields below. By clicking in each box you
                    confirm that you have performed those steps before delivery.
                </label>
            </div>
            <form onSubmit={handleSubmit}>
                <TextFieldInput
                    id="fullName"
                    type="text"
                    label="Translator's full name"
                    className="input-line"
                    placeholder=""
                    error={touched.fullName && errors.fullName}
                    value={values.fullName || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextFieldInput
                    id="gameTitle"
                    type="text"
                    label="Game title"
                    className="input-line"
                    placeholder=""
                    error={touched.gameTitle && errors.gameTitle}
                    value={values.gameTitle || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Select
                    id="targetLanguage"
                    label="Target language"
                    options={LANGUAGE_OPTIONS}
                    value={values.targetLanguage}
                    placeholder=""
                    isMulti={false}
                    error={touched.targetLanguage && errors.targetLanguage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />
                <Select
                    id="transOrProof"
                    label="Translation or proofreading?"
                    options={[
                        { value: 'Translation', label: 'Translation' },
                        { value: 'Proofreading', label: 'Proofreading' }
                    ]}
                    value={values.transOrProof}
                    placeholder=""
                    isMulti={false}
                    error={touched.transOrProof && errors.transOrProof}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                {CHECK_ITEMS.map((item, index) => (
                    <div
                        key={`check-item-${index}`}
                        className="form-group check-list-item"
                    >
                        <label className="form-label">{`${index + 1}. ${
                            item.title
                        }`}</label>
                        <div>
                            <Checkbox
                                id={`check${index + 1}`}
                                onChange={handleChange}
                                checked={values[`check${index + 1}`]}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                            />
                            {'  '}
                            <span className="form-note">{item.note}</span>
                        </div>
                    </div>
                ))}

                <div className="form-group">
                    <label className="form-label">
                        Translator/Proofreader additional comments
                    </label>
                    <div className="form-note">
                        Add any relevant comments that LocalizeDirect should be
                        aware.
                    </div>
                    <TextInput
                        id="transOrProofComments"
                        rows={3}
                        onChange={handleChange}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </div>

                <div>
                    {message}

                    <div className="submit-btns">
                        <button
                            type="submit"
                            className={`btn btn-submit ${
                                isSubmitting ? 'loading' : ''
                            }`}
                            disabled={isSubmitting || !isEmpty(errors)}
                        >
                            {isSubmitting && <SpinnerIcon />}
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(QAChecklistForm);

const QAChecklistModal = ({ onSubmit, onCancel, ...rest }) => {
    return (
        <Modal
            show
            closeable={true}
            onClose={onCancel}
            className={`qachecklist-modal auth-modal`}
            header={<div className="auth-header logo-icon" />}
        >
            <EnhancedForm onSubmit={onSubmit} onCancel={onCancel} {...rest} />
        </Modal>
    );
};

export default QAChecklistModal;
