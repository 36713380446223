import Cookies from 'js-cookie';
import { removeState } from '../common/localStorage';
import { signOut } from 'services/auth';
import { receiveData } from '../api/actions';
import { getUserCompanyInfo, verifyResetPassword } from 'services/auth';

import { FORM_TYPES } from './constants';

import {
    ON_LOG_IN_SUCCESS,
    ON_SIGN_UP_SUCCESS,
    ON_EDIT_PROFILE_SUCCESS,
    ON_RESET_PASSWORD_SUCCESS,
    LOG_OUT,
    AUTH_SHOW_MODAL,
    AUTH_CLOSE_MODAL,
    ON_USER_COMPANY_INFO_FETCHED,
    ON_PASSWORD_VERIFY_SUCCESS,
    ON_PASSWORD_VERIFY_FAILED,
    ON_PASSWORD_CHANGED_SUCCESS
} from './actionConstants';

export function onLogInSuccess(payload) {
    return {
        type: ON_LOG_IN_SUCCESS,
        payload
    };
}

export function onSignUpSuccess(payload) {
    return {
        type: ON_SIGN_UP_SUCCESS,
        payload
    };
}

export function onEditProfileSuccess(payload) {
    return {
        type: ON_EDIT_PROFILE_SUCCESS,
        payload
    };
}

export function clearUserSession() {
    signOut({ token: Cookies.get('token') });
    removeState();
    Cookies.remove('token');
}

export function onResetPasswordSuccess(payload) {
    return {
        type: ON_RESET_PASSWORD_SUCCESS,
        payload
    };
}

export function onPasswordChangedSuccess(payload) {
    return {
        type: ON_PASSWORD_CHANGED_SUCCESS,
        payload
    };
}

export function logOut(history) {
    clearUserSession();

    history.push('/login');
    //window.location.reload();

    return {
        type: LOG_OUT
    };
}

export function showModal(type) {
    return {
        type: AUTH_SHOW_MODAL,
        payload: type
    };
}

export function closeModal() {
    return {
        type: AUTH_CLOSE_MODAL
    };
}

export function onForgotPasswordOpen() {
    return {
        type: AUTH_SHOW_MODAL,
        payload: FORM_TYPES.FORGOT_PASSWORD
    };
}

export function verifyPasswordReset({ userUid, token, history }) {
    return dispatch => {
        verifyResetPassword({
            userUid,
            token
        })
            .then(payload => {
                Cookies.set('resetToken', payload.accessToken, {
                    expires: 365
                });

                history.push('/');
                dispatch(receiveData(ON_PASSWORD_VERIFY_SUCCESS, payload));
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(ON_PASSWORD_VERIFY_FAILED, e));
            });
    };
}

export function fetchUserCompanyInfo(user) {
    return (dispatch, getState) => {
        getUserCompanyInfo({
            userUid: user.userUid,
            companyUid: user.companyUid
        })
            .then(payload => {
                const data = payload;

                dispatch(receiveData(ON_USER_COMPANY_INFO_FETCHED, data));
            })
            .catch(e => {
                console.log(e);
            });
    };
}
