import React from 'react';
import { Modal } from 'components';

import './ConfirmModal-style.css';

const ConfirmModal = ({ show, title, content, onCancel, onConfirm }) => {
    const footer = (
        <div className="confirm-btns">
            <button type="button" className="btn cancel-btn" onClick={onCancel}>
                No
            </button>
            <button
                type="button"
                className="btn confirm-btn"
                onClick={onConfirm}
            >
                Yes
            </button>
        </div>
    );

    return (
        <Modal
            className="confirm-modal"
            show={show}
            closeable={false}
            onClose={onCancel}
            header={title}
            footer={footer}
        >
            {content}
        </Modal>
    );
};

export default ConfirmModal;
