import React from 'react';
import { array, object, func } from 'prop-types';
import clone from 'lodash/clone';
import { AutoSizer } from 'react-virtualized';
import { Table, Checkbox } from 'components';
import OrderIdCell from './OrderIdCell';

import { dateTimeToString, DATE_TIME_FORMAT } from '../common/timeUtils';
import { CHECK_ITEMS } from './const';
const UTC_OFFSET = 60;

class ChecklistTable extends React.PureComponent {
    static propTypes = {
        data: array,
        mapStatus: object,
        filterParams: object,
        sortParams: object,
        onSortChange: func,
        fetchBufferData: func
    };

    static defaultProps = {
        filterParams: {},
        sortParams: {}
    };

    orderIdRender = ({ rowData, columnKey }) => {
        const orderId = rowData[columnKey];

        return <OrderIdCell orderId={orderId} />;
    };

    checkRender = ({ rowData, columnKey }) => {
        const isChecked = rowData[columnKey];

        return <Checkbox checked={isChecked} disabled />;
    };

    dateTimeRender = ({ rowData, columnKey }) => {
        const value = rowData[columnKey];

        return (
            <span>
                {dateTimeToString(
                    value,
                    DATE_TIME_FORMAT.MM_DD_YYYY_HH_MM,
                    UTC_OFFSET
                )}
            </span>
        );
    };

    headerDeliveredRender = ({ label }) => {
        return (
            <span>
                {label} <span className="note-text">(GMT+1)</span>
            </span>
        );
    };

    buildColumns = () => {
        const columns = [
            {
                label: 'Order ID',
                columnKey: 'orderId',
                cellRenderer: this.orderIdRender,
                flexGrow: 0,
                width: 100
            },
            {
                label: `Translator's full name`,
                columnKey: 'fullName',
                sortable: false,
                flexGrow: 1
            },
            {
                label: `Game title`,
                columnKey: 'gameTitle',
                sortable: false
            },
            {
                label: `Target language`,
                columnKey: 'targetLanguage',
                sortable: false,
                flexGrow: 1
            },
            {
                label: `Translation or proofreading?`,
                columnKey: 'transOrProof',
                sortable: false,
                flexGrow: 1
            }
        ];

        CHECK_ITEMS.forEach((item, index) => {
            columns.push({
                label: `${index + 1}. ${item.title}`,
                columnKey: `check${index + 1}`,
                cellRenderer: this.checkRender,
                sortable: false
            });
        });

        columns.push(
            {
                label: `Translator/Proofreader additional comments`,
                columnKey: 'transOrProofComments',
                sortable: false,
                flexGrow: 1,
                width: 500
            },
            {
                label: `Delivered date`,
                columnKey: 'createdAt',
                headerRenderer: this.headerDeliveredRender,
                cellRenderer: this.dateTimeRender,
                sortable: false,
                flexGrow: 1,
                width: 150
            }
        );

        return columns;
    };

    onBufferDataShouldFetch = (startIndex, bufferCount) => {
        const { fetchBufferData } = this.props;

        fetchBufferData(startIndex, bufferCount);
    };

    render() {
        const { data, sortParams, onSortChange } = this.props;

        const { sortKey, sortType } = sortParams;

        const columns = this.buildColumns();
        let filteredData = clone(data);

        /*for (let param in filterParams) {
            if (filterParams[param] !== undefined) {
                filteredData = filteredData.filter(data => {
                    return (
                        data[param] &&
                        data[param]
                            .toLowerCase()
                            .includes(filterParams[param].toLowerCase())
                    );
                });
            }
        }*/

        if (sortKey && sortType) {
            filteredData.sort((dataA, dataB) => {
                const valueA = dataA[sortKey];
                const valueB = dataB[sortKey];
                let sortVal = 0;

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }

        return (
            <div className="table-container">
                <AutoSizer>
                    {({ width, height }) => (
                        <React.Fragment>
                            <Table
                                data={filteredData}
                                columns={columns}
                                width={width}
                                height={height - 50}
                                headerHeight={50}
                                sortKey={sortKey}
                                sortType={sortType}
                                onSortChange={onSortChange}
                                onBufferDataShouldFetch={
                                    this.onBufferDataShouldFetch
                                }
                            />
                        </React.Fragment>
                    )}
                </AutoSizer>
            </div>
        );
    }
}

export default ChecklistTable;
