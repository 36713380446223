import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { Drawer } from 'components';
import QnAContainer from './QnAContainer';

import './style.css';

class QnA extends React.PureComponent {
    static propTypes = {
        orderId: string,
        orderUid: string
    };

    state = {
        isOpenQnATray: undefined
    };

    onIconClick = () => {
        const { isOpenQnATray } = this.state;

        this.setState({
            isOpenQnATray: !isOpenQnATray
        });
    };

    render() {
        const { isOpenQnATray } = this.state;
        const iconClass = classNames('qna-icon clickable', {
            'to-close': isOpenQnATray,
            'to-open': !isOpenQnATray
        });

        return (
            <React.Fragment>
                <div className="qna-icon-wrapper">
                    <div className={iconClass} onClick={this.onIconClick} />
                </div>
                <Drawer className="qna-tray" open={isOpenQnATray}>
                    <QnAContainer {...this.props} />
                </Drawer>
            </React.Fragment>
        );
    }
}

export default QnA;
