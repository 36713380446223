import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';
import App from './App';
import AppPublic from './AppPublic';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configStore';
import { fromJS } from 'immutable';
import { loadState, saveState } from './common/localStorage';

const persistedState = fromJS(loadState());
const store = configureStore(persistedState);

store.subscribe(
    throttle(() => {
        const state = store.getState();

        saveState({
            auth: {
                user: state.getIn(['auth', 'user']),
                modal: {}
            }
        });
    }, 1000)
);

if (
    window.location.href.includes('/extracted-file') ||
    window.location.href.includes('/delivery') ||
    window.location.href.includes('/tracker')
) {
    ReactDOM.render(
        <AppPublic store={store} />,
        document.getElementById('public-root')
    );
} else {
    ReactDOM.render(<App store={store} />, document.getElementById('root'));
}

unregister();
