export const objectToQueryParams = dataObj => {
    const params = [];

    for (let propItem in dataObj) {
        if (dataObj[propItem] !== undefined && dataObj[propItem] !== '') {
            params.push(
                encodeURIComponent(propItem) +
                    '=' +
                    encodeURIComponent(dataObj[propItem])
            );
        }
    }

    return params.join('&');
};

export const queryParamsToObject = paramsString => {
    const params = decodeURIComponent(paramsString)
        .replace(/(^\?)/, '')
        .split('&');
    const resultObj = {};

    params.forEach(param => {
        const items = param.split('=');

        if (items[0]) {
            resultObj[items[0]] = items[1];
        }
    });

    return resultObj;
};

export const queryParamsToString = paramsString => {
    return decodeURIComponent(paramsString)
        .replace(/(^\?)/, '')
        .replace('&', ';');
};
