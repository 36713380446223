export const QNA = {
    CLOSE_MESSAGE: 'QNA_CLOSE_MESSAGE',
    TOGGLE_ADDING_QUESTION: 'QNA_TOGGLE_ADDING_QUESTION',
    FETCH_QUESTION_LIST: 'QNA_FETCH_QUESTION_LIST',
    ON_QUESTION_LIST_FETCHED: 'QNA_ON_QUESTION_LIST_FETCHED',
    ADD_QUESTION: 'QNA_ADD_QUESTION',
    ON_QUESTION_ADDED: 'QNA_ON_QUESTION_ADDED',
    RESOLVE_QUESTION: 'QNA_RESOLVE_QUESTION',
    ON_QUESTION_RESOLVED: 'QNA_ON_QUESTION_RESOLVED',
    ADD_ANSWER: 'QNA_ADD_ANSWER',
    ON_ANSWER_ADDED: 'QNA_ON_ANSWER_ADDED',
    ON_FAILED: 'QNA_ON_FAILED'
};

export const QANDA_STATE = {
    CREATED: 0,
    STAND_BY: 1,
    QUEUED: 2,
    REPLIED: 4,
    RESOLVED: 8
};
