import React, { Fragment } from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import { ConfirmModal } from 'components';
import { MESSAGES } from './constants';

class DeliverButton extends React.Component {
    static propTypes = {
        disabled: bool,
        onDeliverConfirmed: func
    };

    state = {
        modalShow: false
    };

    onDeliverClick = () => {
        this.setState({
            modalShow: true
        });
    };

    onCancelClick = () => {
        this.setState({
            modalShow: false
        });
    };

    onConfirmClick = () => {
        const { onDeliverConfirmed } = this.props;

        this.setState({
            modalShow: false
        });

        onDeliverConfirmed();
    };

    render() {
        const { modalShow } = this.state;
        const { disabled } = this.props;
        const btnClass = classNames('btn btn-sm deliver-btn', {
            disabled: disabled
        });

        return (
            <Fragment>
                <button
                    type="button"
                    className={btnClass}
                    onClick={disabled ? undefined : this.onDeliverClick}
                >
                    <i className="fas fa-location-arrow" />
                    Deliver
                </button>
                {modalShow && (
                    <ConfirmModal
                        show={modalShow}
                        title={MESSAGES.DELIVER_COMFIRM_TITLE}
                        content={MESSAGES.DELIVER_COMFIRM_CONTENT}
                        onCancel={this.onCancelClick}
                        onConfirm={this.onConfirmClick}
                    />
                )}
            </Fragment>
        );
    }
}

export default DeliverButton;
