import React from 'react';
import { func, string, any, number } from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy';
const DEFAULT_TIME_FORMAT = 'HH:mm';

class DateTimeInput extends React.Component {
    static propTypes = {
        selectedValue: any,
        placeholder: string,
        className: string,
        utcOffset: number, // in minutes
        onEnter: func
    };

    static defaultProps = {
        utcOffset: 0
    };

    state = {
        value: null,
        valueDisplay: null
    };

    getDateTimeFormat() {
        return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    }

    handleChange = date => {
        const { utcOffset } = this.props;
        let val = null;

        if (date) {
            // Get date and time value without timezone
            const format = this.getDateTimeFormat();
            val = moment(date, format);
            const offsetInMinutes = val.utcOffset();

            // UTC date time before parsing to iso to prevent the date time value auto converted from local to utc.
            val = moment
                .utc(val)
                .add(offsetInMinutes - utcOffset, 'm')
                .toISOString();
        }

        this.setState({
            value: val,
            valueDisplay: date
        });

        return date;
    };

    handleCalendarClose = () => {
        const { value } = this.state;
        const { onEnter } = this.props;

        if (value) {
            onEnter(value);
        }
    };

    onBlur = () => {
        this.handleCalendarClose();
    };

    getValue = () => {
        const { selectedValue, utcOffset } = this.props;

        if (selectedValue) {
            var date = new Date(selectedValue);
            const offset = new Date().getTimezoneOffset() + utcOffset;

            date.setTime(date.getTime() + offset * 60 * 1000);

            return date;
        }

        return new Date();
    };

    render() {
        const { placeholder, autoFocus } = this.props;
        const { valueDisplay } = this.state;
        const selectedValue = valueDisplay
            ? typeof valueDisplay === 'string'
                ? new Date(valueDisplay)
                : valueDisplay
            : this.getValue();

        return (
            <DatePicker
                selected={selectedValue}
                showTimeSelect
                dateFormat={this.getDateTimeFormat()}
                timeFormat={DEFAULT_TIME_FORMAT}
                placeholderText={placeholder || this.getDateTimeFormat()}
                onChange={this.handleChange}
                onCalendarClose={this.handleCalendarClose}
                onBlur={this.onBlur}
                timeIntervals={30}
                autoFocus={autoFocus}
            />
        );
    }
}

export default DateTimeInput;
