import React from 'react';
import { queryParamsToObject } from 'common/urlQueryUtils';
import { API_URL } from 'common/const';

class ExtractedFilePage extends React.PureComponent {
    componentDidMount() {
        const { location } = this.props;
        const params = queryParamsToObject(location.search);

        if (params.orderId && params.tok) {
            window.location.replace(
                `${API_URL}/tms/interaction/extracted-file/${params.orderId}/${params.jobId}?tok=${params.tok}`
            );
        }
    }

    render() {
        return <span />;
    }
}

export default ExtractedFilePage;
