import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, object, func, bool } from 'prop-types';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { TextInput, UploadBox } from 'components';
import {
    setPMComment,
    uploadPMAssetsFile,
    downloadPMAssetsFile,
    removePMAssetsFile
} from './actions';
import FileAsset from './FileAsset';
import FileDownload from './FileDownload';
import { getFileExt } from '../common/fileUtils';
import { MAX_UPLOAD_FILE_SIZE } from './constants';

class PMNotes extends React.PureComponent {
    static propTypes = {
        orderId: string,
        orderDetail: object,
        readOnly: bool,
        setPMComment: func
    };

    state = {
        status: null,
        managerAssets: this.props.orderDetail
            ? this.props.orderDetail.managerAssets || []
            : []
    };

    handlePMCommentsChange = value => {
        const { orderId, setPMComment } = this.props;

        this.setState({ status: 'saving' });
        setPMComment({
            orderId,
            text: value,
            onSuccess: () => {
                this.setState({ status: 'saved' });
            },
            onError: () => {
                this.setState({ status: 'error' });
            }
        });
    };

    buildDuplicatedFileName = (fileName, existFileNames, count) => {
        if (!existFileNames.includes(fileName)) {
            return fileName;
        }

        const fileExt = getFileExt(fileName);
        const newName =
            fileName.replace(/ \(.\)/, '').replace(`${fileExt}`, '') +
            ` (${count + 1})${fileExt}`;

        return this.buildDuplicatedFileName(newName, existFileNames, count + 1);
    };

    onAssetsInput = files => {
        const { orderId, uploadPMAssetsFile } = this.props;
        const { managerAssets = [] } = this.state;
        const newFileName = this.buildDuplicatedFileName(
            files[0].name,
            managerAssets,
            0
        );

        uploadPMAssetsFile({
            orderId,
            fileObject: {
                name: newFileName,
                file: files[0]
            },
            onSuccess: () => {
                this.setState({
                    managerAssets: [...managerAssets, newFileName]
                });
            },
            onError: () => {
                this.setState({ managerAssets });
            }
        });
    };

    buildAssetFileList = readOnly => {
        const {
            orderId,
            downloadPMAssetsFile,
            removePMAssetsFile
        } = this.props;
        const { managerAssets } = this.state;

        if (!managerAssets || !managerAssets.length) {
            return [];
        }

        const list = [];

        managerAssets.forEach(fileName => {
            const downloadFile = () => {
                downloadPMAssetsFile({ orderId, fileName });
            };
            const removeFile = () => {
                this.setState({
                    managerAssets: managerAssets.filter(
                        file => file !== fileName
                    )
                });

                removePMAssetsFile({
                    orderId,
                    fileName,
                    managerAssets,
                    onError: (error, managerAssets) => {
                        this.setState({
                            managerAssets
                        });
                    }
                });
            };

            if (readOnly) {
                list.push(
                    <div className="file-info" key={`asset-file-${fileName}`}>
                        <FileDownload
                            fileName={fileName}
                            handleFileDownload={downloadFile}
                        />
                    </div>
                );
            } else {
                list.push(
                    <FileAsset
                        key={`asset-file-${fileName}`}
                        fileName={fileName}
                        handleFileDownload={downloadFile}
                        handleFileRemove={removeFile}
                    />
                );
            }
        });

        return <div className="asset-files">{list}</div>;
    };

    render() {
        const { orderDetail, readOnly } = this.props;
        const { status } = this.state;

        return (
            <div className="container pm-notes">
                <div className="row">
                    <div className="col-md-4">
                        <div className="label">Note</div>
                        <div className="note">
                            {readOnly ? (
                                <pre className="note-review">
                                    {orderDetail.managerNote}
                                </pre>
                            ) : (
                                <TextInput
                                    id="pmComments"
                                    name="pmComments"
                                    value={orderDetail.managerNote || ''}
                                    rows={5}
                                    status={status}
                                    useDebounce
                                    onChange={this.handlePMCommentsChange}
                                />
                            )}
                        </div>
                    </div>
                    {readOnly ? (
                        <div className="col-md-8">
                            <div className="label">Assets</div>
                            {this.buildAssetFileList(readOnly)}
                        </div>
                    ) : (
                        <div className="col-md-6">
                            <div className="label">
                                Assets{' '}
                                <span className="note-text">
                                    {`(Maximum upload file size: ${MAX_UPLOAD_FILE_SIZE} Mb)`}
                                </span>
                            </div>
                            <UploadBox
                                id="pm-assets-upload-box"
                                name="pm-assets-upload-box"
                                onNewFiles={this.onAssetsInput}
                                disabled={false}
                            />
                            {this.buildAssetFileList()}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            setPMComment,
            uploadPMAssetsFile,
            downloadPMAssetsFile,
            removePMAssetsFile
        },
        dispatch
    );
};

export default DragDropContext(HTML5Backend)(
    connect(null, mapDispatchToProps)(PMNotes)
);
