import React from 'react';
import { any, object } from 'prop-types';
export class Page extends React.Component {
    static propTypes = {
        component: any.isRequired,
        data: object.isRequired,
        ctx: any.isRequired
    };

    componentDidMount() {
        const { data, ctx } = this.props;
        ctx.changePage(data.match.path);
    }

    render() {
        const { component, data, ctx } = this.props;
        return React.createElement(component, { ...data, ...ctx });
    }
}
