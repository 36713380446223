import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { object, func } from 'prop-types';
import classNames from 'classnames';

class UserDropdown extends Component {
    static propTypes = {
        user: object,
        handleLogout: func
    };

    state = {
        show: false
    };

    onIconClick = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    onLogoutClick = () => {
        const { history, handleLogout } = this.props;

        handleLogout(history);
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    render() {
        const { user } = this.props;
        const { show } = this.state;
        const btnClass = classNames('btn-group', {
            show: show
        });
        const dropdownClass = classNames('dropdown-menu dropdown-menu-right', {
            show: show
        });

        return (
            <div className={btnClass}>
                <button
                    type="button"
                    className="btn btn-user-info dropdown-toggle"
                    onClick={this.onIconClick}
                >
                    <i className="far fa-user-circle" />
                    &nbsp;{user.firstName}
                </button>
                {user && (
                    <div className={dropdownClass}>
                        <div className="dropdown-item">
                            <i className="far fa-user-circle" />
                            &nbsp; {user.firstName} {user.lastName}
                        </div>
                        <div className="dropdown-divider" />
                        <div
                            className="dropdown-item btn-logout clickable"
                            onClick={this.onLogoutClick}
                        >
                            Log out
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(onClickOutside(UserDropdown));
