import React from 'react';
import { PAGES } from 'common/const';
import { Navbar, Nav, NavItem } from 'components';
import { UserNav, AccessControl } from 'auth';

import './AppHeader-style.css';

const NavGroups = ({ current, pages, group, onItemClick }) => {
    return pages
        .filter(p => p.group === group)
        .map(({ path, title, requiredRole }) => {
            const isActive = path === current;
            return (
                <AccessControl
                    key={path}
                    requiredRole={requiredRole}
                    can={'view'}
                >
                    <NavItem
                        active={isActive}
                        path={path}
                        title={title}
                        onClick={onItemClick}
                    />
                </AccessControl>
            );
        });
};

export class AppHeader extends React.PureComponent {
    state = {
        expanded: false
    };
    render() {
        const { current, pages } = this.props;
        const { expanded } = this.state;

        return (
            <div className="app-header header">
                <Navbar classNames="navbar-light sticky-top">
                    <Navbar.Brand path={PAGES.HOME}>
                        <img
                            title="Home"
                            alt="logo"
                            src="/img/logo_ld_small.png"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        target="navbar-collapse-container"
                        onToggle={this.handleMenuToggle}
                        expanded={expanded}
                    />
                    <Navbar.Collapse
                        targetId="navbar-collapse-container"
                        expanded={expanded}
                    >
                        <Nav className="navbar-nav mr-auto">
                            <NavGroups
                                current={current}
                                pages={pages}
                                group="left"
                                onItemClick={this.handleMenuToggle}
                            />
                        </Nav>
                        <Nav classNames="navbar-nav mr-auto">
                            <NavGroups
                                current={current}
                                pages={pages}
                                group="center"
                                onItemClick={this.handleMenuToggle}
                            />
                        </Nav>
                        <Nav className="navbar-nav mr-auto">
                            <NavGroups
                                current={current}
                                pages={pages}
                                group="right"
                                onItemClick={this.handleMenuToggle}
                            />
                        </Nav>
                        <UserNav />
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
    handleMenuToggle = e => {
        this.setState({ expanded: !this.state.expanded });
    };
}
