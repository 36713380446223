import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import { TextInput, Spinner, toJS } from 'components';

import { PAGES } from 'common/const';
import { queryParamsToObject } from 'common/urlQueryUtils';

import { fetchSummary, confirmSummary, closeMessage, reload } from './actions';
import LangSummaryTable from './LangSummaryTable';
import PaymentSummary from './PaymentSummary';
import ErrorHandler from './ErrorHandler';
import SuccessHandler from './SuccessHandler';

import './SummaryReportPage-style.css';

class SummaryReportPage extends React.PureComponent {
    static defaultProps = {
        summary: {}
    };

    state = {
        supplierComments: ''
    };

    isInputDone = () => {
        const { supplierComments } = this.state;

        return !!supplierComments;
    };

    componentDidMount() {
        const { loading, fetchSummary, location } = this.props;
        const params = queryParamsToObject(location.search);

        if (!loading && params.iid) {
            fetchSummary(params.iid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, fetchSummary, actionState, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const prevQueryParams = queryParamsToObject(prevProps.location.search);

        if (
            !loading &&
            ((currentQueryParams.iid &&
                currentQueryParams.iid !== prevQueryParams.iid) ||
                (prevProps.actionState !== actionState && actionState !== null))
        ) {
            fetchSummary(currentQueryParams.iid);
        }
    }

    handleSupplierCommentsChange = value => {
        this.setState({ supplierComments: value });
    };

    onAcceptClick = () => {
        const { loading, confirmSummary, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const { supplierComments } = this.state;

        if (!loading && currentQueryParams.iid && supplierComments) {
            confirmSummary({
                tok: currentQueryParams.iid,
                state: 'accept',
                comments: supplierComments
            });
        }
    };

    onRejectClick = () => {
        const { loading, confirmSummary, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const { supplierComments } = this.state;

        if (!loading && currentQueryParams.iid && supplierComments) {
            confirmSummary({
                tok: currentQueryParams.iid,
                state: 'reject',
                comments: supplierComments
            });
        }
    };

    onCommentsInput = comments => {
        this.setState({
            supplierComments: comments
        });
    };

    render() {
        const {
            loading,
            actionState,
            error,
            summary,
            closeMessage,
            reload
        } = this.props;

        const errorAlert = (
            <ErrorHandler error={error} onClose={closeMessage} />
        );

        const successAlert = (
            <SuccessHandler
                state={actionState === 'accept' ? 'accepted' : 'rejected'}
                summary={summary}
                onClose={reload}
            />
        );

        /*
        if (actionState === 'accept' || actionState === 'reject') {
            return (
                <ConfirmationResult
                    state={actionState === 'accept' ? 'accepted' : 'rejected'}
                    summary={summary}
                />
            );
        }
        */

        const { supplierComments } = this.state;

        return (
            <div className="page-content summary-report-page">
                {loading && <Spinner />}
                {error && errorAlert}
                {actionState && successAlert}
                <div className="summary-main">
                    <section className="step-wrapper">
                        <div className="header">
                            <a href={PAGES.LANDING} className="navbar-brand">
                                <img
                                    className="logo"
                                    title="Home"
                                    alt="logo"
                                    src="/img/logo_ld_large.png"
                                />
                            </a>
                        </div>
                        <div className="step-page checkout-and-pay container">
                            <div className="page-title">Work summary</div>
                            <div className="summary-container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="summary-info">
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Your name
                                                </div>
                                                <div className="col-6 value">
                                                    {get(
                                                        summary,
                                                        'translatorName',
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Your email
                                                </div>
                                                <div className="col-6 value">
                                                    {get(
                                                        summary,
                                                        'translatorEmail',
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Period start
                                                </div>
                                                <div className="col-6 value">
                                                    {get(
                                                        summary,
                                                        'periodDateFrom',
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Period end
                                                </div>
                                                <div className="col-6 value">
                                                    {get(
                                                        summary,
                                                        'periodDateTo',
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Job batches
                                                </div>
                                                <div className="col-6 value">
                                                    {summary.rowData
                                                        ? summary.rowData.length
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Total sum to invoice
                                                </div>
                                                <div className="col-6 value bold">
                                                    {`${summary.totalAmount ||
                                                        ''} ${summary.paymentCurrency ||
                                                        ''}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="summary-info-col col-lg-6">
                                        {summary.state !== 'ACCEPTED' &&
                                            summary.state !== 'REJECTED' && (
                                                <div className="summary payment-info">
                                                    <div className="title">
                                                        Your comments{' '}
                                                        <span className="required-text">
                                                            {' '}
                                                            (Required)
                                                        </span>
                                                    </div>
                                                    <div className="content-container">
                                                        <div className="user-profile">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="value">
                                                                        <TextInput
                                                                            placeholder='Please type a confirmation text (such as "OK" or "Approved") if you wish to accept the summary, or tell us details of the corrections needed if you wish to reject the total amount.'
                                                                            rows={
                                                                                8
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .onCommentsInput
                                                                            }
                                                                            value={
                                                                                supplierComments
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {summary.rowData && (
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <LangSummaryTable
                                                rows={summary.rowData || []}
                                            />
                                            <PaymentSummary summary={summary} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="btn-container">
                                <div className="container-full pr-5">
                                    {summary.paymentState === 'SENT' && (
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-reject"
                                                onClick={this.onRejectClick}
                                                disabled={!this.isInputDone()}
                                            >
                                                Reject
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-accept"
                                                onClick={this.onAcceptClick}
                                                disabled={!this.isInputDone()}
                                            >
                                                Accept
                                            </button>
                                        </div>
                                    )}

                                    {(summary.paymentState === 'ACCEPTED' ||
                                        summary.paymentState ===
                                            'INVOICED') && (
                                        <div className="text-success">
                                            <i className="fas fa-check" />{' '}
                                            Accepted
                                        </div>
                                    )}
                                    {summary.paymentState === 'PAID' && (
                                        <div className="text-success">
                                            <i className="fas fa-check" /> Paid
                                        </div>
                                    )}
                                    {summary.paymentState === 'REJECTED' && (
                                        <div className="text-fail">
                                            <i className="fas fa-ban" />{' '}
                                            Rejected
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="footer container">
                            <div className="contact-info">
                                If you have any questions, please contact:{' '}
                                <a href="mailto:pm@localizedirect.com">
                                    pm@localizedirect.com
                                </a>
                            </div>
                            <div>
                                Localize Direct AB, Henckels Torg 4, SE-252 36,
                                Helsingborg, Sweden
                            </div>
                            <div>+46 42 181962 | VAT No: SE556783457601</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['tracker', 'loading']),
        error: state.getIn(['tracker', 'error']),
        actionState: state.getIn(['tracker', 'actionState']),
        summaryId: state.getIn(['tracker', 'summaryId']),
        summary: state.getIn(['tracker', 'summary'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchSummary,
            confirmSummary,
            closeMessage,
            reload
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(SummaryReportPage));
