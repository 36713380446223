import React from 'react';
import { withFormik } from 'formik';
import { TextFieldInput } from 'components';

import './OrdersFilterForm-style.css';

const formikEnhancer = withFormik({
    mapPropsToValues: ({ values }) => {
        return {
            orderId: values.orderId,
            companyName: values.companyName,
            projectName: values.projectName,
            status: values.status,
            pm: values.pm
        };
    },
    handleSubmit: (payload, { props }) => {
        props.onEnter(payload);
    },
    displayName: 'OrdersFilterForm'
});

const ChecklistsFilterForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        onCancel
    } = props;

    return (
        <div className="orders-filter-form">
            <div className="title">Filter</div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <TextFieldInput
                    id="orderId"
                    type="text"
                    label="Order ID"
                    placeholder="Input keyword"
                    error={touched.orderId && errors.orderId}
                    value={values.orderId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className="submit-btns">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-apply">
                        Apply
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(ChecklistsFilterForm);

export default EnhancedForm;
