export const ON_LOG_IN_SUCCESS = 'ON_LOG_IN_SUCCESS';
export const ON_SIGN_UP_SUCCESS = 'ON_SIGN_UP_SUCCESS';
export const ON_EDIT_PROFILE_SUCCESS = 'ON_EDIT_PROFILE_SUCCESS';
export const ON_RESET_PASSWORD_SUCCESS = 'ON_RESET_PASSWORD_SUCCESS';
export const ON_PASSWORD_VERIFY_SUCCESS = 'ON_PASSWORD_VERIFY_SUCCESS';
export const ON_PASSWORD_VERIFY_FAILED = 'ON_PASSWORD_VERIFY_FAILED';
export const ON_PASSWORD_CHANGED_SUCCESS = 'ON_PASSWORD_CHANGED_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const AUTH_SHOW_MODAL = 'AUTH_SHOW_MODAL';
export const AUTH_CLOSE_MODAL = 'AUTH_CLOSE_MODAL';
export const ON_USER_COMPANY_INFO_FETCHED = 'ON_USER_COMPANY_INFO_FETCHED';
