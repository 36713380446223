import React from 'react';
import Select from 'react-select';
import { array, func, object } from 'prop-types';

import { MenuDropdown } from 'components';
import './GroupSelect-style.css';

class GroupSelect extends React.Component {
    static propTypes = {
        selectedOption: object,
        options: array,
        onChange: func
    };
    state = {
        selectedOption: this.props.selectedOption
    };

    handleChange = selectedOption => {
        const { onChange } = this.props;

        this.setState({ selectedOption });

        if (onChange) {
            onChange(selectedOption);
        }
    };

    renderOption = data => {
        return false;
    };

    renderGroup = data => (
        <MenuDropdown
            label={data.label}
            items={data.options}
            onChange={this.handleChange}
        />
    );

    render() {
        const { options } = this.props;
        const { selectedOption } = this.state;

        return (
            <Select
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
                formatOptionLabel={this.renderOption}
                formatGroupLabel={this.renderGroup}
                closeMenuOnSelect
            />
        );
    }
}

export default GroupSelect;
