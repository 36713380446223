import React from 'react';
import { Column, Cell, Table as FixedDataTable } from 'fixed-data-table-2';
import { array, string, number, func, oneOfType } from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { CollapseCell, IndexCell } from './cellRederer';

import 'fixed-data-table-2/dist/fixed-data-table.css'; // only needs to be imported once
import './Table-style.css';

const DEFAULT_COLUMN_WIDTH = 200;
const DEFAULT_ROW_HEIGHT = 40;
const DEFAULT_SUB_ROW_HEIGHT = 240;
const DEFAULT_BUFFER_COUNT = 50;

const SortTypes = {
    ASC: 'asc',
    DESC: 'desc'
};

class Table extends React.PureComponent {
    static propTypes = {
        className: string,
        data: array.isRequired,
        columns: array,
        width: number,
        height: number,
        rowHeight: oneOfType([number, func]),
        sortKey: string,
        sortType: string,
        rowExpandedRender: func,
        onBufferDataShouldFetch: func
    };

    static defaultProps = {
        showScrollbarY: false
    };

    constructor(props) {
        super(props);

        const columns = props.columns;
        const columnWidths = {};

        columns.forEach(column => {
            columnWidths[column.columnKey] =
                column.width || DEFAULT_COLUMN_WIDTH;
        });

        this.state = {
            collapsedRows: new Set(),
            scrollToRow: null,
            ids: props.data.map(item => (item ? item.id : null)),
            columnWidths
        };
    }

    onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth
            }
        }));
    };

    handleCollapseClick = rowIndex => {
        const { collapsedRows } = this.state;
        const shallowCopyOfCollapsedRows = new Set([...collapsedRows]);
        let scrollToRow = rowIndex;

        if (shallowCopyOfCollapsedRows.has(rowIndex)) {
            shallowCopyOfCollapsedRows.delete(rowIndex);
            scrollToRow = null;
        } else {
            shallowCopyOfCollapsedRows.add(rowIndex);
        }

        this.setState({
            scrollToRow: scrollToRow,
            collapsedRows: shallowCopyOfCollapsedRows
        });
    };

    subRowHeightGetter = index => {
        const { collapsedRows } = this.state;

        return collapsedRows.has(index) ? DEFAULT_SUB_ROW_HEIGHT : 0;
    };

    rowExpandedGetter = ({ rowIndex, width, height }) => {
        const { collapsedRows } = this.state;
        const { data, rowExpandedRender } = this.props;

        if (!collapsedRows.has(rowIndex)) {
            return null;
        }

        const style = {
            height: height,
            width: width - 2
        };
        return (
            <div style={style} className="expanded-content">
                {rowExpandedRender({ rowId: data[rowIndex].id })}
            </div>
        );
    };

    reverseSortDirection = sortDir =>
        sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;

    headerRenderer = (column, props) => {
        const { sortKey, sortType, onSortChange } = this.props;
        const { columnKey, label, headerRenderer, sortable } = column;
        let componentClass = '';
        let onClick = null;
        let content = label;

        if (sortable !== false) {
            const newSortType =
                sortKey === columnKey
                    ? this.reverseSortDirection(sortType)
                    : SortTypes.ASC;
            onClick = e => {
                e.preventDefault();
                onSortChange && onSortChange(columnKey, newSortType);
            };

            componentClass = 'sortable clickable';
        }

        if (headerRenderer) {
            content = headerRenderer({
                label: label,
                ...props
            });
        }

        return (
            <div className={componentClass} onClick={onClick}>
                {content} {sortable !== false && <i className="fas fa-sort" />}
            </div>
        );
    };

    cellRenderer = ({ rowData, columnKey, ...props }) => {
        return <span title={rowData[columnKey]}>{rowData[columnKey]}</span>;
    };

    getDefaultTableWidth = () => {
        const { columns } = this.props;

        return columns.reduce((totalWidth, column) => {
            return totalWidth + (column.width || DEFAULT_COLUMN_WIDTH);
        }, 0);
    };

    getDefaultTableHeight = () => {
        const { data, rowHeight } = this.props;

        return data.length * (rowHeight || DEFAULT_ROW_HEIGHT) + 40;
    };

    buildColumns = columns => {
        const elements = [];
        const { data } = this.props;
        const { columnWidths, collapsedRows } = this.state;

        columns.forEach(
            (
                {
                    label,
                    columnKey,
                    flexGrow,
                    sortable,
                    headerRenderer,
                    cellRenderer,
                    className,
                    ...column
                },
                index
            ) => {
                const colClass = classNames('cell', className);

                elements.push(
                    <Column
                        key={index + columnKey}
                        isResizable={true}
                        columnKey={columnKey}
                        flexGrow={flexGrow !== undefined ? flexGrow : 1}
                        {...column}
                        width={columnWidths[columnKey] || DEFAULT_COLUMN_WIDTH}
                        header={props => (
                            <Cell className={colClass}>
                                {this.headerRenderer(
                                    {
                                        columnKey,
                                        label,
                                        headerRenderer,
                                        sortable
                                    },
                                    props
                                )}
                            </Cell>
                        )}
                        cell={({ rowIndex, ...props }) => {
                            const rowData = data[rowIndex];

                            let cell = null;

                            switch (column.type) {
                                case 'collapse':
                                    cell = (
                                        <CollapseCell
                                            rowIndex={rowIndex}
                                            rowData={rowData}
                                            {...props}
                                            onClick={this.handleCollapseClick}
                                            collapsedRows={collapsedRows}
                                        />
                                    );
                                    break;
                                case 'index':
                                    cell = (
                                        <IndexCell
                                            rowIndex={rowIndex}
                                            {...props}
                                        />
                                    );
                                    break;

                                default:
                                    cell = (
                                        <Cell className={colClass} {...props}>
                                            {cellRenderer
                                                ? cellRenderer({
                                                      rowData,
                                                      ...props
                                                  })
                                                : this.cellRenderer({
                                                      rowData,
                                                      ...props
                                                  })}
                                        </Cell>
                                    );
                            }

                            return cell;
                        }}
                    />
                );
            }
        );

        return elements;
    };

    onVerticalScroll = e => {
        const { data, rowHeight, onBufferDataShouldFetch } = this.props;
        const checkingIndex =
            ((e / (rowHeight || DEFAULT_ROW_HEIGHT) / DEFAULT_BUFFER_COUNT) |
                0) *
                DEFAULT_BUFFER_COUNT +
            DEFAULT_BUFFER_COUNT;

        if (data[checkingIndex] === 'empty' && onBufferDataShouldFetch) {
            onBufferDataShouldFetch(checkingIndex, DEFAULT_BUFFER_COUNT);
        }

        return true;
    };

    static getDerivedStateFromProps(props, state) {
        const newIds = props.data.map(item => (item.id ? item.id : item));
        const { ids } = state;

        if (!isEqual(newIds, ids)) {
            return {
                ids: newIds,
                collapsedRows: new Set(),
                scrollToRow: null
            };
        }

        return null;
    }

    render() {
        const {
            data,
            columns,
            width,
            height,
            rowHeight,
            headerHeight,
            showScrollbarY,
            className,
            ...rest
        } = this.props;
        const { scrollToRow } = this.state;
        const componentClass = classNames(className);

        return (
            <div className={componentClass}>
                <FixedDataTable
                    width={width || this.getDefaultTableWidth()}
                    showScrollbarY={showScrollbarY}
                    //showScrollbarX={false}
                    maxHeight={height || this.getDefaultTableHeight()}
                    className="Table"
                    headerHeight={headerHeight || 40}
                    headerClassName="header-cell"
                    rowHeight={rowHeight || DEFAULT_ROW_HEIGHT}
                    rowsCount={data.length}
                    rowGetter={({ index }) => data[index]}
                    scrollToRow={scrollToRow}
                    subRowHeightGetter={this.subRowHeightGetter}
                    rowExpanded={this.rowExpandedGetter}
                    onVerticalScroll={this.onVerticalScroll}
                    onColumnResizeEndCallback={this.onColumnResizeEndCallback}
                    isColumnResizing={false}
                    touchScrollEnabled={true}
                    {...rest}
                >
                    {this.buildColumns(columns)}
                </FixedDataTable>
            </div>
        );
    }
}

export default Table;
