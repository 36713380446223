import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';

import { FORM_TYPES } from './constants';

import {
    ON_LOG_IN_SUCCESS,
    ON_SIGN_UP_SUCCESS,
    LOG_OUT,
    AUTH_SHOW_MODAL,
    AUTH_CLOSE_MODAL,
    ON_USER_COMPANY_INFO_FETCHED,
    ON_EDIT_PROFILE_SUCCESS,
    ON_RESET_PASSWORD_SUCCESS,
    ON_PASSWORD_VERIFY_SUCCESS,
    ON_PASSWORD_VERIFY_FAILED,
    ON_PASSWORD_CHANGED_SUCCESS
} from './actionConstants';

const INITIAL_STATE = fromJS({
    user: null,
    modal: {
        open: false,
        type: null
    },
    company: null
});

const handlers = {
    [ON_LOG_IN_SUCCESS](state, action) {
        return state.withMutations(mutableState => {
            mutableState.set('user', fromJS(action.payload));
            mutableState.setIn(['modal', 'open'], false);
        });
    },
    [ON_SIGN_UP_SUCCESS](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'type'], FORM_TYPES.SIGN_IN);
        });
    },
    [LOG_OUT](state, action) {
        return INITIAL_STATE;
    },
    [AUTH_SHOW_MODAL](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'open'], true);
            mutableState.setIn(['modal', 'type'], action.payload);
        });
    },
    [AUTH_CLOSE_MODAL](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'open'], false);
            mutableState.setIn(['modal', 'type'], null);
        });
    },
    [ON_USER_COMPANY_INFO_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['company'], fromJS(data));
        });
    },
    [ON_EDIT_PROFILE_SUCCESS](state, { payload }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'open'], false);
            mutableState.setIn(['user'], fromJS(payload.user));
            mutableState.setIn(['company'], fromJS(payload.company));
        });
    },
    [ON_RESET_PASSWORD_SUCCESS](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'type'], FORM_TYPES.SIGN_IN);
        });
    },
    [ON_PASSWORD_VERIFY_SUCCESS](state, { payload }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'open'], true);
            mutableState.setIn(['modal', 'type'], FORM_TYPES.UPDATE_PASSWORD);
        });
    },
    [ON_PASSWORD_VERIFY_FAILED](state, { payload }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'open'], true);
            mutableState.setIn(['modal', 'type'], FORM_TYPES.SIGN_IN);
        });
    },
    [ON_PASSWORD_CHANGED_SUCCESS](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'type'], FORM_TYPES.SIGN_IN);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
