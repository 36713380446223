const INTERVAL = 1500;

export class JobTimer {
    constructor() {
        this.jobs = {};
    }

    addJob(jobName, onTimeCallback) {
        this.removeJob(jobName);

        this.jobs[jobName] = setTimeout(function() {
            onTimeCallback();
        }, INTERVAL);
    }

    removeJob(jobName) {
        if (this.jobs[jobName]) {
            clearTimeout(this.jobs[jobName]);
            delete this.jobs[jobName];
        }
    }
}
