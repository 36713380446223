import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, array, bool } from 'prop-types';
import isEqual from 'lodash/isEqual';

import { Loading, Drawer, toJS } from 'components';
import { AccessControl } from 'auth';
import { fetchChecklists } from './actions';
import ChecklistTable from './ChecklistTable';
import ChecklistsFilterForm from './ChecklistsFilterForm';

import {
    objectToQueryParams,
    queryParamsToObject,
    queryParamsToString
} from 'common/urlQueryUtils';

import { PAGES } from 'common/const';

import '../users/UserList-style.css';

const DEFAULT_COUNT_LIMIT = 50;

class Checklists extends React.PureComponent {
    static propTypes = {
        data: array,
        fetchData: func,
        loading: bool
    };

    state = {
        isOpenFilterTray: undefined,
        isKeepFilterInputtingValues: true
    };

    getCurrentUrlParamsObj = () => {
        const { location } = this.props;

        return queryParamsToObject(location.search);
    };

    getFilterParamsObj = () => {
        const result = {};
        const allParams = this.getCurrentUrlParamsObj();
        const notAllowed = ['sortType', 'sortKey'];
        const filterKeys = Object.keys(allParams).filter(
            key => !notAllowed.includes(key)
        );

        filterKeys.forEach(key => (result[key] = allParams[key]));

        return result;
    };

    getSortParamsObj = () => {
        const result = {};
        const allParams = this.getCurrentUrlParamsObj();
        const allowed = ['sortType', 'sortKey'];
        const filterKeys = Object.keys(allParams).filter(key =>
            allowed.includes(key)
        );

        filterKeys.forEach(key => (result[key] = allParams[key]));

        return result;
    };

    setUrlParams = params => {
        const { history } = this.props;
        const currentParams = this.getCurrentUrlParamsObj();

        history.push({
            pathname: PAGES.CHECKLIST,
            search: objectToQueryParams({ ...currentParams, ...params })
        });
    };

    onFilterBtnClick = () => {
        this.setState({
            isOpenFilterTray: true,
            isKeepFilterInputtingValues: true
        });
    };

    onFilterClose = () => {
        this.setState({
            isOpenFilterTray: false
        });
    };

    onFilterApply = params => {
        this.setUrlParams(params);
        this.onFilterClose();
    };

    onFilterCancel = () => {
        this.setState({
            isOpenFilterTray: false,
            isKeepFilterInputtingValues: false
        });
    };

    onFilterClearClick = e => {
        const { history } = this.props;
        e.stopPropagation();

        history.push({
            search: objectToQueryParams(this.getSortParamsObj())
        });

        this.onFilterCancel();
    };

    onSortChange = (columnKey, sortType) => {
        this.setUrlParams({
            sortKey: columnKey,
            sortType
        });
    };

    onBufferDataFetch = (startIndex, bufferCount) => {
        const { fetchData } = this.props;

        fetchData(
            Object.assign(
                { offset: startIndex, limit: bufferCount },
                this.getCurrentUrlParamsObj()
            )
        );
    };

    componentDidMount() {
        const { loading, fetchData } = this.props;

        if (!loading) {
            fetchData(
                Object.assign(
                    { offset: 0, limit: DEFAULT_COUNT_LIMIT },
                    this.getCurrentUrlParamsObj()
                )
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const currentQueryParams = this.getCurrentUrlParamsObj();
        const prevQueryParams = queryParamsToObject(prevProps.location.search);

        const { loading, fetchData } = this.props;

        if (!loading && !isEqual(currentQueryParams, prevQueryParams)) {
            fetchData(
                Object.assign(
                    { offset: 0, limit: DEFAULT_COUNT_LIMIT },
                    currentQueryParams
                )
            );
        }
    }

    render() {
        const { loading, data } = this.props;
        const { isOpenFilterTray, isKeepFilterInputtingValues } = this.state;
        const filterQueryObj = this.getFilterParamsObj();
        const filterString = queryParamsToString(
            objectToQueryParams(filterQueryObj)
        );
        const filterBtnContent = filterString ? (
            <span>
                {filterString}{' '}
                <i
                    className="fas fa-times icon-clear-filter"
                    onClick={this.onFilterClearClick}
                />
            </span>
        ) : (
            'Filter'
        );

        if (loading || !data) {
            return <Loading />;
        }

        return (
            <div className="user-list checklists h-100">
                <div className="header d-flex justify-content-between m-3">
                    <h2 className="page-title">Translation checklist</h2>
                    <div
                        className="filter-btn clickable"
                        onClick={this.onFilterBtnClick}
                    >
                        <i className="fas fa-filter" />
                        <span className="value"> {filterBtnContent}</span>
                    </div>
                    <Drawer
                        className="filter-tray"
                        open={isOpenFilterTray}
                        onClose={this.onFilterClose}
                    >
                        {isKeepFilterInputtingValues && (
                            <ChecklistsFilterForm
                                values={filterQueryObj}
                                onCancel={this.onFilterCancel}
                                onEnter={this.onFilterApply}
                            />
                        )}
                    </Drawer>
                </div>

                <AccessControl requiredRole={'admin'} can="action">
                    <ChecklistTable
                        data={data}
                        filterParams={filterQueryObj}
                        sortParams={this.getSortParamsObj()}
                        onSortChange={this.onSortChange}
                        fetchBufferData={this.onBufferDataFetch}
                    />
                </AccessControl>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['orders', 'checklists', 'loading']),
        data: state.getIn(['orders', 'checklists', 'data'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchChecklists
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(Checklists));
