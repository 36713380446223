import { request } from './request';

export function getUserList() {
    return request({
        path: `/secure/v1/accounts`,
        options: {
            method: 'GET'
        }
    });
}

export function createUser(payload) {
    return request({
        path: '/secure/v1/accounts',
        options: {
            method: 'POST',
            body: JSON.stringify(payload)
        }
    });
}

export function updateUser(payload) {
    return request({
        path: `/secure/v1/accounts/${payload.userUid}`,
        options: {
            method: 'PUT',
            body: JSON.stringify(payload)
        }
    });
}

export function deactivateUser(payload) {
    return request({
        path: `/secure/v1/accounts/${payload.userUid}/deactivate`,
        options: {
            method: 'PUT'
        }
    });
}

export function activateUser(payload) {
    return request({
        path: `/secure/v1/accounts/${payload.userUid}/activate`,
        options: {
            method: 'PUT'
        }
    });
}
