import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PAGES, THEMES } from 'common/const';
import {
    AuthenticationCheck,
    SignInPage,
    SignOutPage,
    clearUserSession,
    AuthPage
} from 'auth';
import { OrderList, OrderDetails, Checklists } from 'orders';
import { UserList } from 'users';
import Customer from './customers';
import { ErrorBoundary, AppHeader, AppContext, Page } from './components';
import AssignmentMessage from './messages/AssignmentMessage';
import './app.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <AuthenticationCheck
        onAuth={() => <Route {...rest} />}
        onUnAuth={() => {
            clearUserSession();

            return <Redirect to="/login" />;
        }}
    />
);

const Routes = ({ pages }) => {
    return (
        <Switch>
            {pages.map(({ path, component }) => (
                <PrivateRoute key={path} exact path={path}>
                    {data => (
                        <AppContext.Consumer>
                            {ctx => (
                                <Page
                                    component={component}
                                    data={data}
                                    ctx={ctx}
                                />
                            )}
                        </AppContext.Consumer>
                    )}
                </PrivateRoute>
            ))}
            <Route path="/login" component={SignInPage} />
            <Route path="/logout" component={SignOutPage} />
            <Route path={`/:token/reset-pass/:userUid/`} component={AuthPage} />
            <Route path="/message/assignment" component={AssignmentMessage} />
        </Switch>
    );
};

class App extends Component {
    constructor(props) {
        super(props);

        this.handleChangePage = page => {
            this.setState({ page });
        };

        this.handleChangeTheme = theme => {
            this.setState({ theme });
        };

        this.state = {
            page: PAGES.HOME,
            theme: THEMES.TEAL,
            changePage: this.handleChangePage,
            changeTheme: this.handleChangeTheme
        };
    }

    static defaultProps = {
        menus: [
            {
                title: 'ORDER LIST',
                path: PAGES.ORDERS,
                group: 'left'
            },
            {
                title: 'USER LIST',
                path: PAGES.USERS,
                group: 'left',
                requiredRole: 'admin'
            },
            {
                title: 'CUSTOMER LIST',
                path: PAGES.CUSTOMER,
                group: 'left',
                requiredRole: 'admin'
            },
            {
                title: 'TRANSLATION CHECKLIST',
                path: PAGES.CHECKLIST,
                group: 'left',
                requiredRole: 'admin'
            }
        ],
        routes: [
            {
                path: PAGES.HOME,
                component: OrderList
            },
            {
                path: PAGES.ORDERS,
                component: OrderList
            },
            {
                path: PAGES.ORDER,
                component: OrderDetails
            },
            {
                path: PAGES.USERS,
                component: UserList
            },
            {
                path: PAGES.CUSTOMER,
                component: Customer
            },
            {
                path: PAGES.CHECKLIST,
                component: Checklists
            }
        ]
    };
    render() {
        const { menus, routes, store } = this.props;
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppContext.Provider value={this.state}>
                        <AppHeader current={this.state.page} pages={menus} />
                        <ErrorBoundary>
                            <div className="page-container">
                                <Routes pages={routes} />
                            </div>
                        </ErrorBoundary>
                    </AppContext.Provider>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
