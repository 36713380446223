import React from 'react';
import { func, bool, string } from 'prop-types';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import Icon from 'components/icon';

class UploadBox extends React.PureComponent {
    static propTypes = {
        id: string.isRequired,
        name: string,
        textContent: string,
        disabled: bool.isRequired,
        onNewFiles: func.isRequired,
        connectDropTarget: func.isRequired,
        isOver: bool.isRequired
    };

    selectFileHandle = files => {
        const { onNewFiles, disabled } = this.props;
        !disabled && onNewFiles(files);
    };

    onFileInputChange = e => {
        this.selectFileHandle(Array.from(e.target.files));

        e.target.value = null;
    };

    render() {
        const {
            id,
            isOver,
            connectDropTarget,
            disabled,
            textContent
        } = this.props;
        const elmId = `input-upload-${id}`;

        return connectDropTarget(
            <div className="upload-box">
                <div className={`drop-box ${isOver ? 'dropable' : ''}`}>
                    <div className="select-file-box">
                        <label className="select-file-control" htmlFor={elmId}>
                            <Icon name="cloud-upload" size={56} />
                            <input
                                type="file"
                                id={elmId}
                                name={id}
                                multiple
                                style={{ display: 'none' }}
                                onChange={this.onFileInputChange}
                                disabled={disabled}
                            />
                            <h4>Drop or Click</h4>
                            <h6>
                                {textContent ||
                                    'to select a file on your computer'}
                            </h6>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

const collect = (connect, monitor) => {
    return {
        isOver: monitor.isOver(),
        connectDropTarget: connect.dropTarget()
    };
};
const dropFilesOnTarget = {
    drop(props, monitor) {
        if (props.onNewFiles && !props.disabled) {
            props.onNewFiles(monitor.getItem().files);
        }
    }
};
export default DropTarget(NativeTypes.FILE, dropFilesOnTarget, collect)(
    UploadBox
);
