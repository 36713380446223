import React from 'react';
import {
    object,
    objectOf,
    arrayOf,
    shape,
    string,
    number,
    bool
} from 'prop-types';
import debounce from 'lodash.debounce';
import {
    AutoSizer,
    InfiniteLoader,
    Table,
    Column,
    SortIndicator
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { findAll, loadMore, selectCustomer } from '../reducer';
import JoyQueryBox from 'joy-query-box';

const BATCH_SIZE = 3;
const DEFUALT_PAGE_SIZE = 50;

const Columns = ({ keys, fields }, headerRenderer, cellRenderers) => {
    return Array.isArray(keys) && fields
        ? keys.map(keyField => {
              const field = fields[keyField];
              const cellRenderer = cellRenderers
                  ? cellRenderers[keyField]
                  : undefined;
              return field ? (
                  <Column
                      key={keyField}
                      dataKey={keyField}
                      disableSort={!field.sortable}
                      label={field.label}
                      width={field.width}
                      headerRenderer={headerRenderer}
                      cellRenderer={cellRenderer}
                  />
              ) : null;
          })
        : null;
};

class ActionTable extends React.Component {
    static propTypes = {
        meta: shape({
            size: number.isRequired,
            entry: string.isRequired,
            keys: arrayOf(string).isRequired,
            fields: objectOf(
                shape({
                    width: number.isRequired,
                    label: string.isRequired,
                    sortable: bool
                })
            )
        }),
        setting: shape({
            title: string.isRequired,
            height: number.isRequired,
            headerHeight: number.isRequired,
            rowHeight: number.isRequired,
            disableHeader: bool.isRequired,
            overscanRowCount: number.isRequired
        }),
        cellRenderers: object,
        customers: object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.__pageSize = this.props.meta
            ? this.props.meta.size || DEFUALT_PAGE_SIZE
            : DEFUALT_PAGE_SIZE;
        this.__stack = [];
        this.__batch = [];
        this.__fetching = [];
        this.executeRequest = debounce(this.executeRequest, 200);
    }

    static getDerivedStateFromProps(props, state) {
        const { customers } = props;
        if (state.customers !== customers) {
            return { customers };
        }
        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.customers !== nextState.customers;
    }

    isRowLoaded = ({ index }) => {
        if (
            this.__stack.some(([start, stop]) => index >= start && index < stop)
        )
            return true;
        if (
            this.__batch.some(([start, stop]) => index >= start && index < stop)
        )
            return true;
        if (
            this.__fetching.some(
                ([start, stop]) => index >= start && index < stop
            )
        )
            return true;
        return !!this.state.customers.list[index];
    };

    loadMoreRows = ({ startIndex }) => {
        this.__stack.push([startIndex, startIndex + this.__pageSize]);
        this.executeRequest();
    };

    executeRequest = () => {
        this.__batch = this.__stack;
        this.__stack = [];
        if (this.__batch.length) {
            this.__fetching =
                this.length > BATCH_SIZE
                    ? this.__batch.slice(1).slice(-BATCH_SIZE)
                    : this.__batch;
            this.__batch = [];
            Promise.all(
                this.__fetching
                    .reverse()
                    .map(([begin, _]) =>
                        this.props.loadMore(begin, this.__pageSize)
                    )
            ).then(() => (this.__fetching = []));
        }
    };

    render() {
        const {
            meta,
            setting: {
                title,
                headerHeight,
                rowHeight,
                disableHeader,
                overscanRowCount
            },
            cellRenderers
        } = this.props;
        const { customers } = this.state;
        const scrollToIndex = customers.highlightIx;
        const queryText = customers.queryText;
        const total = customers.total;

        const words = meta.keys.reduce((wordsRes, key) => {
            const field = meta.fields[key];
            if (field && field.keyFilter) {
                wordsRes.push({
                    word: field.keyFilter,
                    desc: `Column: ${field.label}`
                });
            }
            return wordsRes;
        }, []);

        return (
            <div className="card h-100">
                <div className="card-header">
                    <div className="row">
                        <div className="col-lg-2">
                            <h4 className="card-title text-md-left">{title}</h4>
                        </div>
                        <div className="col-lg-6">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        Filter
                                    </div>
                                </div>
                                <JoyQueryBox
                                    className="form-control py-2"
                                    words={words}
                                    autoFocus
                                    disabled
                                    label="Filter"
                                    placeholder="Type condition here"
                                    onSearch={this.handleSearch}
                                    queryText={queryText}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <button
                                type="button"
                                className="btn btn-outline-dark float-right"
                                onClick={this.addNewItem}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Invite rest of in-active customer"
                                disabled
                            >
                                <i className="fas fa-paper-plane" /> Invite all
                                in-active customers
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <AutoSizer>
                        {({ width, height }) => (
                            <InfiniteLoader
                                isRowLoaded={this.isRowLoaded}
                                loadMoreRows={this.loadMoreRows}
                                rowCount={total}
                            >
                                {({ onRowsRendered, registerChild }) => (
                                    <Table
                                        ref={registerChild}
                                        className="data-table "
                                        disableHeader={disableHeader}
                                        headerClassName="headerColumn"
                                        headerHeight={headerHeight}
                                        height={height}
                                        noRowsRenderer={this.noRowsRenderer}
                                        overscanRowCount={overscanRowCount}
                                        rowClassName={this.rowClassName}
                                        rowHeight={rowHeight}
                                        rowGetter={this.rowGetter}
                                        rowCount={total}
                                        scrollToIndex={scrollToIndex}
                                        width={width}
                                        onRowClick={this.selecteItem}
                                        onRowsRendered={onRowsRendered}
                                    >
                                        {Columns(
                                            meta,
                                            this.headerRenderer,
                                            cellRenderers
                                        )}
                                    </Table>
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                </div>
                <div className="card-footer text-muted">
                    <div className="font-weight-light text-dark">
                        Found {total} items
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { findAll, meta } = this.props;
        const list = this.state.customers.list;
        if (!list.size) {
            findAll(meta.entry, { offset: 0, limit: meta.size });
        }
    }

    viewItemDetails = ({ index, rowData }) => {
        this.props.viewCustomerDetails(rowData);
    };

    selecteItem = ({ index, rowData }) => {
        this.props.selectCustomer(index, rowData);
    };

    rowGetter = ({ index }) => this.state.customers.list[index] || {};

    headerRenderer = ({ dataKey, sortBy, sortDirection, label }) => (
        <div title={dataKey}>
            {label}
            {sortBy === dataKey && (
                <SortIndicator sortDirection={sortDirection} />
            )}
        </div>
    );

    noRowsRenderer = () => <div className="noRows"> No rows </div>;

    rowClassName = ({ index }) => {
        if (index < 0) {
            return 'headerRow shadow-sm';
        } else {
            const { customers } = this.state;
            const classNames = [index % 2 === 0 ? 'evenRow' : 'oddRow'];
            const rowData = customers.list[index];
            if (rowData) {
                rowData.userUid === customers.highlightId &&
                    classNames.push('highlight');
                rowData.email === customers.working &&
                    classNames.push('working');
            }

            return classNames.join(' ');
        }
    };

    handleSearch = (syntaxErr, conditions, queryText) => {
        if (!syntaxErr) {
            this.setState({ conditions, syntaxErr });
            const { findAll, meta } = this.props;
            const { limit, offset } = meta.paging;

            findAll(meta.entry, { ...conditions, limit, offset }, queryText);
        } else {
            this.setState({ syntaxErr });
        }
    };
}

const mapStateToProps = state => {
    const customers = state.get('customers').current.toJS();
    return { customers };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ findAll, loadMore, selectCustomer }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionTable);
