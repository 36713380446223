export function requestData(type, data) {
    return {
        data,
        type
    };
}

export function receiveData(type, data) {
    return {
        data,
        type
    };
}
