import React from 'react';
import { withFormik } from 'formik';
import { TextFieldInput, Select } from 'components';

import { UserFormSelection } from 'users';

import './OrdersFilterForm-style.css';

const formikEnhancer = withFormik({
    mapPropsToValues: ({ values }) => {
        return {
            orderId: values.orderId,
            companyName: values.companyName,
            projectName: values.projectName,
            status: values.status,
            pm: values.pm
        };
    },
    handleSubmit: (payload, { props }) => {
        props.onEnter(payload);
    },
    displayName: 'OrdersFilterForm'
});

const OrdersFilterForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        onCancel
    } = props;

    const STATUS_OPTIONS = [
        { value: '', label: 'All' },
        { value: 'not-assign', label: 'Not assigned' },
        { value: 'translating', label: 'Translating' },
        { value: 'finished', label: 'Finished translation' },
        { value: 'issue', label: 'Issue' },
        { value: 'delivered', label: 'Delivered' },
        { value: 'rejected', label: 'Cancelled' }
    ];

    return (
        <div className="orders-filter-form">
            <div className="title">Filter</div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <TextFieldInput
                    id="orderId"
                    type="text"
                    label="Order ID"
                    placeholder="Input keyword"
                    error={touched.orderId && errors.orderId}
                    value={values.orderId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextFieldInput
                    id="companyName"
                    type="text"
                    label="Company name"
                    placeholder="Input keyword"
                    error={touched.companyName && errors.companyName}
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextFieldInput
                    id="projectName"
                    type="text"
                    label="Game name"
                    placeholder="Input keyword"
                    error={touched.projectName && errors.projectName}
                    value={values.projectName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Select
                    id="status"
                    label="Status"
                    options={STATUS_OPTIONS}
                    value={values.status}
                    placeholder="Input keyword"
                    error={touched.status && errors.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                <UserFormSelection
                    id="pm"
                    label="PM"
                    userRole="pm"
                    value={values.pm}
                    placeholder="Input keyword"
                    error={touched.pm && errors.pm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                <div className="submit-btns">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-apply">
                        Apply
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(OrdersFilterForm);

export default EnhancedForm;
