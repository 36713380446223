import React from 'react';
import { connect } from 'react-redux';
import { Select, toJS } from 'components';

const UserFormSelection = ({ userList, userRole, ...props }) => {
    const options = [{ value: '', label: 'All' }];

    userList &&
        userList.forEach(user => {
            if (
                user.status === true &&
                user.roleName.toLowerCase() === userRole.toLowerCase()
            ) {
                options.push({
                    value: user.userUid,
                    label: user.firstName
                });
            }
        });

    return <Select options={options} {...props} />;
};

const mapStateToProps = (state, ownProps) => {
    return {
        userList: state.getIn(['users', 'data'])
    };
};

export default connect(
    mapStateToProps,
    null
)(toJS(UserFormSelection));
