import React from 'react';
import classNames from 'classnames';

const ButtonItem = ({ value, label, selected, className, onSelect }) => {
    const itemClass = classNames('button-select-item clickable', className, {
        selected: selected
    });
    const onItemClick = () => {
        onSelect({ value, label });
    };

    return (
        <div className={itemClass} onClick={onItemClick}>
            {!!label && label}
        </div>
    );
};

export default ButtonItem;
