import { fromJS, List } from 'immutable';
import { createReducer } from 'store/utils';
import * as api from './servcie';

const CUSTOMER_FIND = 'CUSTOMER_FIND';
const CUSTOMER_FIND_COMPLETED = 'CUSTOMER_FIND_COMPLETED';
export const findAll = (entry, queries, queryText) => {
    return {
        type: CUSTOMER_FIND,
        meta: {
            entry,
            queries,
            queryText,
            isLockUI: true
        },
        payload: api.findAll(entry, queries)
    };
};

const CUSTOMER_LOAD_MORE = 'CUSTOMER_LOAD_MORE';
const CUSTOMER_LOAD_MORE_COMPLETED = 'CUSTOMER_LOAD_MORE_COMPLETED';
export const loadMore = (offset, limit) => (dispatch, getState) => {
    const currentEntity = getState().get('customers').current;
    const entry = currentEntity.get('entry');
    const queries = currentEntity.get('queries');
    return dispatch({
        type: CUSTOMER_LOAD_MORE,
        meta: {
            entry,
            queries: { ...queries, offset, limit }
        },
        payload: api.findAll(entry, { ...queries, offset, limit })
    });
};

const CUSTOMER_SELECT = 'CUSTOMER_SELECT';
export const selectCustomer = (ix, customer) => {
    return {
        type: CUSTOMER_SELECT,
        ix,
        customer
    };
};

const CUSTOMER_INVITE = 'CUSTOMER_INVITE';
const CUSTOMER_INVITE_BEGIN = 'CUSTOMER_INVITE_BEGIN';
const CUSTOMER_INVITE_COMMIT = 'CUSTOMER_INVITE_COMMIT';
export const inviteCustomer = (entry, email) => (dispatch, getState) => {
    return dispatch({
        type: CUSTOMER_INVITE,
        meta: {
            isOptimistic: true,
            entry,
            email
        },
        payload: api.inviteCustomer(entry, { email })
    });
};

const INITIAL_STATE = fromJS({
    entry: '',
    queryText: '',
    queries: {},
    sortBy: {
        field: null,
        direction: null
    },
    list: List([]),
    origin: List([]),
    total: 0,
    highlightId: null,
    highlightIx: 0,
    selected: null,
    error: null,
    working: null
});

const handlers = {
    [CUSTOMER_FIND_COMPLETED](state, action) {
        const {
            payload: { customers, total },
            meta: { queries, entry, queryText }
        } = action;
        return state.withMutations(mst => {
            mst.set('entry', entry);
            mst.set('queries', queries);
            mst.set('list', List(customers));
            mst.set('origin', List(customers));
            mst.set('total', total);
            mst.set('offset', queries.offset);
            mst.set('limit', queries.limit);
            if (queryText) mst.set('queryText', queryText);
        });
    },

    [CUSTOMER_LOAD_MORE_COMPLETED](state, action) {
        const {
            payload: { customers },
            meta: { queries }
        } = action;
        return state.withMutations(mst => {
            mst.updateIn(['list'], ls => ls.concat(customers));
            mst.updateIn(['origin'], ls => ls.concat(customers));
            mst.set('offset', queries.offset);
            mst.set('limit', queries.limit);
        });
    },
    [CUSTOMER_SELECT](state, { ix, customer }) {
        return state.withMutations(mst => {
            mst.set('highlightId', customer.userUid);
            mst.set('highlightIx', ix);
            mst.set('selected', customer);
        });
    },
    [CUSTOMER_INVITE_BEGIN](state, { meta }) {
        console.log('CUSTOMER_INVITE_BEGIN', meta);
        return state.withMutations(mst => {
            mst.set('working', meta.email);
        });
    },
    [CUSTOMER_INVITE_COMMIT](state, action) {
        const { payload } = action;
        return state.withMutations(mst => {
            mst.updateIn(['list'], l =>
                l.reduce(
                    (stack, item) =>
                        item.userId !== payload.userId
                            ? stack.push(item)
                            : stack.push({
                                  ...item,
                                  status: payload.status,
                                  invited: payload.invited
                              }),
                    List([])
                )
            );
        });
    }
};
export default createReducer(INITIAL_STATE, handlers);
