export const LANGUAGE_OPTIONS = [
    { value: 'afZA', label: 'afZA  Afrikaans - South Africa' },
    { value: 'arAE', label: 'arAE  arAE  Arabic - United Arab Emirates' },
    { value: 'arBH', label: 'arBH  Arabic - Bahrain' },
    { value: 'arDZ', label: 'arDZ  Arabic - Algeria' },
    { value: 'arEG', label: 'arEG  Arabic - Egypt' },
    { value: 'arIQ', label: 'arIQ  Arabic - Iraq' },
    { value: 'arJO', label: 'arJO  Arabic - Jordan' },
    { value: 'arKW', label: 'arKW  Arabic - Kuwait' },
    { value: 'arLB', label: 'arLB  Arabic - Lebanon' },
    { value: 'arLY', label: 'arLY  Arabic - Libya' },
    { value: 'arMA', label: 'arMA  Arabic - Morocco' },
    { value: 'arOM', label: 'arOM  Arabic - Oman' },
    { value: 'arQA', label: 'arQA  Arabic - Qatar' },
    { value: 'arSA', label: 'arSA  Arabic - Saudi Arabia' },
    { value: 'arSY', label: 'arSY  Arabic - Syria' },
    { value: 'arTN', label: 'arTN  Arabic - Tunisia' },
    { value: 'arYE', label: 'arYE  Arabic - Yemen' },
    { value: 'azAZ', label: 'azAZ  Azeri - Azerbaijan' },
    { value: 'beBY', label: 'beBY  Belarusian - Belarus' },
    { value: 'bgBG', label: 'bgBG  Bulgarian - Bulgaria' },
    { value: 'bnBD', label: 'bnBD  Bengali - Bangladesh' },
    { value: 'bsBA', label: 'bsBA  Bosnian - Bosnia and Herzegovina' },
    { value: 'caES', label: 'caES  Catalan - Spain' },
    { value: 'csCZ', label: 'csCZ  Czech - Czech Republic' },
    { value: 'cyGB', label: 'cyGB  Welsh - United Kingdom' },
    { value: 'daDK', label: 'daDK  Danish - Denmark' },
    { value: 'deAT', label: 'deAT  German - Austria' },
    { value: 'deCH', label: 'deCH  German - Switzerland' },
    { value: 'deDE', label: 'deDE  German - Germany' },
    { value: 'deLI', label: 'deLI  German - Liechtenstein' },
    { value: 'deLU', label: 'deLU  German - Luxembourg' },
    { value: 'dvMV', label: 'dvMV  Divehi - Maldives' },
    { value: 'elGR', label: 'elGR  Greek - Greece' },
    { value: 'enAU', label: 'enAU  English - Australia' },
    { value: 'enBZ', label: 'enBZ  English - Belize' },
    { value: 'enCA', label: 'enCA  English - Canada' },
    { value: 'enCB', label: 'enCB  English - Caribbean' },
    { value: 'enGB', label: 'enGB  English - United Kingdom' },
    { value: 'enIE', label: 'enIE  English - Ireland' },
    { value: 'enJM', label: 'enJM  English - Jamaica' },
    { value: 'enNZ', label: 'enNZ  English - New Zealand' },
    { value: 'enPH', label: 'enPH  English - Republic of the Philippines' },
    { value: 'enTT', label: 'enTT  English - Trinidad and Tobago' },
    { value: 'enUS', label: 'enUS  English - United States' },
    { value: 'enZA', label: 'enZA  English - South Africa' },
    { value: 'enZW', label: 'enZW  English - Zimbabwe' },
    { value: 'esAR', label: 'esAR  Spanish - Argentina' },
    { value: 'esBO', label: 'esBO  Spanish - Bolivia' },
    { value: 'esCL', label: 'esCL  Spanish - Chile' },
    { value: 'esCO', label: 'esCO  Spanish - Colombia' },
    { value: 'esCR', label: 'esCR  Spanish - Costa Rica' },
    { value: 'esDO', label: 'esDO  Spanish - Dominican Republic' },
    { value: 'esEC', label: 'esEC  Spanish - Ecuador' },
    { value: 'esES', label: 'esES  Spanish (European) - Spain' },
    { value: 'esGT', label: 'esGT  Spanish - Guatemala' },
    { value: 'esHN', label: 'esHN  Spanish - Honduras' },
    { value: 'esMX', label: 'esMX  Spanish (Latin American) - Mexico' },
    { value: 'esNI', label: 'esNI  Spanish - Nicaragua' },
    { value: 'esPA', label: 'esPA  Spanish - Panama' },
    { value: 'esPE', label: 'esPE  Spanish - Peru' },
    { value: 'esPR', label: 'esPR  Spanish - Puerto Rico' },
    { value: 'esPY', label: 'esPY  Spanish - Paraguay' },
    { value: 'esSV', label: 'esSV  Spanish - El Salvador' },
    { value: 'esUY', label: 'esUY  Spanish - Uruguay' },
    { value: 'esVE', label: 'esVE  Spanish - Venezuela' },
    { value: 'etEE', label: 'etEE  Estonian - Estonia' },
    { value: 'euES', label: 'euES  Basque - Spain' },
    { value: 'faIR', label: 'faIR  Farsi - Iran' },
    { value: 'fiFI', label: 'fiFI  Finnish - Finland' },
    { value: 'foFO', label: 'foFO  Faroese - Faroe Islands' },
    { value: 'frBE', label: 'frBE  French - Belgium' },
    { value: 'frCA', label: 'frCA  French (Canada) - Canada' },
    { value: 'frCH', label: 'frCH  French - Switzerland' },
    { value: 'frFR', label: 'frFR  French (European) - France' },
    { value: 'frLU', label: 'frLU  French - Luxembourg' },
    { value: 'frMC', label: 'frMC  French - Principality of Monaco' },
    { value: 'glES', label: 'glES  Galician - Spain' },
    { value: 'guIN', label: 'guIN  Gujarati - India' },
    { value: 'heIL', label: 'heIL  Hebrew - Israel' },
    { value: 'hiIN', label: 'hiIN  Hindi - India' },
    { value: 'hrBA', label: 'hrBA  Croatian - Bosnia and Herzegovina' },
    { value: 'hrHR', label: 'hrHR  Croatian - Croatia' },
    { value: 'huHU', label: 'huHU  Hungarian - Hungary' },
    { value: 'hyAM', label: 'hyAM  Armenian - Armenia' },
    { value: 'idID', label: 'idID  Indonesian - Indonesia' },
    { value: 'isIS', label: 'isIS  Icelandic - Iceland' },
    { value: 'itCH', label: 'itCH  Italian - Switzerland' },
    { value: 'itIT', label: 'itIT  Italian - Italy' },
    { value: 'jaJP', label: 'jaJP  Japanese - Japan' },
    { value: 'jwID', label: 'jwID  Javanese - Indonesia' },
    { value: 'kaGE', label: 'kaGE  Georgian - Georgia' },
    { value: 'kkKZ', label: 'kkKZ  Kazakh - Kazakhstan' },
    { value: 'knIN', label: 'knIN  Kannada - India' },
    { value: 'koKR', label: 'koKR  Korean - Korea' },
    { value: 'kyKG', label: 'kyKG  Kyrgyz - Kyrgyzstan' },
    { value: 'kyKZ', label: 'kyKZ  Kyrgyz - Kazakhstan' },
    { value: 'ltLT', label: 'ltLT  Lithuanian - Lithuania' },
    { value: 'lvLV', label: 'lvLV  Latvian - Latvia' },
    { value: 'miNZ', label: 'miNZ  Maori - New Zealand' },
    {
        value: 'mkMK',
        label: 'mkMK  Macedonian - Former Yugoslav Republic of Macedonia'
    },
    { value: 'mnMN', label: 'mnMN  Mongolian - Mongolia' },
    { value: 'mrIN', label: 'mrIN  Marathi - India' },
    { value: 'msBN', label: 'msBN  Malay - Brunei Darussalam' },
    { value: 'msMY', label: 'msMY  Malay - Malaysia' },
    { value: 'mtMT', label: 'mtMT  Maltese - Malta' },
    { value: 'nlBE', label: 'nlBE  Dutch - Belgium' },
    { value: 'nlNL', label: 'nlNL  Dutch - Netherlands' },
    { value: 'noNO', label: 'Norwegian - Norway' },
    { value: 'nsZA', label: 'nsZA  Northern Sotho - South Africa' },
    { value: 'paIN', label: 'paIN  Punjabi - India' },
    { value: 'plPL', label: 'plPL  Polish - Poland' },
    { value: 'psAF', label: 'psAF  Pashto - Afghanistan' },
    { value: 'ptBR', label: 'ptBR  Portuguese (Brazil) - Brazil' },
    { value: 'ptPT', label: 'ptPT  Portuguese (European) - Portugal' },
    { value: 'quBO', label: 'quBO  Quechua - Bolivia' },
    { value: 'quEC', label: 'quEC  Quechua - Ecuador' },
    { value: 'quPE', label: 'quPE  Quechua - Peru' },
    { value: 'roRO', label: 'roRO  Romanian - Romania' },
    { value: 'ruRU', label: 'ruRU  Russian - Russia' },
    { value: 'saIN', label: 'saIN  Sanskrit - India' },
    { value: 'seFI', label: 'seFI  Sami - Finland' },
    { value: 'seNO', label: 'seNO  Sami - Norway' },
    { value: 'seSE', label: 'seSE  Sami - Sweden' },
    { value: 'skSK', label: 'skSK  Slovak - Slovakia' },
    { value: 'slSI', label: 'slSI  Slovenian - Slovenia' },
    { value: 'sqAL', label: 'sqAL  Albanian - Albania' },
    { value: 'srBA', label: 'srBA  Serbian - Bosnia and Herzegovina' },
    { value: 'srRS', label: 'srRS  Serbian - Serbia' },
    { value: 'svFI', label: 'svFI  Swedish - Finland' },
    { value: 'svSE', label: 'svSE  Swedish - Sweden' },
    { value: 'swKE', label: 'swKE  Swahili - Kenya' },
    { value: 'syrSY', label: 'syrSY  Syriac - Syria' },
    { value: 'taIN', label: 'taIN  Tamil - India' },
    { value: 'teIN', label: 'teIN  Telugu - India' },
    { value: 'thTH', label: 'thTH  Thai - Thailand' },
    { value: 'tlPH', label: 'tlPH  Tagalog - Republic of the Philippines' },
    { value: 'tnZA', label: 'tnZA  Tswana - South Africa' },
    { value: 'trTR', label: 'trTR  Turkish - Turkey' },
    { value: 'ttRU', label: 'ttRU  Tatar - Russia' },
    { value: 'ukUA', label: 'ukUA  Ukrainian - Ukraine' },
    { value: 'urIN', label: 'urIN  Urdu - India' },
    { value: 'urPK', label: 'urPK  Urdu - Islamic Republic of Pakistan' },
    { value: 'uzUZ', label: 'uzUZ  Uzbek - Uzbekistan' },
    { value: 'viVN', label: 'viVN  Vietnamese - Vietnam' },
    { value: 'xhZA', label: 'xhZA  Xhosa - South Africa' },
    { value: 'zhCHS', label: 'Chinese (Simplified)' },
    { value: 'zhCHT', label: 'Chinese (Traditional)' },
    { value: 'zhCN', label: 'zhCN  Chinese (Simplified) - China' },
    {
        value: 'zhHK',
        label: 'zhHK  Chinese (Traditional, HongKong) - Hong Kong'
    },
    { value: 'zhMO', label: 'zhMO  Chinese - Macau' },
    { value: 'zhSG', label: 'zhSG  Chinese - Singapore' },
    { value: 'zhTW', label: 'zhTW  Chinese (Traditional, Taiwan) - Taiwan' },
    { value: 'zuZA', label: 'zuZA  Zulu - South Africa' }
];

export function getLanguageDisplayName(value) {
    const found = LANGUAGE_OPTIONS.find(option => option.value === value);

    return found ? found.label : value;
}
