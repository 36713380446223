import Cookies from 'js-cookie';
import decode from 'jwt-decode';

const isLoggedIn = () => {
    const token = Cookies.get('token');
    return !!token && !isTokenExpired(token);
};

const isTokenExpired = token => {
    let expired = true;

    try {
        const decoded = decode(token);
        if (decoded.exp > Date.now() / 1000) {
            expired = false;
        }
    } catch (err) {}

    return expired;
};

const AuthenticationCheck = ({ onAuth, onUnAuth }) => {
    if (isLoggedIn()) {
        return onAuth();
    } else {
        return onUnAuth();
    }
};

export default AuthenticationCheck;
