import React from 'react';
import { COUNTRIES } from '../common/countryData';
import { Select } from './form/Select';

const CountrySelect = props => {
    const options = COUNTRIES.map(country => ({
        value: country.code,
        label: country.name
    }));

    return <Select {...props} options={options} className="country-select" />;
};

export default CountrySelect;
