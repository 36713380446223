const localizeData = {
    'msg-accepted': 'You have accepted the assignment, thanks very much!',
    'msg-accepted-already': 'You have already accepted the assignment.',
    'msg-declined': 'You have rejected the assignment, thanks very much!',
    'msg-declined-already': 'You have already declined the assignment.',
    'msg-accepted-cannot-decline':
        'This job was originally accepted, you cannot decline or suggest at this point. Please contact the PM team via email: <a href="mailto:pm@localizedirect.com">pm@localizedirect.com</a>',
    'msg-not-available':
        'This job is no longer available to accept. Please contact the PM team via email: <a href="mailto:pm@localizedirect.com">pm@localizedirect.com</a>',
    'msg-submit-job-extension-error': `Can't submit your suggestion. Please contact the PM team via email: <a href="mailto:pm@localizedirect.com">pm@localizedirect.com</a>`,
    'msg-job-suggestion-submitted': `Your suggestion has been sent.<br><br>
        PM team will consider and let you know the result via email. 
        For any concerns, please contact the PM team via email: <a href="mailto:pm@localizedirect.com">pm@localizedirect.com</a>`,
    FILE_PROGRESS_EMPTY:
        'Your translated file is being processed. Please refresh your browser after a bit.',
    FILE_NAME_NOT_MATCH_OR_EXIST:
        'Your uploaded file is not valid. File name is not matched with the original file.',
    CONTENTS_OF_COLUMN_ID_NOT_MATCH_OR_CORRECT:
        'Your uploaded file is not valid. Content of Id column is not matched with the original file.',
    CONTENTS_OF_COLUMN_SOURCE_NOT_MATCH_OR_CORRECT:
        'Your uploaded file is not valid. Content of Source Language column is not matched with the original file.',
    CONTENTS_OF_COLUMN_RAW_NOT_MATCH_OR_CORRECT:
        'Your uploaded file is not valid. Content of Raw Content column is not matched with the original file'
};
const getText = key => localizeData[key] || key;
export default getText;
