import React from 'react';
import { connect } from 'react-redux';

const AccessControl = ({
    children,
    requiredRole,
    can,
    isAddmin,
    isUser,
    ...rest
}) => {
    if (requiredRole === 'admin') {
        if (isAddmin) {
            return React.cloneElement(children);
        } else {
            switch (can) {
                case 'view':
                    return <span />;
                case 'action':
                    return React.cloneElement(children, { readOnly: true });

                default:
                    return <span />;
            }
        }
    } else {
        return React.cloneElement(children); // no admin required
    }
};

const mapStateToProps = (state, ownProps) => {
    const user = state.getIn(['auth', 'user']);
    const roles = user ? user.toJS().roles.map(role => role.authority) : [];

    return {
        isUser: roles.includes('TMS:ROLE_TMS_TRANSLATOR'),
        isAddmin:
            roles.includes('TMS:ROLE_TMS_PM') ||
            roles.includes('TMS:ROLE_TMS_ADMIN')
    };
};

export default connect(
    mapStateToProps,
    null
)(AccessControl);
