import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';

import { ORDER } from './actionConstants';

const INITIAL_STATE = fromJS({
    loading: false,
    calculating: false,
    error: null,
    message: null,
    mapStatus: {},
    data: null, // order list
    jobs: {
        'order-id-sample': {
            loading: false,
            data: null, // job list
            details: {
                'job-id-sample-1': {},
                'job-id-sample-2': {}
            }
        }
    },
    details: {
        'order-id-sample': {
            loading: false,
            data: null
        }
    },
    checklists: {
        loading: false,
        data: null // checklist list
    }
});

const handlers = {
    [ORDER.ON_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['calculating'], false);
        });
    },
    [ORDER.SHOW_MESSAGE](state, { message }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['message'], message);
        });
    },
    [ORDER.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['message'], null);
        });
    },
    [ORDER.FETCH_ORDER_LIST](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], true);
        });
    },
    [ORDER.FETCH_ORDER_BUFFER_LIST](state, { data }) {
        const { limit, offset } = data;
        const total = limit + offset;
        let fullData = state.getIn(['data']);
        fullData = fullData ? fullData.toJS() : new Array(total);
        const endIndex = Math.min(total, fullData.length);

        for (let i = offset; i < endIndex; i++) {
            fullData[i] = 'loading';
        }

        return state.withMutations(mutableState => {
            mutableState.setIn(['data'], fromJS(fullData));
        });
    },
    [ORDER.ON_ORDER_LIST_FETCHED](state, { data }) {
        const { offset, total, dataRows, mapStatus } = data;
        let fullData = state.getIn(['data']);

        fullData = fullData && offset ? fullData.toJS() : new Array(total);

        for (let i = 0; i < dataRows.length; i++) {
            fullData[offset + i] = dataRows[i];
        }

        if (offset === 0) {
            for (let i = dataRows.length; i < total; i++) {
                fullData[i] = 'empty';
            }
        }

        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['data'], fromJS(fullData));
            mutableState.set('mapStatus', mapStatus);

            dataRows.forEach(item => {
                if (item) {
                    mutableState.mergeDeep({
                        details: {
                            [item.orderId]: {
                                loading: false,
                                data: fromJS(item)
                            }
                        }
                    });
                }
            });
        });
    },
    [ORDER.FETCH_ORDER_JOB_LIST](state, { data }) {
        return state.mergeDeep({
            jobs: {
                [data.orderId]: {
                    loading: true,
                    data: null,
                    details: {}
                }
            }
        });
    },
    [ORDER.ON_ORDER_JOB_LIST_FETCHED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['jobs', orderId, 'loading'], false);
            mutableState.setIn(['jobs', orderId, 'data'], data.jobs);

            data.jobs.forEach(item => {
                mutableState.mergeDeep({
                    jobs: {
                        [orderId]: {
                            details: {
                                [item.id]: item
                            }
                        }
                    }
                });
            });
        });
    },
    [ORDER.FETCH_ORDER_DETAILS](state, { data }) {
        return state.mergeDeep({
            details: {
                [data.orderId]: {
                    loading: true,
                    data: null
                }
            }
        });
    },
    [ORDER.ON_ORDER_DETAILS_FETCHED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['details', orderId, 'loading'], false);
            mutableState.setIn(['details', orderId, 'data'], data.details);
            mutableState.set('mapStatus', data.mapStatus);
        });
    },
    [ORDER.ON_ORDER_ASSIGNED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['details', orderId, 'data'], data.details);
        });
    },
    [ORDER.ON_ORDER_DELIVERED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['details', orderId, 'data'], data.details);
        });
    },
    [ORDER.ON_ORDER_DEADLINE_SET](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['details', orderId, 'data'], data.details);
        });
    },
    [ORDER.ON_JOB_ASSIGNED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(
                ['jobs', orderId, 'details', data.jobId],
                data.details
            );
        });
    },
    [ORDER.ON_TRANSLATED_FILE_UPLOADED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(
                ['jobs', orderId, 'details', data.jobId],
                data.details
            );
        });
    },
    [ORDER.ON_JOB_DONE_CONFIRMED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(
                ['jobs', data.orderId, 'details', data.jobId],
                data.details
            );
        });
    },
    [ORDER.ON_JOB_EMAIL_SENT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(
                ['jobs', data.orderId, 'details', data.jobId],
                data.details
            );
        });
    },
    [ORDER.UPLOAD_FILE](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.MERGE_TRANSLATED_FILES](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_TRANSLATED_FILES_MERGED](state, { data }) {
        const orderId = data.orderId;

        return state.withMutations(mutableState => {
            //mutableState.setIn(['calculating'], false);
            mutableState.setIn(['details', orderId, 'data'], data.details);
        });
    },
    [ORDER.ON_PM_ASSETS_UPLOADED](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
        });
    },

    [ORDER.FETCH_CHECKLIST](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['checklists', 'loading'], true);
        });
    },
    [ORDER.FETCH_BUFFER_CHECKLIST](state, { data }) {
        const { limit, offset } = data;
        const total = limit + offset;
        let fullData = state.getIn(['checklists', 'data']);
        fullData = fullData ? fullData.toJS() : new Array(total);
        const endIndex = Math.min(total, fullData.length);

        for (let i = offset; i < endIndex; i++) {
            fullData[i] = 'loading';
        }

        return state.withMutations(mutableState => {
            mutableState.setIn(['checklists', 'data'], fromJS(fullData));
        });
    },
    [ORDER.ON_CHECKLIST_FETCHED](state, { data }) {
        const { offset, total, dataRows } = data;
        let fullData = state.getIn(['checklists', 'data']);

        fullData = fullData && offset ? fullData.toJS() : new Array(total);

        for (let i = 0; i < dataRows.length; i++) {
            fullData[offset + i] = dataRows[i];
        }

        if (offset === 0) {
            for (let i = dataRows.length; i < total; i++) {
                fullData[i] = 'empty';
            }
        }

        return state.withMutations(mutableState => {
            mutableState.setIn(['checklists', 'loading'], false);
            mutableState.setIn(['checklists', 'data'], fromJS(fullData));
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
