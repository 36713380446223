import React from 'react';
import classNames from 'classnames';

const ICON_MAPPING = {
    success: 'info-circle fa-lg',
    danger: 'exclamation-triangle',
    warning: 'exclamation-circle fa-lg'
};

export default function Message({ type, message, value, onClose, className }) {
    const icon = ICON_MAPPING[type];
    const itemClass = classNames(`alert alert-${type}`, className);

    const onClick = () => {
        if (onClose) {
            onClose(value, message);
        }
    };

    if (message) {
        return (
            <div className={itemClass}>
                {icon && <i className={`fa fa-${icon}`} />}
                <span
                    dangerouslySetInnerHTML={{
                        __html: message
                    }}
                />
                {onClose && (
                    <i
                        className="fas fa-times-circle clickable"
                        onClick={onClick}
                    />
                )}
            </div>
        );
    }

    return <span />;
}
