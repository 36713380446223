import React from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import { TextFieldInput, Select, Message } from 'components';
import { LANGUAGE_OPTIONS } from 'common/languageData';
import { createUser, updateUser } from 'services/user';
import { APP_NAME } from 'common/const';
import { getMessage } from 'common/getMessage';

import { USER_ROLES } from './constants';

import './UserForm-style.css';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        userName: Yup.string().required('Full name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        confirmEmail: Yup.string()
            .required('Confirm email is required')
            .oneOf([Yup.ref('email'), null], 'Emails do not match'),
        role: Yup.string().required('Role is required')
    }),
    mapPropsToValues: ({ user }) => {
        const values = user || {};
        const selectedLangs = values.translationLanguages;

        return {
            userName: values.userName,
            email: values.email,
            confirmEmail: values.email,
            role: values.role || USER_ROLES.PM,
            languages: selectedLangs
        };
    },
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        setStatus({ error: null });
        const user = props.user;

        if (user) {
            updateUser({
                userUid: user.userUid,
                email: payload.email,
                fullName: payload.userName,
                translationLanguages: payload.languages || []
            })
                .then(data => {
                    setStatus({
                        error: null
                    });
                    setSubmitting(false);

                    props.onSuccess(data);
                })
                .catch(e => {
                    setStatus({
                        error: getMessage({
                            token: e.message,
                            httpCode: e.code || e.status
                        })
                    });
                    setSubmitting(false);
                });
        } else {
            createUser({
                fullName: payload.userName,
                email: payload.email,
                role: payload.role,
                translationLanguages: payload.languages || [],
                serviceType: APP_NAME
            })
                .then(data => {
                    setStatus({
                        error: null
                    });
                    setSubmitting(false);

                    props.onSuccess(data);
                })
                .catch(e => {
                    setStatus({
                        error: getMessage({
                            token: e.message,
                            httpCode: e.code || e.status
                        })
                    });
                    setSubmitting(false);
                });
        }
    },
    displayName: 'UserForm'
});

const UserForm = props => {
    const {
        user,
        values,
        touched,
        errors,
        isValid,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        status,
        onCancel
    } = props;

    const ROLE_OPTIONS = [
        { value: USER_ROLES.PM, label: 'PM' },
        { value: USER_ROLES.TRANSLATOR, label: 'Translator' }
    ];

    const message = status && status.error && (
        <Message type="danger" message={status.error} />
    );

    const selectedLangs = values.languages
        ? LANGUAGE_OPTIONS.filter(option => {
              return values.languages.includes(option.value);
          })
        : [];

    return (
        <div className="user-form">
            <div className="title">{user ? 'Edit user' : 'Add new user'}</div>
            {message}
            <form onSubmit={handleSubmit} autoComplete="off">
                <TextFieldInput
                    id="userName"
                    type="text"
                    label="Full name"
                    placeholder=""
                    required
                    error={touched.userName && errors.userName}
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextFieldInput
                    id="email"
                    type="text"
                    label="Email"
                    placeholder=""
                    required
                    error={touched.email && errors.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextFieldInput
                    id="confirmEmail"
                    type="email"
                    label="Confirm email"
                    placeholder=""
                    required
                    error={touched.confirmEmail && errors.confirmEmail}
                    value={values.confirmEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste="off"
                    autoComplete="new-value"
                />

                <Select
                    id="role"
                    label="Role"
                    options={ROLE_OPTIONS}
                    value={values.role}
                    placeholder=""
                    required
                    isDisabled={!!user}
                    error={touched.role && errors.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                {values.role === USER_ROLES.TRANSLATOR && (
                    <Select
                        id="languages"
                        label="Translation language(s)"
                        options={LANGUAGE_OPTIONS}
                        value={selectedLangs}
                        placeholder=""
                        isMulti={true}
                        error={touched.languages && errors.languages}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                )}

                <div className="submit-btns">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-apply"
                        disabled={isSubmitting || !isValid}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(UserForm);

export default EnhancedForm;
