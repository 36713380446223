import React from 'react';
import classNames from 'classnames';

import './SearchInput-style.css';

const SearchInput = ({
    className,
    placeholder = 'Search...',
    name = 'search',
    value,
    onChange
}) => {
    const componentClass = classNames('search-input', className);
    const onInput = event => {
        onChange(event.target.value);
    };

    return (
        <div className={componentClass}>
            <input
                type="text"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onInput}
            />
            <i className="fa fa-search" />
        </div>
    );
};

export default SearchInput;
