import React from 'react';
import { array, bool, object, func } from 'prop-types';
import clone from 'lodash/clone';
import { AutoSizer } from 'react-virtualized';
import { AccessControl } from 'auth';
import { Table } from 'components';
import { UserDropdownInput } from 'users';
import FileDownload from './FileDownload';
import Status from './Status';
import JobList from './JobList';
import OrderIdCell from './OrderIdCell';
import DateTimePopoverInput from './DateTimePopoverInput';

class OrderTable extends React.PureComponent {
    static propTypes = {
        data: array,
        mapStatus: object,
        filterParams: object,
        sortParams: object,
        readOnly: bool,
        onSortChange: func,
        fetchBufferData: func
    };

    static defaultProps = {
        filterParams: {},
        sortParams: {}
    };

    orderIdRender = ({ rowData, columnKey }) => {
        const orderId = rowData[columnKey];

        return <OrderIdCell orderId={orderId} />;
    };

    dateInputRender = ({ rowData, columnKey }) => {
        return (
            <AccessControl requiredRole={'admin'} can="action">
                <DateTimePopoverInput
                    key={`date-input-${rowData.orderId}`}
                    value={rowData[columnKey]}
                    orderId={rowData.orderId}
                />
            </AccessControl>
        );
    };

    sourceFileRender = ({ rowData, columnKey }) => {
        return (
            <FileDownload
                orderId={rowData.uid}
                fileName={rowData[columnKey]}
                isTruncate={true}
            />
        );
    };

    orderStatusRender = ({ rowData, columnKey }) => {
        return <Status value={rowData[columnKey]} map={this.props.mapStatus} />;
    };

    rowExpandedRender = ({ rowId }) => {
        return <JobList orderId={rowId} />;
    };

    userRender = ({ rowData, columnKey }) => {
        return (
            <AccessControl requiredRole={'admin'} can="action">
                <UserDropdownInput
                    selectedValue={rowData[columnKey]}
                    orderId={rowData.orderId}
                    placeholder="Select a PM"
                    userRole="pm"
                />
            </AccessControl>
        );
    };

    headerDeadlineRender = ({ label }) => {
        return (
            <span>
                {label} <span className="note-text">(GMT+1)</span>
            </span>
        );
    };

    buildColumns = () => {
        const { readOnly } = this.props;
        const columns = [
            {
                label: '',
                columnKey: 'id',
                type: 'collapse',
                flexGrow: 0,
                width: 32,
                sortable: false
            },
            {
                label: 'Order ID',
                columnKey: 'orderId',
                cellRenderer: this.orderIdRender,
                flexGrow: 0,
                width: 100
            },
            {
                label: 'Company name',
                columnKey: 'companyName',
                width: 250
            },
            {
                label: 'Game name',
                columnKey: 'project',
                width: 250
            },
            {
                label: 'Order date',
                columnKey: 'createdDate',
                flexGrow: 0,
                width: 130
            },
            {
                label: 'Deadline',
                columnKey: 'deadline',
                headerRenderer: this.headerDeadlineRender,
                cellRenderer: this.dateInputRender,
                flexGrow: 0,
                width: 200
            },
            {
                label: 'Reference file',
                columnKey: 'sourceFile',
                cellRenderer: this.sourceFileRender
            },
            {
                label: 'PM',
                columnKey: 'manager',
                cellRenderer: this.userRender
            }
        ];

        if (!readOnly) {
            columns.push({
                label: 'Status',
                columnKey: 'state',
                cellRenderer: this.orderStatusRender
            });
        }

        return columns;
    };

    onBufferDataShouldFetch = (startIndex, bufferCount) => {
        const { fetchBufferData } = this.props;

        fetchBufferData(startIndex, bufferCount);
    };

    render() {
        const { data, sortParams, onSortChange } = this.props;

        const { sortKey, sortType } = sortParams;

        const columns = this.buildColumns();
        let filteredData = clone(data);

        /*for (let param in filterParams) {
            if (filterParams[param] !== undefined) {
                filteredData = filteredData.filter(data => {
                    return (
                        data[param] &&
                        data[param]
                            .toLowerCase()
                            .includes(filterParams[param].toLowerCase())
                    );
                });
            }
        }*/

        if (sortKey && sortType) {
            filteredData.sort((dataA, dataB) => {
                const valueA = dataA[sortKey];
                const valueB = dataB[sortKey];
                let sortVal = 0;

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }

        return (
            <div className="table-container">
                <AutoSizer>
                    {({ width, height }) => (
                        <React.Fragment>
                            <Table
                                data={filteredData}
                                columns={columns}
                                width={width}
                                height={height - 50}
                                rowExpandedRender={this.rowExpandedRender}
                                sortKey={sortKey}
                                sortType={sortType}
                                onSortChange={onSortChange}
                                onBufferDataShouldFetch={
                                    this.onBufferDataShouldFetch
                                }
                            />
                            <div
                                className="showing-count px-2"
                                style={{ width, height: 50 }}
                            >
                                {`Showing ${filteredData.length} order(s)`}
                            </div>
                        </React.Fragment>
                    )}
                </AutoSizer>
            </div>
        );
    }
}

export default OrderTable;
