import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, bool, object, string, number } from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

import {
    LanguageCountry,
    Table,
    UploadIcon,
    FileProgress,
    Checkbox
} from 'components';
import { UserDropdownInput } from 'users';

import {
    downloadExtractedFile,
    downloadTranslatedFile,
    uploadTranslatedFile,
    confirmJobDone,
    notifyJobAssignment,
    updateJobRequireChecklist
} from './actions';
import FileDownload from './FileDownload';
import QAChecklistModal from './QAChecklistModal';
import Status from './Status';
import { STATE_VALUES } from './constants';

class TargetLangTable extends React.PureComponent {
    static propTypes = {
        data: array,
        readOnly: bool,
        user: object,
        projectName: string
    };

    state = {
        confirmModalShow: false,
        checklistConfirm: {},
        checkedRequireChecklist: this.props.data
            ? this.props.data
                  .filter(job => job.requireChecklist)
                  .map(filteredJob => filteredJob.id)
            : [],
        totalWordCount: number,
        settingSheets: array
    };

    langsRender = ({ rowData, columnKey }) => {
        const langCode = rowData[columnKey];

        return <LanguageCountry languageCode={langCode} />;
    };

    extractedFileRender = ({ rowData, columnKey }) => {
        const { downloadExtractedFile } = this.props;

        return (
            <FileDownload
                orderId={rowData.orderUid}
                fileName={rowData[columnKey]}
                handleFileDownload={downloadExtractedFile}
            />
        );
    };

    translatedFileRender = ({ rowData, columnKey }) => {
        const { downloadTranslatedFile } = this.props;
        const fileName = rowData[columnKey];
        return (
            <Fragment>
                <FileDownload
                    orderId={rowData.orderUid}
                    fileName={fileName}
                    jobId={rowData.id}
                    handleFileDownload={downloadTranslatedFile}
                    isTruncate={true}
                />
                {fileName && (
                    <FileProgress
                        className="translated-progress"
                        data={rowData.progress}
                    />
                )}
            </Fragment>
        );
    };

    statusRender = ({ rowData, columnKey }) => {
        return <Status value={rowData[columnKey]} map={this.props.mapStatus} />;
    };

    requireChecklistHeaderRender = ({ label }) => {
        const { data, updateJobRequireChecklist } = this.props;

        const allJobs = data.map(filteredJob => filteredJob.id);
        const orderId = data ? data[0].orderId : 0;
        const { checkedRequireChecklist } = this.state;

        const onChange = checked => {
            let list = [];
            if (checked) {
                list = allJobs;
            }

            this.setState({
                checkedRequireChecklist: list
            });

            updateJobRequireChecklist({
                orderId,
                jobIds: allJobs,
                isChecked: !!checked,
                onFailCallback: () => {
                    this.setState({
                        checkedRequireChecklist
                    });
                }
            });
        };

        return (
            <div style={{ display: 'flex' }}>
                <span>{label}</span>
                &nbsp;&nbsp;
                <Checkbox
                    onChange={onChange}
                    checked={allJobs.length <= checkedRequireChecklist.length}
                />
            </div>
        );
    };

    requireChecklistRender = ({ rowData, columnKey }) => {
        const { updateJobRequireChecklist } = this.props;
        const { checkedRequireChecklist } = this.state;
        const checked = checkedRequireChecklist.includes(rowData.id);

        const onChange = checked => {
            let checkedList = [];
            if (checked) {
                checkedList = [...checkedRequireChecklist, rowData.id];
            } else {
                checkedList = checkedRequireChecklist.filter(
                    jobId => jobId !== rowData.id
                );
            }
            this.setState({
                checkedRequireChecklist: checkedList
            });

            updateJobRequireChecklist({
                orderId: rowData.orderId,
                jobIds: [rowData.id],
                isChecked: !!checked,
                onFailCallback: () => {
                    this.setState({
                        checkedRequireChecklist
                    });
                }
            });
        };

        return <Checkbox onChange={onChange} checked={checked} />;
    };

    actionsRender = ({ rowData, columnKey }) => {
        const {
            uploadTranslatedFile,
            readOnly,
            notifyJobAssignment,
            confirmJobDone
        } = this.props;
        const { checkedRequireChecklist } = this.state;

        const onFileInput = files => {
            uploadTranslatedFile(rowData.orderId, rowData.id, files);
        };
        const onConfirmDoneClick = () => {
            if (checkedRequireChecklist.includes(rowData.id)) {
                this.setState({
                    confirmModalShow: true,
                    checklistConfirm: {
                        orderId: rowData.orderId,
                        jobId: rowData.id,
                        target: rowData.target
                    }
                });
            } else {
                confirmJobDone(rowData.orderId, rowData.id);
            }
        };
        const onEmailBtnClick = () => {
            notifyJobAssignment(rowData.orderId, rowData.id, rowData.assignee);
        };

        const jobState = rowData.state;
        const validProgress = !(!rowData.progress || rowData.progress.err);
        const confirmDoneClickable =
            !!rowData.translatedFile &&
            jobState !== STATE_VALUES.DONE &&
            validProgress;

        const confirmDoneClass = classNames('btn confirm-btn', {
            done: jobState === STATE_VALUES.DONE,
            disabled: !confirmDoneClickable,
            clickable: confirmDoneClickable
        });
        const emailBtnClickable =
            jobState === STATE_VALUES.ASSIGNED ||
            jobState === STATE_VALUES.DECLINED;

        const emailBtnClass = classNames('btn', {
            done: [
                STATE_VALUES.DONE,
                STATE_VALUES.ACCEPTED,
                STATE_VALUES.COMMITED,
                STATE_VALUES.REQUESTED,
                STATE_VALUES.HOLDED
            ].includes(jobState),
            clickable: emailBtnClickable,
            disabled: !rowData.assignee
        });

        return (
            <div className="action-btns">
                {!readOnly && (
                    <span
                        className={emailBtnClass}
                        onClick={
                            emailBtnClickable ? onEmailBtnClick : undefined
                        }
                        title={emailBtnClickable ? 'Send email' : undefined}
                    >
                        <i className="fas fa-envelope" />
                    </span>
                )}
                <UploadIcon
                    id={`${rowData.id}`}
                    onNewFiles={onFileInput}
                    title="Upload file"
                />
                <span
                    title={confirmDoneClickable ? 'Mark as done' : null}
                    className={confirmDoneClass}
                    onClick={confirmDoneClickable ? onConfirmDoneClick : null}
                >
                    <i className="fas fa-check-circle" />
                </span>
            </div>
        );
    };

    userRender = ({ rowData, columnKey }) => {
        const { readOnly } = this.props;

        return (
            <UserDropdownInput
                selectedValue={rowData[columnKey]}
                orderId={rowData.orderId}
                jobId={rowData.id}
                placeholder="Select a translator"
                userRole="translator"
                target={rowData.target}
                readOnly={readOnly}
            />
        );
    };

    buildColumns = data => {
        const { readOnly } = this.props;

        const columns = [
            {
                label: 'Target language',
                columnKey: 'target',
                cellRenderer: this.langsRender,
                width: readOnly ? 200 : 150,
                sortable: false
            },
            {
                label: 'Word count',
                columnKey: 'wordCount',
                width: 100,
                sortable: false
            },
            {
                label: 'File to translate',
                columnKey: 'originalFile',
                cellRenderer: this.extractedFileRender,
                sortable: false
            },
            {
                label: 'Translator',
                columnKey: 'assignee',
                sortable: false,
                cellRenderer: this.userRender
            },
            {
                label: 'Status',
                columnKey: 'state',
                cellRenderer: this.statusRender,
                width: 200,
                flexGrow: 0,
                sortable: false
            },
            {
                label: 'Translated file',
                columnKey: 'translatedFile',
                cellRenderer: this.translatedFileRender,
                flexGrow: 0,
                width: 250,
                sortable: false
            },
            {
                label: 'Require checklist',
                cellRenderer: this.requireChecklistRender,
                headerRenderer: this.requireChecklistHeaderRender,
                sortable: false,
                width: 170,
                className: 'right-cell',
                hidden: readOnly
            },
            {
                label: '     ',
                columnKey: 'index',
                flexGrow: 0,
                sortable: false,
                width: readOnly ? 120 : 140,
                cellRenderer: this.actionsRender
            }
        ];

        return columns.filter(col => !col.hidden);
    };

    onChecklistFormClose = () => {
        this.setState({
            confirmModalShow: false,
            checklistConfirm: {}
        });
    };

    onChecklistFormSubmit = () => {
        const { confirmJobDone } = this.props;
        const { orderId, jobId } = this.state.checklistConfirm;

        confirmJobDone(orderId, jobId);
        this.onChecklistFormClose();
    };

    render() {
        const { data, user, projectName } = this.props;
        const { confirmModalShow, checklistConfirm } = this.state;

        const columns = this.buildColumns(data);

        return (
            <Fragment>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            className="target-lang-table"
                            data={data}
                            columns={columns}
                            width={width}
                            rowHeight={50}
                        />
                    )}
                </AutoSizer>
                {confirmModalShow && (
                    <QAChecklistModal
                        orderId={checklistConfirm.orderId}
                        jobId={checklistConfirm.jobId}
                        translatorFullName={user.userName || user.firstName}
                        target={checklistConfirm.target}
                        projectName={projectName}
                        onCancel={this.onChecklistFormClose}
                        onSubmit={this.onChecklistFormSubmit}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    mapStatus: state.getIn(['orders', 'mapStatus'])
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            downloadExtractedFile,
            downloadTranslatedFile,
            uploadTranslatedFile,
            confirmJobDone,
            notifyJobAssignment,
            updateJobRequireChecklist
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetLangTable);
