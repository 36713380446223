export const ORDER = {
    FETCH_ORDER_LIST: 'FETCH_ORDER_LIST',
    FETCH_ORDER_BUFFER_LIST: 'FETCH_ORDER_BUFFER_LIST',
    ON_ORDER_LIST_FETCHED: 'ON_ORDER_LIST_FETCHED',
    FETCH_ORDER_JOB_LIST: 'FETCH_ORDER_JOB_LIST',
    ON_ORDER_JOB_LIST_FETCHED: 'ON_ORDER_JOB_LIST_FETCHED',
    FETCH_ORDER_DETAILS: 'FETCH_ORDER_DETAILS',
    ON_ORDER_DETAILS_FETCHED: 'ON_ORDER_DETAILS_FETCHED',
    ON_ORDER_ASSIGNED: 'ON_ORDER_ASSIGNED',
    ON_JOB_ASSIGNED: 'ON_JOB_ASSIGNED',
    ON_JOB_EMAIL_SENT: 'ON_JOB_EMAIL_SENT',
    ON_JOB_DONE_CONFIRMED: 'ON_JOB_DONE_CONFIRMED',
    ON_ORDER_DEADLINE_SET: 'ON_ORDER_DEADLINE_SET',
    ON_TRANSLATED_FILE_UPLOADED: 'ON_TRANSLATED_FILE_UPLOADED',
    UPLOAD_FILE: 'UPLOAD_FILE',
    MERGE_TRANSLATED_FILES: 'MERGE_TRANSLATED_FILES',
    ON_TRANSLATED_FILES_MERGED: 'ON_TRANSLATED_FILES_MERGED',
    DELIVER_ORDER: 'DELIVER_ORDER',
    ON_ORDER_DELIVERED: 'ON_ORDER_DELIVERED',
    ON_FAILED: 'ON_FAILED',
    SHOW_MESSAGE: 'SHOW_MESSAGE',
    CLOSE_MESSAGE: 'CLOSE_MESSAGE',
    UPDATE_JOB_CHECKLIST: 'UPDATE_JOB_CHECKLIST',
    ON_JOB_CHECKLIST_UPDATED: 'ON_JOB_CHECKLIST_UPDATED',
    FETCH_CHECKLIST: 'FETCH_CHECKLIST',
    FETCH_BUFFER_CHECKLIST: 'FETCH_BUFFER_CHECKLIST',
    ON_CHECKLIST_FETCHED: 'ON_CHECKLIST_FETCHED',
    ON_PM_COMMENT_UPDATE: 'ON_PM_COMMENT_UPDATE',
    ON_PM_ASSETS_UPLOADED: 'ON_PM_ASSETS_UPLOADED'
};
