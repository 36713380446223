import { requestData, receiveData } from '../api/actions';
import { getUserList, activateUser, deactivateUser } from 'services/user';

import { USER } from './actionConstants';
import { USER_ROLE_OPTIONS } from './constants';

export function showModal(data) {
    return {
        type: USER.SHOW_MODAL,
        data
    };
}

export function closeModal() {
    return {
        type: USER.CLOSE_MODAL
    };
}

export function reloadUserList() {
    return fetchUserList();
}

export function fetchUserList() {
    return dispatch => {
        dispatch(requestData(USER.FETCH_USER_LIST));

        getUserList()
            .then(payload => {
                const users = payload.map(user => {
                    const roles = user.roles || [];
                    const role = roles[0].roleName;

                    return {
                        ...user,
                        role,
                        roleName: USER_ROLE_OPTIONS[role] || ''
                    };
                });

                dispatch(receiveData(USER.ON_USER_LIST_FETCHED, users));
            })
            .catch(e => {
                console.log(e);
                dispatch(receiveData(USER.ON_FAILED, e));
            });
    };
}

export function toogleUserStatus(user) {
    return dispatch => {
        if (user.status === true) {
            deactivateUser(user)
                .then(payload => {
                    dispatch(
                        receiveData(USER.ON_USER_UPDATED, {
                            ...user,
                            status: !user.status
                        })
                    );
                })
                .catch(e => {
                    console.log(e);
                    dispatch(receiveData(USER.ON_FAILED, e));
                });
        } else {
            activateUser(user)
                .then(payload => {
                    dispatch(
                        receiveData(USER.ON_USER_UPDATED, {
                            ...user,
                            status: !user.status
                        })
                    );
                })
                .catch(e => {
                    console.log(e);
                    dispatch(receiveData(USER.ON_FAILED, e));
                });
        }
    };
}
