import React from 'react';
import { array, func, any, string, bool, oneOfType } from 'prop-types';
import ReactSelect from 'react-select';

export class Select extends React.Component {
    static propTypes = {
        id: string.isRequired,
        label: string.isRequired,
        value: oneOfType([string, array]),
        isMulti: bool,
        isDisabled: bool,
        options: array,
        placeholder: string,
        required: bool,
        error: any,
        className: string,
        setFieldValue: func.isRequired,
        setFieldTouched: func.isRequired
    };

    state = {
        value: this.props.value
    };

    handleChange = selected => {
        const { id, setFieldValue, setFieldTouched } = this.props;

        if (selected != null) {
            let value = selected.value;

            if (selected.length) {
                value = selected.map(o => o.value);
            }
            setFieldValue(id, value);
            setFieldTouched(id, true);
            this.setState({ value: selected });
        } else {
            setFieldValue(id, '');
            this.setState({ value: '' });
        }
    };

    render() {
        const {
            id,
            error,
            label,
            className,
            options,
            placeholder,
            isMulti,
            isDisabled,
            required
        } = this.props;
        const { value } = this.state;
        const defaultValue = isMulti
            ? value
            : options
            ? options.find(option => option.value === value)
            : '';

        return (
            <div className={`form-group ${className}`}>
                <label className="form-label">
                    {label} {required && <span>(Required)</span>}
                </label>
                <ReactSelect
                    id={id}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    closeOnSelect={true}
                    value={defaultValue}
                    onChange={data => {
                        this.handleChange(data);
                    }}
                    options={options}
                    placeholder={placeholder}
                    isSearchable
                    className="select"
                />
                <div className={error ? 'error' : ''}>
                    {error && <div className="input-feedback">{error}</div>}
                </div>
            </div>
        );
    }
}
