import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, array, bool, string } from 'prop-types';
import get from 'lodash/get';
import { Spinner, toJS } from 'components';
import { AccessControl } from 'auth';

import { fetchOrderJobList } from './actions';
import JobTable from './JobTable';

class JobList extends React.PureComponent {
    static propTypes = {
        detailId: string,
        data: array,
        fetchData: func,
        loading: bool
    };

    componentDidMount() {
        const { detailId, loading, data, fetchData } = this.props;

        if (!loading && !data) {
            fetchData(detailId);
        }
    }

    render() {
        const { loading, data } = this.props;

        if (loading || !data) {
            return <Spinner />;
        }

        return (
            <div className="list-container job-list">
                <AccessControl requiredRole={'admin'} can="action">
                    <JobTable data={data} />
                </AccessControl>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const detailId = ownProps.orderId;
    const jobs = state.getIn(['orders', 'jobs']).toJS();

    const orderJobs = get(jobs, [detailId, 'data']);
    const orderJobDetails = get(jobs, [detailId, 'details']);

    return {
        detailId,
        loading: get(jobs, [detailId, 'loading']),
        data: orderJobs && orderJobs.map(item => orderJobDetails[item.id])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchOrderJobList
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(JobList));
