import React from 'react';
import Popover from 'react-tiny-popover';
import { DateTimeInput } from 'components';

import { string, any, func } from 'prop-types';
import { dateTimeToString, DATE_TIME_FORMAT } from '../common/timeUtils';

import './DateTimePopoverInput-style.css';

const UTC_OFFSET = 60;

class DateTimePopoverInput extends React.Component {
    static propTypes = {
        id: string,
        value: any,
        onChange: func,
        setFieldValue: func
    };

    state = {
        show: false,
        value: this.props.value
    };

    handleClickOnside = event => {
        event.stopPropagation();
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    onButtonClick = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    onWheel = e => {
        this.setState({
            show: false
        });
    };

    onChange = date => {
        const { id, value, onChange, setFieldValue } = this.props;

        if (value !== date) {
            onChange && onChange(date);
            setFieldValue && setFieldValue(id, date);
        }

        this.setState({
            show: false,
            value: date
        });
    };

    render() {
        const { show, value } = this.state;
        const contentClass = `content ${value ? '' : 'empty'}`;

        return (
            <Popover
                isOpen={show}
                content={
                    <div className="popover-content popover-content-large">
                        <div
                            className={contentClass}
                            onClick={this.handleClickOnside}
                        >
                            <DateTimeInput
                                selectedValue={value}
                                autoFocus
                                utcOffset={UTC_OFFSET}
                                onEnter={this.onChange}
                            />
                        </div>
                        <div
                            className="overlay"
                            onWheel={this.onWheel}
                            onClick={this.handleClickOutside}
                        />
                    </div>
                }
            >
                <span
                    className={`date-time-input selection clickable ${
                        show ? 'selected' : ''
                    }`}
                    onClick={() => this.setState({ show: !show })}
                >
                    <span className={contentClass}>
                        <i className="far fa-calendar-alt" />
                        {value
                            ? dateTimeToString(
                                  value,
                                  DATE_TIME_FORMAT.MM_DD_YYYY_HH_MM,
                                  UTC_OFFSET
                              )
                            : 'Select a date'}
                    </span>
                </span>
            </Popover>
        );
    }
}

export default DateTimePopoverInput;
