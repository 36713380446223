import React from 'react';
import { queryParamsToObject } from 'common/urlQueryUtils';
import { API_URL } from 'common/const';

class DeliveryPage extends React.PureComponent {
    componentDidMount() {
        const { location } = this.props;
        const params = queryParamsToObject(location.search);

        if (params.orderId && params.tok) {
            window.location.replace(
                `${API_URL}/tms/interaction/deliver-file/${params.orderId}?tok=${params.tok}`
            );
        }
    }

    render() {
        return <span />;
    }
}

export default DeliveryPage;
