import React from 'react';
import './Spinner-style.css';

function Spinning() {
    return (
        <div className="spinner-wrapper">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
        </div>
    );
}

export const Spinner = Spinning;
export const Loading = () => (
    <div className="loading-spinner w-100 h-100 position-relative">
        <Spinning />
    </div>
);

export const SpinnerIcon = () => {
    return <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />;
};
