import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserList } from './actions';

class UsersFetch extends React.PureComponent {
    componentDidMount() {
        const { userList, userListLoading, fetchUserList } = this.props;

        if (!userListLoading && !userList) {
            fetchUserList();
        }
    }

    render() {
        return <span />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userListLoading: state.getIn(['users', 'loading']),
        userList: state.getIn(['users', 'data'])
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserList
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersFetch);
