module.exports = {
    size: 50,
    entry: '/secure/v1/customers',
    keys: [
        'userId',
        'fortnoxCustomerId',
        'companyName',
        'email',
        'userName',
        'created',
        'status'
    ],
    fields: {
        userId: {
            label: 'ID (int)',
            width: 80,
            sortable: false,
            editable: false
        },
        email: {
            label: 'Email',
            width: 380,
            sortable: false,
            editable: true,
            keyFilter: 'email'
        },
        userName: {
            label: 'Full Name',
            width: 180,
            sortable: false,
            editable: true
        },
        firstName: {
            label: 'First Name',
            width: 180,
            sortable: false,
            editable: true
        },
        lastName: {
            label: 'Last Name',
            width: 180,
            sortable: false,
            editable: true
        },
        status: {
            label: '',
            width: 120,
            sortable: false,
            editable: true
        },
        fortnoxCustomerId: {
            label: 'Fortnox Id',
            width: 120,
            sortable: false,
            editable: true,
            keyFilter: 'fortnoxId'
        },
        companyName: {
            label: 'Company',
            width: 250,
            sortable: false,
            editable: true,
            keyFilter: 'company'
        },
        created: {
            label: 'Created Date',
            width: 150,
            sortable: false,
            editable: true
        }
    },
    paging: {
        offset: 0,
        limit: 30
    }
};
