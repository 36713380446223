import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bool, object, func } from 'prop-types';

import { Modal, toJS } from 'components';
import { closeModal, reloadUserList } from './actions';
import UserForm from './UserForm';

class UserModal extends React.Component {
    static propTypes = {
        show: bool,
        user: object,
        onClose: func
    };

    onSuccess = newUser => {
        const { onClose, reloadUserList } = this.props;

        onClose();
        reloadUserList();
    };

    render() {
        const { show, user, onClose } = this.props;

        return (
            <Modal
                show={show}
                closeable={false}
                onClose={onClose}
                className={`user-modal`}
            >
                {show && (
                    <UserForm
                        title={user ? 'Edit user' : 'Add new user'}
                        user={user}
                        onCancel={onClose}
                        onSuccess={this.onSuccess}
                    />
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const modal = state.getIn(['users', 'modal']).toJS();

    return {
        show: modal.show,
        user: modal.data
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onClose: closeModal,
            reloadUserList
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(UserModal));
