import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, string, object, bool, array } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { LanguageCountry, Alert, Message, Loading, toJS } from 'components';
import { UsersFetch, UserDropdownInput } from 'users';
import { AccessControl } from 'auth';
import {
    fetchOrderDetails,
    fetchOrderJobList,
    downloadAssetsFile,
    mergeTranslatedFiles,
    downloadMergedFile,
    deliverOrder,
    closeMessage
} from './actions';
import Status from './Status';
import FileDownload from './FileDownload';
import TargetLangTable from './TargetLangTable';
import DateTimePopoverInput from './DateTimePopoverInput';
import DeliverButton from './DeliverButton';
import PMNotes from './PMNotes';

import { STATE_VALUES, MAX_UPLOAD_FILE_SIZE } from './constants';
import QnA from '../qna';
import { dateTimeToString, DATE_TIME_FORMAT } from '../common/timeUtils';

import './OrderDetails-style.css';

const ASSET_FILE_PREFIXS = {
    originalLanguage: '[original]',
    isLegacyTranslation: '[legacy]',
    isGlossary: '[glossary]',
    isStyleGuide: '[styleguide]',
    isScreenshots: '[screenshots]'
};

const GENDERS = {
    male: 'Male',
    female: 'Female',
    both: 'Female & Male'
};

const ACTION_STYLES = {
    informal: 'Informal',
    formal: 'Formal',
    follow: `Follow linguist's advice`
};

class OrderDetails extends React.PureComponent {
    static propTypes = {
        id: string,
        data: object,
        jobs: array,
        fetchData: func,
        loading: bool,
        calculating: bool
    };

    onMergeFilesClick = () => {
        const { mergeTranslatedFiles, data } = this.props;

        mergeTranslatedFiles(data.orderId);
    };

    onDeliverClick = () => {
        const { deliverOrder, data } = this.props;

        deliverOrder(data.orderId);
    };

    buildAssetFileListV1 = () => {
        const { data, downloadAssetsFile } = this.props;
        const assetFiles = get(data, 'assets');
        const orderId = data.uid;

        const list = [];

        assetFiles &&
            assetFiles.forEach(fileName => {
                list.push(
                    <div className="file-info" key={`asset-file-${fileName}`}>
                        <FileDownload
                            orderId={orderId}
                            fileName={fileName}
                            handleFileDownload={downloadAssetsFile}
                        />
                    </div>
                );
            });

        return <div className="asset-files">{list}</div>;
    };

    // Info updated version#2
    buildAssetFileList = filterAssetId => {
        const { data, referOrderData, downloadAssetsFile } = this.props;
        const assetFiles = get(data, 'assets');
        const referAssetFiles = get(referOrderData, 'assets');
        const orderId = data.uid;

        const list = [];

        referAssetFiles &&
            referAssetFiles.forEach(fileName => {
                if (fileName.includes(filterAssetId)) {
                    list.push(
                        <div
                            className="file-info"
                            key={`asset-file-${fileName}`}
                        >
                            <FileDownload
                                orderId={referOrderData.uid}
                                fileName={fileName}
                                newFileName={fileName.replace(
                                    filterAssetId,
                                    ''
                                )}
                                handleFileDownload={downloadAssetsFile}
                            />
                        </div>
                    );
                }
            });

        assetFiles &&
            assetFiles.forEach(fileName => {
                if (fileName.includes(filterAssetId)) {
                    list.push(
                        <div
                            className="file-info"
                            key={`asset-file-${fileName}`}
                        >
                            <FileDownload
                                orderId={orderId}
                                fileName={fileName}
                                newFileName={fileName.replace(
                                    filterAssetId,
                                    ''
                                )}
                                handleFileDownload={downloadAssetsFile}
                            />
                        </div>
                    );
                }
            });

        return <div className="asset-files">{list}</div>;
    };

    buildMessage = () => {
        let component = <span />;
        const { message, error, closeMessage } = this.props;

        if (error) {
            let errorMsg =
                error.message ||
                'We encountered an unexpected problem. Please contact our Admin if you have any enquiries.';
            let errorTitle = 'Oops! Something went wrong';

            if (error.code === 'FILE_SIZE_EXCEEDED') {
                errorTitle = 'Invalid file';
                errorMsg = `The selected file exceeds the maximum size allowed (${MAX_UPLOAD_FILE_SIZE} Mb) and cannot be saved. Please select another file.`;
            }

            component = (
                <Alert
                    type="danger"
                    title={errorTitle}
                    message={errorMsg}
                    onClose={closeMessage}
                    className="error-message"
                />
            );
        } else if (message) {
            component = <Message type="success" message={message} />;
        }

        return component;
    };

    isMergeFilesReady = () => {
        const { jobs = [] } = this.props;

        return !!jobs.find(job => job.state === STATE_VALUES.DONE);
    };

    componentDidMount() {
        const {
            id,
            detailsLoading,
            referOrderId,
            referOrderData,
            isReferLoading,
            jobsLoading,
            data,
            jobs,
            fetchData,
            fetchOrderJobList
        } = this.props;

        if (!detailsLoading && !data) {
            fetchData(id);
        }

        if (!jobsLoading && !jobs) {
            fetchOrderJobList(id);
        }

        if (referOrderId && !referOrderData && !isReferLoading) {
            fetchData(referOrderId);
        }
    }

    componentDidUpdate() {
        const {
            referOrderId,
            referOrderData,
            isReferLoading,
            fetchData
        } = this.props;

        if (referOrderId && !referOrderData && !isReferLoading) {
            fetchData(referOrderId);
        }
    }

    render() {
        const {
            user,
            detailsLoading,
            jobsLoading,
            calculating,
            data,
            id,
            jobs,
            downloadMergedFile,
            mapStatus
        } = this.props;

        if (detailsLoading || !data || !jobs || jobsLoading) {
            return <Loading />;
        }

        const mergeFilesBtnClass = classNames(
            'btn btn-sm btn-outline-dark merge-btn',
            {
                disabled: !this.isMergeFilesReady()
            }
        );

        const settings = get(data, ['files', 0, 'settings']) || [];
        const settingSheets = settings.filter(item => item.sheetIndex >= 0);
        const info = get(data, 'info', {});

        return (
            <div className="order-details">
                {calculating && (
                    <div className="position-absolute w-100 h-100">
                        <Loading />
                    </div>
                )}
                {this.buildMessage()}
                <UsersFetch />
                <div className="container my-2">
                    <div className="row">
                        <div className="col-12 title">
                            <h2 className="page-title">{`Order ID: ${id}`}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 justify-content-start">
                            <div className="label">
                                <span>Order date </span>
                                <span className="note-text">(GMT+1)</span>
                            </div>
                            <span className="date-time selection">
                                <span className="content">
                                    <i className="far fa-calendar-alt" />
                                    {data.createdDate}
                                </span>
                            </span>
                        </div>
                        <div className="col-3 justify-content-end">
                            <div className="label">
                                <span>Deadline </span>
                                <span className="note-text">(GMT+1)</span>
                            </div>
                            <AccessControl requiredRole={'admin'} can="action">
                                <DateTimePopoverInput
                                    value={data.deadline}
                                    orderId={id}
                                />
                            </AccessControl>
                        </div>
                        <AccessControl requiredRole={'admin'} can="view">
                            <div className="status-section col-3 justify-content-end">
                                <div className="label">Order status</div>
                                <Status value={data.state} map={mapStatus} />
                            </div>
                        </AccessControl>
                        <div className="pm-section col-3">
                            <div className="label">PM</div>
                            <div>
                                <AccessControl
                                    requiredRole={'admin'}
                                    can="action"
                                >
                                    <UserDropdownInput
                                        selectedValue={data.manager}
                                        orderId={id}
                                        placeholder="Select a PM"
                                        userRole="pm"
                                    />
                                </AccessControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5 mb-3">
                    <div className="session-title">Translation request</div>
                </div>
                <div className="panel trans-request m-4 bg-white rounded rounded-4">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-3">
                                <div className="label">Source language</div>
                                <LanguageCountry languageCode={data.source} />
                            </div>
                            <AccessControl requiredRole={'admin'} can="view">
                                <div className="col-3">
                                    <div className="label">Merged file</div>
                                    <FileDownload
                                        orderId={data.uid}
                                        fileName={data.combinedFile}
                                        handleFileDownload={downloadMergedFile}
                                    />
                                </div>
                            </AccessControl>

                            <div className="col-3" />
                            <div className="col-3">
                                <AccessControl
                                    requiredRole={'admin'}
                                    can="view"
                                >
                                    <div className="d-flex justify-content-around align-items-center h-100 m-2">
                                        <button
                                            type="button"
                                            className={mergeFilesBtnClass}
                                            onClick={
                                                this.isMergeFilesReady()
                                                    ? this.onMergeFilesClick
                                                    : undefined
                                            }
                                        >
                                            <i className="fas fa-file-archive" />
                                            Merge files
                                        </button>
                                        <DeliverButton
                                            disabled={!data.combinedFile}
                                            onDeliverConfirmed={
                                                this.onDeliverClick
                                            }
                                        />
                                    </div>
                                </AccessControl>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <AccessControl requiredRole={'admin'} can="action">
                            <TargetLangTable
                                data={jobs}
                                user={user}
                                projectName={data.project}
                                settingSheets={settingSheets}
                                totalWordCount={get(data, 'wordCount')}
                            />
                        </AccessControl>
                    </div>
                </div>

                <div className="container">
                    <div className="session-title">PM notes</div>
                </div>

                <div className="panel m-4 bg-white rounded rounded-4">
                    <AccessControl requiredRole={'admin'} can="action">
                        <PMNotes orderId={id} orderDetail={data} />
                    </AccessControl>
                </div>

                <div className="container">
                    <div className="session-title">Order information</div>
                </div>
                {!(info.version === 2) && (
                    <div className="panel m-4 bg-white rounded rounded-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="label">Game name</div>
                                    <div>{data.project}</div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label">
                                        Translate game name?
                                    </div>
                                    <div>
                                        {get(
                                            data,
                                            ['info', 'isTranslateGameTitle'],
                                            false
                                        )
                                            ? 'Yes'
                                            : 'No'}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label">Game category</div>
                                    <div className="text-value">
                                        {get(
                                            data,
                                            ['info', 'gameCategory'],
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="label">
                                        Target age group
                                    </div>
                                    <div>
                                        {get(data, ['info', 'targetAge'])
                                            ? get(data, ['info', 'targetAge']) +
                                              '+'
                                            : ''}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label">Game webpage</div>
                                    <div>
                                        {get(data, ['info', 'webPage'], '')}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label">Reference file</div>
                                    <FileDownload
                                        orderId={data.uid}
                                        fileName={data.sourceFile}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="label">Note</div>
                                    <div className="multi-lines-text">
                                        <pre className="note-review">
                                            {get(data, ['info', 'note'], '')}
                                        </pre>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="label">Game assets</div>
                                    <div>{this.buildAssetFileListV1()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {info.version === 2 && (
                    <div className="panel m-4 rounded rounded-4">
                        <div className="game-infos">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">Game title:</div>
                                        <div>{data.project}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Translate game title?
                                        </div>
                                        <div>
                                            {info.isTranslateGameTitle ===
                                            'follow'
                                                ? `Follow linguist's advice`
                                                : info.isTranslateGameTitle ===
                                                  true
                                                ? 'Yes'
                                                : 'No'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                Game already released?
                                            </div>
                                            <div>
                                                {info.isGameReleased === true
                                                    ? 'Yes'
                                                    : 'No'}
                                            </div>
                                        </p>
                                        {info.isGameReleased === false &&
                                            info.gameReleaseDate && (
                                                <p>
                                                    <div className="label">
                                                        Release Date{' '}
                                                        <span className="note-text">
                                                            (mm-dd-yyyy)
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {dateTimeToString(
                                                            info.gameReleaseDate,
                                                            DATE_TIME_FORMAT.MM_DD_YYYY
                                                        )}
                                                    </div>
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">Age rating:</div>
                                        <div>
                                            {get(data, ['info', 'targetAge'])
                                                ? get(data, [
                                                      'info',
                                                      'targetAge'
                                                  ]) + '+'
                                                : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Platform(s):
                                        </div>
                                        <div>
                                            {(info.platforms || []).join(' | ')}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Reference file:&nbsp;
                                            <span className="note-text">
                                                (note: do NOT use this file to
                                                translate)
                                            </span>
                                        </div>
                                        <FileDownload
                                            orderId={data.uid}
                                            fileName={data.sourceFile}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Game link(s):
                                        </div>
                                        <pre className="note-review">
                                            {info.webPage || ''}
                                        </pre>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">Swearing:</div>
                                        <div>{info.swearing || ''}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">Genre(s):</div>
                                        <div>
                                            {info.gameCategories
                                                ? info.gameCategories.join(
                                                      ' | '
                                                  )
                                                : info.gameCategory}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Original game language:
                                        </div>
                                        <div>{info.originalLanguage || ''}</div>
                                        <div>
                                            {this.buildAssetFileList(
                                                ASSET_FILE_PREFIXS.originalLanguage
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                List of terms that should remain
                                                untranslated (if any)?
                                            </div>
                                            <pre className="note-review">
                                                {info.isRemainTerms === true
                                                    ? 'Yes'
                                                    : 'No'}
                                            </pre>
                                        </p>
                                        {info.isRemainTerms === true && (
                                            <p>
                                                <div className="label">
                                                    Retain following terms in
                                                    original language:
                                                </div>
                                                <pre className="note-review">
                                                    {info.remainTerms || ''}
                                                </pre>
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Screenshots/Videos:
                                        </div>
                                        <div>
                                            {this.buildAssetFileList(
                                                ASSET_FILE_PREFIXS.isScreenshots
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Legacy translations:
                                        </div>
                                        <div>
                                            {this.buildAssetFileList(
                                                ASSET_FILE_PREFIXS.isLegacyTranslation
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">Glossary:</div>
                                        <div>
                                            {this.buildAssetFileList(
                                                ASSET_FILE_PREFIXS.isGlossary
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            Style guide:
                                        </div>
                                        <div>
                                            {this.buildAssetFileList(
                                                ASSET_FILE_PREFIXS.isStyleGuide
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                Does the game support special MS
                                                characters such as non-breaking
                                                space, en dash (—), curly
                                                quotes, etc.?
                                            </div>
                                            <div>
                                                {info.isSpecialCharacter ===
                                                    true && 'Yes'}
                                                {info.isSpecialCharacter ===
                                                    false && 'No'}
                                                {info.isSpecialCharacter ===
                                                    'na' && `I don't know`}
                                            </div>
                                        </p>
                                        {info.isSpecialCharacter === 'na' &&
                                            info.specialCharactersNote && (
                                                <p>
                                                    <div className="label">
                                                        Use it or not?
                                                    </div>
                                                    <pre className="note-review">
                                                        {info.specialCharactersNote ||
                                                            ''}
                                                    </pre>
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                Are adaptations required
                                                (currency, measurements, date
                                                conversion, references to local
                                                culture, etc.)?
                                            </div>
                                            <div>
                                                {info.isAdaptation === true &&
                                                    'Yes'}
                                                {info.isAdaptation === false &&
                                                    'No'}
                                            </div>
                                        </p>
                                        {info.isAdaptation === true && (
                                            <p>
                                                <div className="label">
                                                    Adaptation rules:
                                                </div>
                                                <pre className="note-review">
                                                    {info.adaptationNote || ''}
                                                </pre>
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                Are dialogues in order? Is info
                                                on who's the speaker available
                                                (gender issues)?
                                            </div>
                                            <div>
                                                {info.isDialoguesInOrder ===
                                                    true && 'Yes'}
                                                {info.isDialoguesInOrder ===
                                                    false && 'No'}
                                                {info.isDialoguesInOrder ===
                                                    'na' && 'N/A'}
                                            </div>
                                        </p>
                                        {info.dialoguesInOrderNote && (
                                            <p>
                                                <div className="label">
                                                    Dialogue comments:
                                                </div>
                                                <pre className="note-review">
                                                    {info.dialoguesInOrderNote ||
                                                        ''}
                                                </pre>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            How do characters address each
                                            other?
                                        </div>
                                        <div>
                                            {ACTION_STYLES[
                                                info.characterAddress
                                            ] || ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            How do the game messages address the
                                            player?
                                        </div>
                                        <div>
                                            {ACTION_STYLES[
                                                info.messagesAddress
                                            ] || ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <div className="label">
                                            The gender of the player character
                                            can be:
                                        </div>
                                        <div>
                                            {GENDERS[info.targetGender] || ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info-group">
                                        <p>
                                            <div className="label">
                                                Is there a string length
                                                limitation? (standard is 20-30%
                                                longer than English)
                                            </div>
                                            <div>
                                                {info.isStringLengthLimit ===
                                                    true && 'Yes'}
                                                {info.isStringLengthLimit ===
                                                    false && 'No'}
                                                {info.isStringLengthLimit ===
                                                    true &&
                                                info.stringLengthPriority ===
                                                    'length'
                                                    ? '. Prioritize Length'
                                                    : ''}
                                                {info.isStringLengthLimit ===
                                                    true &&
                                                info.stringLengthPriority ===
                                                    'quality'
                                                    ? '. Prioritize Quality'
                                                    : ''}
                                            </div>
                                        </p>
                                        {info.isStringLengthLimit === true &&
                                            info.stringLengthLimitNote && (
                                                <p>
                                                    <div className="label">
                                                        String length rules:
                                                    </div>
                                                    <pre className="note-review">
                                                        {info.stringLengthLimitNote ||
                                                            ''}
                                                    </pre>
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="info-group">
                                        <div className="label">
                                            Other reference material <br />
                                            (extra links, game access keys,
                                            builds, wiki, extra context,
                                            descriptions, etc.):
                                        </div>
                                        <div className="multi-lines-text">
                                            <pre className="note-review">
                                                {get(
                                                    data,
                                                    ['info', 'note'],
                                                    ''
                                                )}
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"></div>
                        </div>
                    </div>
                )}

                <QnA orderId={id} orderUid={data.uid} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const detailId = get(ownProps, ['match', 'params', 'id']);
    const details = state.getIn(['orders', 'details']).toJS();
    const detail = get(details, [detailId, 'data']);
    const jobs = state.getIn(['orders', 'jobs']).toJS();
    const user = state.getIn(['auth', 'user']).toJS();

    const orderJobs = get(jobs, [detailId, 'data']);
    const orderJobDetails = get(jobs, [detailId, 'details']);

    const referOrderId =
        detail && detail.info ? detail.info.originalOrderId : null;

    return {
        id: detailId,
        user,
        detailsLoading: get(details, [detailId, 'loading']),
        data: detail,
        referOrderId,
        referOrderData: get(details, [referOrderId, 'data']),
        isReferLoading: get(details, [referOrderId, 'loading']),
        jobs: orderJobs && orderJobs.map(item => orderJobDetails[item.id]),
        jobsLoading: get(jobs, [detailId, 'loading']),
        calculating: state.getIn(['orders', 'calculating']),
        error: state.getIn(['orders', 'error']),
        message: state.getIn(['orders', 'message']),
        mapStatus: state.getIn(['orders', 'mapStatus'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchOrderDetails,
            fetchOrderJobList,
            downloadAssetsFile,
            mergeTranslatedFiles,
            downloadMergedFile,
            deliverOrder,
            closeMessage
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(OrderDetails));
