import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';

import { USER } from './actionConstants';

const INITIAL_STATE = fromJS({
    loading: false,
    data: null, // User list
    modal: {
        show: false,
        data: null
    }
});

const handlers = {
    [USER.SHOW_MODAL](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'show'], true);
            mutableState.setIn(['modal', 'data'], fromJS(data));
        });
    },
    [USER.CLOSE_MODAL](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['modal', 'show'], false);
        });
    },
    [USER.FETCH_USER_LIST](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], true);
        });
    },
    [USER.ON_USER_LIST_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['data'], fromJS(data));
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
