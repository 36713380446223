import { request } from './request';

const multipart = files => {
    let data = new FormData();

    files.reduce((data, file) => {
        data.append('files', file, `${file.name}`);
        return data;
    }, data);

    return data;
};

const ORDER_STATUS_VALUES_MAP = {
    'not-assign': {
        min: 0,
        max: 512
    },
    translating: {
        min: 544,
        max: 770
    },
    finished: {
        min: 1024,
        max: 1284
    },
    issue: {
        min: 1282,
        max: 1282
    },
    delivered: {
        min: 2048,
        max: 2048
    },
    rejected: {
        min: 4096,
        max: 4096
    }
};

export function getOrderList({
    offset,
    limit,
    orderId,
    companyName,
    projectName,
    status,
    pm
}) {
    const pathString = [];

    pathString.push(`/tms/order?p.offset=${offset}&p.limit=${limit}`);

    if (orderId !== undefined && orderId.trim() !== '') {
        pathString.push(`&id.$eq=${orderId}`);
    }

    if (companyName !== undefined && companyName.trim() !== '') {
        pathString.push(`&companyName.$like=%${companyName}%`);
    }

    if (projectName !== undefined && projectName.trim() !== '') {
        pathString.push(`&project.$like=%${projectName}%`);
    }

    if (pm !== undefined && pm.trim() !== '') {
        pathString.push(`&manager.$eq=${pm}`);
    }

    if (status !== undefined && status.trim() !== '') {
        const orderState = ORDER_STATUS_VALUES_MAP[status];

        if (status === 'issue' || status === 'delivered') {
            pathString.push(`&state.$eq=${orderState.min}`);
        } else {
            pathString.push(`&state.$gte=${orderState.min}`);
            pathString.push(`&state.$lte=${orderState.max}`);
        }
    } else {
        pathString.push(`&state.$lt=${ORDER_STATUS_VALUES_MAP.rejected.min}`);
    }

    return request({
        path: encodeURI(pathString.join('')),
        options: {
            method: 'GET'
        }
    });
}

export function getOrderJobList(orderId) {
    return request({
        path: `/tms/order/${orderId}/jobs`,
        options: {
            method: 'GET'
        }
    });
}

export function getOrderDetails(orderId) {
    return request({
        path: `/tms/order/${orderId}`,
        options: {
            method: 'GET'
        }
    });
}

export function getFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function getExtractedFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/extracted/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function getAssetsFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/assets/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function getMergedFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/merged/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function getTranslatedFile(orderId, jobId, fileName) {
    return request({
        path: `/tms/order/${orderId}/jobs/${jobId}/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function assignOrder(orderId, userUid) {
    return request({
        path: `/tms/order/${orderId}/assign`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ userId: userUid })
        }
    });
}

export function assignJob(orderId, jobId, userUid) {
    return request({
        path: `/tms/order/${orderId}/jobs/${jobId}/assign`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ userId: userUid })
        }
    });
}

export function setJobDone(orderId, jobId) {
    return request({
        path: `/tms/order/${orderId}/jobs/${jobId}/confirm`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ confirmed: true })
        }
    });
}

export function setDeadline(orderId, deadline) {
    return request({
        path: `/tms/order/${orderId}/deadline`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ deadline })
        }
    });
}

export function postTranslatedFile(orderId, jobId, files) {
    return request({
        path: `/tms/order/${orderId}/jobs/${jobId}`,
        options: {
            method: 'POST',
            body: multipart(files)
        },
        useDefaultHeader: false
    });
}

export function mergeFiles(orderId) {
    return request({
        path: `/tms/order/${orderId}/merge`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ force: true })
        }
    });
}

export function sendJobAssignmentEmail(orderId, jobId, userId) {
    return request({
        path: `/tms/order/${orderId}/jobs/${jobId}/send`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ userId, force: true })
        }
    });
}

export function postDeliverOrder(orderId) {
    return request({
        path: `/tms/order/${orderId}/deliver`,
        options: {
            method: 'POST',
            body: JSON.stringify({ confirm: true })
        }
    });
}

export function setJobRequireChecklist(orderId, jobIds, isChecked) {
    const checkedOrUnchecked = isChecked ? 'checked' : 'unchecked';

    return request({
        path: `/tms/order/${orderId}/jobs/checklist/${checkedOrUnchecked}`,
        options: {
            method: 'PUT',
            body: JSON.stringify({ jobIds })
        }
    });
}

export function getChecklistSubmissionList({ offset, limit, orderId }) {
    const pathString = [];

    pathString.push(`/tms/checklists?p.offset=${offset}&p.limit=${limit}`);

    if (orderId !== undefined && orderId.trim() !== '') {
        pathString.push(`&orderId.$eq=${orderId}`);
    }

    return request({
        path: encodeURI(pathString.join('')),
        options: {
            method: 'GET'
        }
    });
}

export function postQACheclistForm(orderId, jobId, formData) {
    return request({
        path: `/tms/order/${orderId}/${jobId}/checklist`,
        options: {
            method: 'POST',
            body: JSON.stringify({ formData })
        }
    });
}

export function setManagerNote(orderId, text) {
    return request({
        path: `/tms/order/${orderId}/manager/note`,
        options: {
            method: 'POST',
            body: JSON.stringify({ text })
        }
    });
}

export function postManagerAssetsFile(orderId, { name, file }) {
    const data = new FormData();

    data.append('files', file, name);

    return request({
        path: `/tms/order/${orderId}/manager/assets`,
        options: {
            method: 'POST',
            body: data
        },
        useDefaultHeader: false
    });
}

export function getManagerAssetsFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/manager/assets/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function deleteManagerAssetsFile(orderId, fileName) {
    return request({
        path: `/tms/order/${orderId}/manager/assets/${fileName}`,
        options: {
            method: 'DELETE'
        }
    });
}

export function postJobExtension(jobId, tok, deadline) {
    return request({
        path: `/tms/interaction/suggest-job-extension/${jobId}?deadline=${deadline}&tok=${tok}`,
        options: {
            method: 'GET'
        },
        useDefaultHeader: false
    });
}
