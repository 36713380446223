import React from 'react';
import classNames from 'classnames';

import './Modal-style.css';

export const Modal = ({
    show,
    header,
    footer,
    className,
    closeable,
    onClose,
    children
}) => {
    const headerComponent = typeof header === 'function' ? header() : header;
    const footerComponent = typeof footer === 'function' ? footer() : footer;
    const componentClass = classNames('modal fade', className, {
        show: show
    });

    return (
        <div
            className={componentClass}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {headerComponent && (
                        <div className="modal-header">
                            {headerComponent}
                            {closeable && (
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={onClose}
                                >
                                    <i className="fas fa-times" />
                                </button>
                            )}
                        </div>
                    )}
                    <div className="modal-body">{children}</div>
                    {footerComponent && (
                        <div className="modal-footer">{footerComponent}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
