import React from 'react';
import classNames from 'classnames';
import MenuDropdown from './MenuDropdown';

const MenuItem = ({ option, selectedOption, onClick, className }) => {
    const {
        icon,
        label,
        type,
        selected,
        searchName,
        optionGroups,
        options
    } = option;
    let element;

    const onItemClick = () => {
        onClick(option);
    };

    switch (type) {
        case 'group':
            element = (
                <MenuDropdown
                    className="dropdown-group-item"
                    label={label}
                    searchName={searchName}
                    optionGroups={optionGroups}
                    options={options}
                    onChange={onClick}
                    direction={'right'}
                    selectedOption={selectedOption}
                />
            );
            break;
        case 'divider':
            element = <div className="dropdown-divider" />;
            break;
        default:
            const elmClass = classNames('dropdown-item', className, {
                active: selected,
                clickable: !selected
            });

            element = (
                <div
                    className={elmClass}
                    onClick={selected ? undefined : onItemClick}
                >
                    {!!icon && icon}
                    {label}
                    {selected && <i className="fas fa-check icon-selected" />}
                </div>
            );
            break;
    }

    return element;
};

export default MenuItem;
