import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func } from 'prop-types';

import { toJS } from 'components';
import { showModal, logOut } from './actions';
import { FORM_TYPES } from './constants';
import AuthModal from './AuthModal';
import UserDropdown from './UserDropdown';

import './UserNav-style.css';

class UserNav extends React.Component {
    static propTypes = {
        showModal: func
    };

    onSignInClick = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.SIGN_IN);
    };

    onSignUpClick = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.SIGN_UP);
    };

    onLogOut = history => {
        const { logOut } = this.props;

        logOut(history);
    };

    isAuthenticated = user => {
        return !!user;
    };

    render() {
        const { user } = this.props;
        const isAuthenticated = this.isAuthenticated(user);

        return (
            <div className="user-nav">
                {isAuthenticated && (
                    <UserDropdown user={user} handleLogout={this.onLogOut} />
                )}
                {!isAuthenticated && (
                    <div className="auth-group">
                        <button
                            type="button"
                            className="btn btn-login"
                            onClick={this.onSignInClick}
                        >
                            Log in
                        </button>
                        <button
                            type="button"
                            className="btn btn-signup"
                            onClick={this.onSignUpClick}
                        >
                            Sign up
                        </button>
                    </div>
                )}
                <AuthModal />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.getIn(['auth', 'user'])
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            showModal,
            logOut
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(UserNav));
