import get from 'lodash/get';
import { requestData, receiveData } from '../api/actions';
import * as trackerApi from 'services/tracker';

import { SUMMARY_REPORT } from './actionConstants';

export function closeMessage() {
    return {
        type: SUMMARY_REPORT.CLOSE_MESSAGE
    };
}

export function reload() {
    return {
        type: SUMMARY_REPORT.RELOAD
    };
}

export function fetchSummary(tok) {
    return (dispatch, getState) => {
        dispatch(requestData(SUMMARY_REPORT.EXECUTING));

        trackerApi
            .getSummary(tok)
            .then(payload => {
                const payloadData = get(payload, ['data'], {});

                const summary = payloadData;

                dispatch(
                    receiveData(SUMMARY_REPORT.ON_SUMMARY_FETCHED, {
                        summary
                    })
                );
            })
            .catch(e => {
                dispatch(receiveData(SUMMARY_REPORT.ON_FAILED, e));
            });
    };
}

export function confirmSummary({ tok, state, comments }) {
    return (dispatch, getState) => {
        dispatch(requestData(SUMMARY_REPORT.EXECUTING));

        trackerApi
            .confirmSummary({ tok, state, comments })
            .then(payload => {
                //const orderPayload = get(payload, ['data'], {});

                dispatch(
                    receiveData(SUMMARY_REPORT.ON_CONFIRMED, {
                        tok,
                        state,
                        comments
                    })
                );
            })
            .catch(e => {
                dispatch(receiveData(SUMMARY_REPORT.ON_FAILED, e));
            });
    };
}
