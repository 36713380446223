import React from 'react';
import { withFormik } from 'formik';
import { TextFieldInput, Select } from 'components';

import './UsersFilterForm-style.css';

const formikEnhancer = withFormik({
    mapPropsToValues: ({ values }) => {
        return {
            userName: values.userName,
            email: values.email,
            role: values.role,
            status: values.status,
            languages: values.languages
        };
    },
    handleSubmit: (payload, { props }) => {
        props.onEnter(payload);
    },
    displayName: 'UsersFilterForm'
});

const UsersFilterForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        onCancel
    } = props;

    const ROLE_OPTIONS = [
        { value: undefined, label: 'All' },
        { value: 'pm', label: 'PM' },
        { value: 'translator', label: 'Translator' }
    ];

    const STATUS_OPTIONS = [
        { value: undefined, label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ];

    return (
        <div className="users-filter-form">
            <div className="title">Filter</div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <TextFieldInput
                    id="userName"
                    type="text"
                    label="User name"
                    placeholder="Input keyword"
                    error={touched.userName && errors.userName}
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextFieldInput
                    id="email"
                    type="text"
                    label="Email"
                    placeholder="Input keyword"
                    error={touched.email && errors.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Select
                    id="role"
                    label="Role"
                    options={ROLE_OPTIONS}
                    value={values.role}
                    placeholder="Input keyword"
                    error={touched.role && errors.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                <Select
                    id="status"
                    label="Status"
                    options={STATUS_OPTIONS}
                    value={values.status}
                    placeholder="Input keyword"
                    error={touched.status && errors.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

                <div className="submit-btns">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-apply">
                        Apply
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(UsersFilterForm);

export default EnhancedForm;
