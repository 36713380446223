import { request } from './request';

// Get Fortnox Order
export function getSummary(tok) {
    return request({
        path: `/tracker/air/summary?tok=${tok}`,
        options: {
            method: 'GET'
        }
    });
}

export function confirmSummary({ tok, state, comments }) {
    return request({
        path: `/tracker/air/summary/confirm`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                tok,
                state,
                comments
            })
        }
    });
}
