import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionTable from './components/ActionTable';
import { inviteCustomer, findAll, loadMore, selectCustomer } from './reducer';
import meta from './meta';
import './index.css';

const StatusDobuleCheck = () => {
    return (
        <div title="active" className="text-success align-middle text-center">
            <i className="fas fa-check-double" />
        </div>
    );
};
const StatusSingleCheck = () => {
    return (
        <div
            title="invited"
            className="text-secondary align-middle text-center"
        >
            <i className="fas fa-check" />
        </div>
    );
};

const StatusInviteBtn = ({ onButtonClick }) => {
    return (
        <div className="customer-status d-flex justify-content-center">
            <button className="btn btn-outline-secondary btn-sm disabled">
                Invite
            </button>
            <button
                type="button"
                className={`btn btn-warning btn-sm `}
                onClick={onButtonClick}
            >
                <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                />
                Invite
            </button>
        </div>
    );
};

class CustomerList extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.cellRenderers = {
            created: ({ columnIndex, rowData, rowIndex, dataKey }) => {
                const createdDate = rowData.created
                    ? new Date(rowData.created)
                    : null;
                return (
                    <span>
                        {' '}
                        {createdDate ? createdDate.toDateString() : null}{' '}
                    </span>
                );
            },
            status: ({ columnIndex, rowData, rowIndex, dataKey }) => {
                return rowData.status && rowData.invited ? (
                    <StatusDobuleCheck />
                ) : rowData.invited === undefined ? null : rowData.invited ? (
                    <StatusSingleCheck />
                ) : (
                    <StatusInviteBtn
                        onButtonClick={e =>
                            this.handleCellClick({
                                rowData,
                                columnIndex,
                                rowIndex,
                                dataKey
                            })
                        }
                    />
                );
            }
        };
    }
    render() {
        return (
            <ActionTable
                meta={meta}
                cellRenderers={this.cellRenderers}
                setting={{
                    title: 'Customer',
                    height: 450,
                    headerHeight: 40,
                    rowHeight: 35,
                    disableHeader: false,
                    overscanRowCount: 50
                }}
            />
        );
    }

    handleCellClick = ({ rowData, columnIndex, rowIndex, dataKey }) => {
        const { inviteCustomer } = this.props;
        inviteCustomer(`${meta.entry}/invite`, rowData.email);
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        { findAll, loadMore, selectCustomer, inviteCustomer },
        dispatch
    );
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(CustomerList));
