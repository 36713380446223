import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal } from './actions';
import { FORM_TYPES } from './constants';

class SignInPage extends React.Component {
    componentDidMount() {
        const { showModal } = this.props;

        showModal(FORM_TYPES.SIGN_IN);
    }

    render() {
        return <div />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            showModal
        },
        dispatch
    );
};

export default connect(
    null,
    mapDispatchToProps
)(SignInPage);
