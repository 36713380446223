import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QandaPool } from 'ld-qanda-rux';
import { string } from 'prop-types';
import { Loading, Spinner, toJS } from 'components';

import {
    fetchActiveQuestions,
    searchText,
    addQuestion,
    resolveQuestion,
    addAnswer,
    toggleAddingQuestion
} from './actions';

class QnAContainer extends React.PureComponent {
    static propTypes = {
        orderId: string.isRequired,
        orderUid: string.isRequired
    };

    state = {
        sourceOptions: null
    };

    componentDidMount() {
        const { orderId, loading, fetchData } = this.props;

        if (!loading) {
            fetchData(orderId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { orderId, fetchData } = this.props;

        if (prevProps.orderId !== orderId) {
            fetchData(orderId);
        }
    }

    handleSaveQuestion = async question => this.props.addQuestion(question);

    handleResolveQuestion = async question =>
        this.props.resolveQuestion(question);

    handleSaveAnswer = async ({ id, content }) =>
        this.props.addAnswer({ questionId: id, content });

    searchText = async (keyword, orderUid) => {
        const result = await searchText(keyword, orderUid);
        const { total, sources } = result.data || {};
        const options = total
            ? sources.map(ctx => ({
                  value: ctx.id,
                  label: ctx.sourceText,
                  ctx
              }))
            : [];
        return options;
    };

    render() {
        const {
            user,
            orderId,
            orderUid,
            list,
            groupKey,
            isAddingQuestion,
            loading,
            executing,
            error
        } = this.props;

        if (loading || !list) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                {executing && <Spinner />}
                <div className="qna-container d-flex flex-column h-100">
                    <QandaPool
                        userUid={user.userUid}
                        orderId={orderId}
                        orderUid={orderUid}
                        isAddingQuestion={isAddingQuestion}
                        list={list}
                        groupKey={groupKey}
                        error={error}
                        onSearchText={this.searchText}
                        onSaveQuestion={this.handleSaveQuestion}
                        onSaveAnswer={this.handleSaveAnswer}
                        onResolveQuestion={this.handleResolveQuestion}
                        onNewQuestion={this.handleNewQuestion}
                        onCancelQuestion={this.handleCancelQuestion}
                    />
                </div>
            </React.Fragment>
        );
    }

    handleNewQuestion = () => {
        this.props.toggleAddingQuestion(true);
    };

    handleCancelQuestion = () => {
        this.props.toggleAddingQuestion(false);
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['qna', 'loading']),
        executing: state.getIn(['qna', 'executing']),
        list: state.getIn(['qna', 'list']),
        groupKey: state.getIn(['qna', 'groupKey']),
        isAddingQuestion: state.getIn(['qna', 'isAddingQuestion']),
        error: state.getIn(['qna', 'error']),
        user: state.getIn(['auth', 'user'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchActiveQuestions,
            resolveQuestion,
            addQuestion,
            addAnswer,
            toggleAddingQuestion
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(QnAContainer));
