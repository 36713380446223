import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import localizedHelp from '../common/localizeHelper';
import AssignmentMaybe from './AssignmentMaybe';

import './Assignment-style.css';

class AssignmentMessage extends React.PureComponent {
    render() {
        const params = queryString.parse(this.props.location.search);
        return params.msg === 'maybe' ? (
            <AssignmentMaybe {...params} />
        ) : (
            <div className="page-content full-height">
                <div className="m-5">
                    <h3
                        className="display-5"
                        dangerouslySetInnerHTML={{
                            __html: localizedHelp(`msg-${params.msg}`)
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(AssignmentMessage);
