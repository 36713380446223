import React from 'react';
import classNames from 'classnames';
import { func, string, bool } from 'prop-types';

import './Checkbox-style.css';

class Checkbox extends React.Component {
    static propTypes = {
        id: string,
        checked: bool,
        disabled: bool,
        className: string,
        onChange: func,
        setFieldValue: func,
        setFieldTouched: func
    };

    state = {
        checked: this.props.checked
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({
                checked: this.props.checked
            });
        }
    }

    onChange = () => {
        const { id, onChange, setFieldValue, setFieldTouched } = this.props;
        const { checked } = this.state;

        onChange && onChange(!checked);

        this.setState({
            checked: !checked
        });

        setFieldValue && setFieldValue(id, !checked);
        setFieldTouched && setFieldTouched(id, true);
    };

    render() {
        const { className, disabled } = this.props;
        const { checked } = this.state;
        const componentClass = classNames('checkbox', className, {
            checked: checked,
            disabled: !!disabled
        });

        return (
            <span
                className={componentClass}
                onClick={!disabled ? this.onChange : null}
            >
                {checked && <i className="fas fa-check" />}
            </span>
        );
    }
}

export default Checkbox;
