import React from 'react';
import { array, object, func } from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import clone from 'lodash/clone';
import { LanguageCountry, Table } from 'components';
import UserActionsBtn from './UserActionsBtn';

class UserTable extends React.Component {
    static propTypes = {
        data: array,
        filterParams: object,
        sortParams: object,
        onSortChange: func
    };

    static defaultProps = {
        filterParams: {},
        sortParams: {}
    };

    statusRender = ({ rowData, columnKey }) => {
        return rowData[columnKey] === true ? 'Active' : 'Inactive';
    };

    actionRender = ({ rowData }) => {
        return <UserActionsBtn user={rowData} label={'...'} />;
    };

    languagesRender = ({ rowData, columnKey }) => {
        const data = rowData[columnKey];
        const elements = [];

        data &&
            data.forEach(item => {
                elements.push(
                    <LanguageCountry key={item} languageCode={item} />
                );
            });

        return <div className="language-country-list">{elements}</div>;
    };

    buildColumns = () => {
        const columns = [
            { label: 'User name', columnKey: 'userName' },
            { label: 'Email', columnKey: 'email', flexGrow: 0, width: 300 },
            { label: 'Role', columnKey: 'roleName', flexGrow: 0, width: 150 },
            {
                label: 'Translation languages',
                columnKey: 'translationLanguages',
                cellRenderer: this.languagesRender
            },
            {
                label: 'Status',
                columnKey: 'status',
                cellRenderer: this.statusRender,
                flexGrow: 0,
                width: 150
            },
            {
                label: '',
                columnKey: 'id',
                sortable: false,
                cellRenderer: this.actionRender,
                flexGrow: 0,
                width: 50
            }
        ];

        return columns;
    };

    render() {
        const { data, filterParams, sortParams, onSortChange } = this.props;

        const { sortKey, sortType } = sortParams;

        const columns = this.buildColumns();
        let filteredData = clone(data);

        for (let param in filterParams) {
            if (filterParams[param] !== undefined) {
                filteredData = filteredData.filter(data => {
                    if (param === 'status') {
                        return (
                            (filterParams[param].toLowerCase() === 'active' &&
                                data[param]) ||
                            (filterParams[param].toLowerCase() === 'inactive' &&
                                !data[param])
                        );
                    } else {
                        return (
                            data[param] &&
                            data[param]
                                .toLowerCase()
                                .includes(filterParams[param].toLowerCase())
                        );
                    }
                });
            }
        }

        if (sortKey && sortType) {
            filteredData.sort((dataA, dataB) => {
                const valueA = dataA[sortKey];
                const valueB = dataB[sortKey];
                let sortVal = 0;

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }

        return (
            <div className="table-container">
                <AutoSizer>
                    {({ width, height }) => (
                        <React.Fragment>
                            <Table
                                className="user-table"
                                data={filteredData}
                                columns={columns}
                                height={height - 50}
                                width={width}
                                rowExpandedRender={this.rowExpandedRender}
                                sortKey={sortKey}
                                sortType={sortType}
                                onSortChange={onSortChange}
                            />
                            <div
                                style={{ width, height: 50 }}
                                className="showing-count px-2"
                            >
                                {`Showing ${filteredData.length} user(s)`}
                            </div>
                        </React.Fragment>
                    )}
                </AutoSizer>
            </div>
        );
    }
}

export default UserTable;
