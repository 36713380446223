import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popover from 'react-tiny-popover';
import { any, string, bool } from 'prop-types';
import { DateTimeInput } from 'components';

import { setOrderDeadline } from './actions';

import { dateTimeToString, DATE_TIME_FORMAT } from '../common/timeUtils';

import './DateTimePopoverInput-style.css';

// TODO: a HOC for popover events handling

const UTC_OFFSET = 60;

class DateTimePopoverInput extends React.Component {
    static propTypes = {
        value: any,
        orderId: string,
        readOnly: bool
    };

    state = {
        show: false,
        value: this.props.value
    };

    handleClickOnside = event => {
        event.stopPropagation();
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    onButtonClick = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    onWheel = e => {
        this.setState({
            show: false
        });
    };

    onChange = date => {
        const { value, orderId, setOrderDeadline } = this.props;

        if (value !== date) {
            setOrderDeadline(orderId, date);
        }

        this.setState({
            show: false,
            value: date
        });
    };

    render() {
        const { readOnly } = this.props;
        const { show, value } = this.state;
        const contentClass = `content ${value ? '' : 'empty'}`;

        if (readOnly) {
            return (
                <span className={`date-time-input selection`}>
                    <span className={contentClass}>
                        <i className="far fa-calendar-alt" />
                        {value
                            ? dateTimeToString(
                                  value,
                                  DATE_TIME_FORMAT.MM_DD_YYYY_HH_MM,
                                  UTC_OFFSET
                              )
                            : 'n/a'}
                    </span>
                </span>
            );
        }

        return (
            <Popover
                isOpen={show}
                content={
                    <div className="popover-content">
                        <div
                            className={contentClass}
                            onClick={this.handleClickOnside}
                        >
                            <DateTimeInput
                                selectedValue={value}
                                autoFocus
                                utcOffset={UTC_OFFSET}
                                onEnter={this.onChange}
                            />
                        </div>
                        <div
                            className="overlay"
                            onWheel={this.onWheel}
                            onClick={this.handleClickOutside}
                        />
                    </div>
                }
            >
                <span
                    className={`date-time-input selection clickable ${
                        show ? 'selected' : ''
                    }`}
                    onClick={() => this.setState({ show: !show })}
                >
                    <span className={contentClass}>
                        <i className="far fa-calendar-alt" />
                        {value
                            ? dateTimeToString(
                                  value,
                                  DATE_TIME_FORMAT.MM_DD_YYYY_HH_MM,
                                  UTC_OFFSET
                              )
                            : 'Select a date'}
                    </span>
                </span>
            </Popover>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ setOrderDeadline }, dispatch);
};

export default connect(null, mapDispatchToProps)(DateTimePopoverInput);
