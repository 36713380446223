import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, bool } from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

import { Table, LanguageCountry, FileProgress } from 'components';
import { UserDropdownInput } from 'users';
import { downloadTranslatedFile, notifyJobAssignment } from './actions';
import FileDownload from './FileDownload';
import Status from './Status';
import { STATE_VALUES } from './constants';

class JobTable extends React.PureComponent {
    static propTypes = {
        data: array,
        readOnly: bool
    };

    state = {
        sortKey: 'targetLang',
        sortType: 'desc'
    };

    langsRender = ({ rowData, columnKey }) => {
        const langCode = rowData[columnKey];

        return <LanguageCountry languageCode={langCode} />;
    };

    translatedFileRender = ({ rowData, columnKey }) => {
        const { downloadTranslatedFile } = this.props;
        const fileName = rowData[columnKey];
        return (
            <div>
                <FileDownload
                    orderId={rowData.orderUid}
                    fileName={fileName}
                    jobId={rowData.id}
                    handleFileDownload={downloadTranslatedFile}
                    isTruncate={true}
                />
                {fileName && (
                    <FileProgress
                        className="translated-progress"
                        data={rowData.progress}
                    />
                )}
            </div>
        );
    };

    statusRender = ({ rowData, columnKey }) => {
        return <Status value={rowData[columnKey]} map={this.props.mapStatus} />;
    };

    userRender = ({ rowData, columnKey }) => {
        const { readOnly } = this.props;

        return (
            <UserDropdownInput
                selectedValue={rowData[columnKey]}
                orderId={rowData.orderId}
                jobId={rowData.id}
                placeholder="Select a translator"
                userRole="translator"
                target={rowData.target}
                readOnly={readOnly}
            />
        );
    };

    actionsRender = ({ rowData, columnKey }) => {
        const { notifyJobAssignment } = this.props;

        const onEmailBtnClick = () => {
            notifyJobAssignment(rowData.orderId, rowData.id, rowData.assignee);
        };

        const jobState = rowData.state;
        const emailBtnClickable =
            jobState === STATE_VALUES.ASSIGNED ||
            jobState === STATE_VALUES.DECLINED;
        const emailBtnClass = classNames('btn', {
            done: [
                STATE_VALUES.DONE,
                STATE_VALUES.ACCEPTED,
                STATE_VALUES.COMMITED,
                STATE_VALUES.REQUESTED,
                STATE_VALUES.HOLDED
            ].includes(jobState),
            clickable: emailBtnClickable,
            disabled: !rowData.assignee
        });

        return (
            <div className="action-btns">
                <span
                    className={emailBtnClass}
                    onClick={emailBtnClickable ? onEmailBtnClick : undefined}
                    title={emailBtnClickable ? 'Send email' : undefined}
                >
                    <i className="fas fa-envelope" />
                </span>
            </div>
        );
    };

    buildColumns = data => {
        const { readOnly } = this.props;
        const columns = [
            {
                label: '',
                columnKey: 'id',
                type: 'index',
                flexGrow: 0,
                width: 41,
                sortable: false
            },
            {
                label: 'Target language',
                columnKey: 'target',
                cellRenderer: this.langsRender
            },
            {
                label: 'Translated file',
                columnKey: 'translatedFile',
                cellRenderer: this.translatedFileRender
            },
            {
                label: 'Word count',
                columnKey: 'wordCount'
            },
            {
                label: 'Translator',
                columnKey: 'assignee',
                cellRenderer: this.userRender
            },
            {
                label: 'Status',
                columnKey: 'state',
                cellRenderer: this.statusRender
            }
        ];

        if (!readOnly) {
            columns.push({
                label: '',
                flexGrow: 0,
                width: 50,
                cellRenderer: this.actionsRender,
                sortable: false
            });
        }

        return columns;
    };

    onSortChange = (columnKey, sortType) => {
        this.setState({
            sortKey: columnKey,
            sortType
        });
    };

    render() {
        const { data } = this.props;
        const { sortKey, sortType } = this.state;
        const columns = this.buildColumns(data);

        if (sortKey && sortType) {
            data.sort((dataA, dataB) => {
                const valueA = dataA[sortKey];
                const valueB = dataB[sortKey];
                let sortVal = 0;

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'asc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }

        return (
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={data}
                        columns={columns}
                        rowHeight={50}
                        height={height}
                        width={width}
                        sortKey={sortKey}
                        sortType={sortType}
                        showScrollbarY
                        onSortChange={this.onSortChange}
                    />
                )}
            </AutoSizer>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    mapStatus: state.getIn(['orders', 'mapStatus'])
});
const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            downloadTranslatedFile,
            notifyJobAssignment
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTable);
