import React from 'react';
import { array, func } from 'prop-types';

import TabItem from './TabItem';

class Tab extends React.PureComponent {
    static propTypes = {
        items: array,
        onSelect: func
    };
    state = {
        active: this.props.items[0].value || 0
    };

    onSelect = item => {
        const { onSelect } = this.props;

        this.setState({
            active: item.value
        });

        if (onSelect) {
            onSelect(item.value);
        }
    };

    render() {
        const { items } = this.props;
        const { active } = this.state;
        const tabItems = [];

        items.forEach((item, index) => {
            tabItems.push(
                <TabItem
                    key={`tab_item_${index}`}
                    item={{
                        value: item.value || index,
                        label: item.label,
                        icon: item.icon,
                        active: active === (item.value || index)
                    }}
                    onSelect={this.onSelect}
                />
            );
        });

        return <ul className="nav nav-tabs">{tabItems}</ul>;
    }
}

export default Tab;
