const React = require('react');
const { Cell } = require('fixed-data-table-2');

export const CollapseCell = ({
    rowData,
    rowIndex,
    columnKey,
    collapsedRows,
    onClick,
    ...props
}) => {
    const onCellClick = () => {
        onClick(rowIndex, rowData);
    };

    return (
        <Cell {...props}>
            <i onClick={onCellClick} className="collapse-icon clickable">
                {collapsedRows.has(rowIndex) ? '\u25BC' : '\u25BA'}
            </i>
        </Cell>
    );
};

export const IndexCell = ({ rowIndex, ...props }) => {
    return <Cell {...props}>{rowIndex + 1}</Cell>;
};
