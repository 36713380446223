import React from 'react';
import classNames from 'classnames';

import './Alert-style.css';

const ICON_MAPPING = {
    success: 'info-circle fa-lg',
    danger: 'far fa-exclamation-circle',
    warning: 'exclamation-circle fa-lg'
};

export default function Alert({ type, title, message, className, onClose }) {
    const itemClass = classNames('alert-container', className);
    const icon = ICON_MAPPING[type];

    return (
        <div className={itemClass}>
            <div className={`title alert alert-${type}`}>
                {icon && <i className={`fa fa-${icon} icon-type`} />}
                {title}
                <div className="close" onClick={onClose}>
                    <i className="fas fa-times" />
                </div>
            </div>
            <div
                className="message alert"
                dangerouslySetInnerHTML={{ __html: message }}
            ></div>
        </div>
    );
}
