import React from 'react';
import ReactCountryFlag from 'react-country-flag';

import './LanguageCountry-style.css';

export default function LanguageCountry({ languageCode }) {
    return (
        <div className="language-country">
            <span className="country-flag">
                <ReactCountryFlag
                    code={languageCode.substring(2).toLowerCase()}
                    svg
                />
            </span>
            {languageCode}
        </div>
    );
}
