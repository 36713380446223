import React from 'react';
import { Alert } from 'components';
const DEFAULT_TITLE = 'Success!';

const SuccessHandler = ({ state, summary, onClose }) => {
    let title = DEFAULT_TITLE;
    let message = '';

    if (state === 'accepted') {
        title = 'ACCEPTED!';
        message = `Thank you. You accepted your "Service report - ${summary.fromMonthName} ${summary.fromYear}". Please send your invoice to <a href="mailto:invoice@localizedirect.com" target="_blank">invoice@localizedirect.com</a> before the 15th of the month.`;
    } else {
        title = 'REJECTED!';
        message = `Thank you. You rejected your "Service report - ${summary.fromMonthName} ${summary.fromYear}". The details of the corrections needed have been sent to us.`;
    }
    return (
        <Alert
            type="success"
            title={title}
            message={message}
            onClose={onClose}
            className="success-message"
        />
    );
};

export default SuccessHandler;
