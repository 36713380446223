export const STATE_VALUES = {
    CREATED: 8,
    ASSIGNED: 32,
    REQUESTED: 33,
    DECLINED: 34,
    ACCEPTED: 36,
    HOLDED: 48,
    COMMITED: 64,
    DONE: 128
};

export const MESSAGES = {
    DELIVER_COMFIRM_TITLE: 'Deliver file to customer',
    DELIVER_COMFIRM_CONTENT: 'Do you want to deliver?',
    DELIVER_AGAIN_COMFIRM_CONTENT:
        'You have delivered this order before. Do you want to deliver again?'
};

export const MAX_UPLOAD_FILE_SIZE = 64; // in Mb
