import React from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { func, string, bool } from 'prop-types';

import './TextInput-style.css';

const STATES = {
    TYPING: 'typing',
    SAVING: 'saving',
    SAVED: 'saved',
    ERROR: 'error'
};

class TextInput extends React.Component {
    static propTypes = {
        id: string,
        value: string,
        name: string,
        placeholder: string,
        className: string,
        useDebounce: bool,
        status: string,
        onChange: func,
        setFieldValue: func,
        setFieldTouched: func
    };
    static defaultProps = {
        value: '',
        status: null
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: this.props.value,
            status: this.props.status
        };

        if (props.useDebounce) {
            this.onChange = debounce(this.debounceChange, 1000);
        } else {
            this.onChange = this.props.onChange;
        }
    }

    debounceChange = value => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    };

    onInput = event => {
        const { id, setFieldValue, setFieldTouched } = this.props;

        this.setState({
            value: event.target.value,
            status: STATES.TYPING
        });

        this.onChange(event.target.value, id);

        setFieldValue && setFieldValue(id, event.target.value);
        setFieldTouched && setFieldTouched(id, true);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.status !== prevProps.status) {
            this.setState({
                status: this.props.status
            });
        }

        if (
            this.props.status !== prevProps.status &&
            this.props.status === STATES.SAVED
        ) {
            setTimeout(() => {
                this.setState({
                    status: null
                });
            }, 2000);
        }
    }

    buildStatus() {
        const { useDebounce } = this.props;
        const { status } = this.state;

        if (!useDebounce) {
            return <span />;
        }

        switch (status) {
            case STATES.ERROR:
                return <span className="input-state error">error</span>;
            case STATES.TYPING:
            case STATES.SAVING:
                return <span className="input-state typing">...typing</span>;
            case STATES.SAVED:
                return <span className="input-state saved">saved</span>;
            default:
                return <span />;
        }
    }

    render() {
        const { className, placeholder, name, rows } = this.props;
        const { value } = this.state;
        const componentClass = classNames('text-input', className);

        return (
            <div className={componentClass}>
                {this.buildStatus()}
                {!rows && (
                    <input
                        type="text"
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        onChange={this.onInput}
                    />
                )}

                {rows && (
                    <textarea
                        rows={rows}
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        onChange={this.onInput}
                    />
                )}
            </div>
        );
    }
}

export default TextInput;
