import React from 'react';
import { string, bool, func } from 'prop-types';
import { Link } from 'react-router-dom';

export class Nav extends React.PureComponent {
    static propTypes = {
        classNames: string.isRequired
    };

    static defaultProps = {
        classNames: 'navbar-nav'
    };

    render() {
        const { classNames } = this.props;
        return <ul className={`nav ${classNames}`}>{this.props.children}</ul>;
    }
}

export class NavItem extends React.PureComponent {
    static propTypes = {
        path: string.isRequired,
        title: string.isRequired,
        exact: bool,
        active: bool,
        enable: bool,
        onClick: func
    };
    static defaultProps = {
        exact: false,
        active: false,
        enable: true
    };
    render() {
        const { path, title, active, enable, onClick } = this.props;
        const classes = [];
        if (active) classes.push('active');
        if (!enable) classes.push('disabled');
        return (
            <li className="nav-item" onClick={e => onClick && onClick(path, e)}>
                <Link
                    className={`nav-link ${classes.join(' ')}`}
                    to={enable ? path : '#'}
                >
                    {title}
                </Link>
                {active && <div className="active-line" />}
            </li>
        );
    }
}
