import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { logOut } from './actions';

class SignOutPage extends React.Component {
    componentDidMount() {
        const { logOut, history } = this.props;

        logOut(history);
    }

    render() {
        return <div />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            logOut
        },
        dispatch
    );
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(SignOutPage)
);
