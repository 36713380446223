export const CHECK_ITEMS = [
    {
        title: 'Spell-check',
        note: `A spell-checker was run and the translation does not contain misspelled words.`
    },
    {
        title: 'Automatic QA',
        note: `An automatic QA was run in Memsource or another tool.`
    },
    {
        title: 'Source, references, glossaries, and query sheet',
        note: `All references have been checked before starting translation and all queries have been duly checked before delivery.`
    },
    {
        title: 'Terminology consistency',
        note: `All terms have been consistently translated.`
    },
    {
        title: 'No empty translation',
        note: `The target contains translated text.`
    },
    {
        title: 'Punctuation is accurate',
        note: `Check if punctuation is different in the source and target. For example, the source ends with "." but the target does not or vice-versa. If translation needs to be different, please add a comment to client clarifying the reasons.`
    },
    {
        title: 'No missing numbers',
        note: `The numbers in the source are present in the target. Error example: the source has the phrase "10 and 20", but the target reads "10 and 30".`
    },
    {
        title: 'No repeated words',
        note: `There are not two or more adjacent words in the target that are are identical. Error example: "You won won this game".`
    },
    {
        title: 'No multiple spaces',
        note: `There are not multiple spaces in the translation.`
    },
    {
        title: 'Tags & formatting matches source',
        note: `Tags/formatting are not missing or invalid, or there aren't missing or redundant line breaks. Example: the source contains "{100} {200}" but the target reads "{100}".`
    },
    {
        title: 'Character limit',
        note: `The target segment length is within the provided character limit (if applicable).`
    },
    {
        title: 'Currency, measurements, and date conversion',
        note: `Content is localized appropriately. Comments or questions were added to inform on the decision of converting or not those numbers.`
    }
];
