import React from 'react';
import get from 'lodash/get';

const PaymentSummary = ({ summary }) => {
    return (
        <div className="summary payment-summary row">
            <div className="col-xl-6 col-md-6" />
            <div className="col-xl-6 col-md-6 mt-3">
                {get(summary, 'totalVAT', 0) > 0 && (
                    <React.Fragment>
                        <div className="sub-total-payed">
                            <span className="label">{`Total excl. TAX`}</span>
                            <span className="value">
                                {summary.totalAmount} {summary.paymentCurrency}
                            </span>
                        </div>
                        <div className="vat">
                            <span className="label">{`VAT`}</span>
                            <span className="value">
                                {summary.totalVAT} {summary.paymentCurrency}
                            </span>
                        </div>
                    </React.Fragment>
                )}
                <hr className="line-2" />
                <div className="total-payed">
                    <span className="label bold-1">{`Total`}</span>
                    <span className="value bold">
                        {summary.totalAmountInclVAT} {summary.paymentCurrency}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentSummary;
